import React, { FC, useState, Dispatch, SetStateAction } from 'react'
import cn from 'classnames'
import { EnvironmentOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'
import { Timeline as AntTimeline } from 'antd'
import { ModalPayload as ModalExperiencePayload } from 'app/AuthenticatedApp/Candidate/Dashboard/Profile/Body/Modal/Experience'
import { ModalPayload as ModalEducationPayload } from 'app/AuthenticatedApp/Candidate/Dashboard/Profile/Body/Modal/Education'
import { ModalPayload as ModalDeletePayload } from 'app/AuthenticatedApp/Candidate/Dashboard/Profile/Body/Modal/Delete'

type Step = {
  title: string
  description?: string
  location?: string
  startDate: string | number
  endDate?: string | number
  isCurrent?: boolean
  dataCy?: string
}

export interface TimelineProps {
  steps: Step[]
  secondary?: boolean
  label?: string
  large?: boolean
  isEditable?: boolean
  onRemove?: () => void
  onEdit?: Dispatch<SetStateAction<ModalExperiencePayload | ModalEducationPayload>>
  onDelete?: (payload: Omit<ModalDeletePayload, 'context'>) => void
  showLine?: boolean
  dataCy?: string
}

const MAX_LENGTH = 200

const Timeline: FC<TimelineProps> = ({
  steps,
  label,
  secondary,
  large = false,
  isEditable = false,
  onRemove = () => undefined,
  onEdit = () => undefined,
  onDelete = () => undefined,
  showLine = false,
  dataCy,
}) => {
  const { formatMessage } = useIntl()
  const [showMore, setShowMore] = useState<boolean>(false)

  if (false) {
    return <div></div>
  }

  return (
    <div
      className={cn(styles.timeline, {
        [styles.secondary]: secondary,
        [styles.disableLine]: !showLine,
      })}
    >
      {label && <div className={cn(styles.label, { [styles.large]: large })}>{label}</div>}
      <AntTimeline>
        {steps?.map((step, index) => (
          <AntTimeline.Item
            key={index}
            label={
              <>
                <div className={styles.dates}>
                  <div className={styles.endDate}>
                    {step.isCurrent ? formatMessage({ id: 'today' }) : step.endDate ?? null}
                  </div>
                  <div className={styles.startDate}>{step.startDate}</div>
                </div>
              </>
            }
          >
            <li className={styles.element}>
              <div className={styles.title}>{step.title}</div>
              {step.location && (
                <div className={styles.location}>
                  <EnvironmentOutlined /> {step.location}
                </div>
              )}
              <div className={styles.description}>
                {!showMore && step.description && step.description.length > MAX_LENGTH ? (
                  <>
                    {step?.description.substring(0, MAX_LENGTH)}
                    {'... '}
                    <span className={styles.showMore} onClick={() => setShowMore(true)}>
                      {formatMessage({ id: 'global.components.timeline.showMore' })}
                    </span>
                  </>
                ) : (
                  step?.description
                )}
              </div>
            </li>
            {isEditable && (
              <div className={styles.subActionsWrapper}>
                <DeleteOutlined
                  data-cy={`${dataCy}.${index}.remove`}
                  onClick={() => onDelete({ open: true, index: index })}
                />
                <EditOutlined
                  data-cy={`${dataCy}.${index}.edit`}
                  onClick={() => onEdit({ context: 'update', index: index, open: true })}
                />
              </div>
            )}
          </AntTimeline.Item>
        ))}
      </AntTimeline>
    </div>
  )
}

export default Timeline
