import { User } from 'app/contexts/AuthContext/types'
import { Recruiter } from 'app/types'
import { FormValues } from 'app/UnauthenticatedApp/Signin/RecruiterForm/types'
import config from 'config'
import { MutationResultPair, QueryResult, useMutation, useQuery } from 'react-query'
import { callApi, callAuthenticatedApi } from '../utils'

type RegisterRequest = FormValues

interface RegisterResponse extends FormValues {
  id: string
}

export const useGetRecruiter = (id: string): QueryResult<Recruiter> => {
  return useQuery<Recruiter, Error>(['recruiter', { id }], async () => {
    const { data } = await callAuthenticatedApi<Recruiter>(`${config.api.recruiter.get}/${id}`)
    return data
  })
}

export const useRegister = (): MutationResultPair<
  RegisterResponse,
  Error,
  RegisterRequest,
  string
> => {
  return useMutation(
    async (payload: RegisterRequest) => {
      const { data } = await callApi<RegisterResponse>(config.api.recruiter.register, {
        method: 'POST',
        data: payload,
      })
      return data
    },
    {
      throwOnError: true,
    }
  )
}

interface RegisterPatchRequest {
  id: User['id']
  payload: Partial<Recruiter>
}

export const usePatchRegister = (): MutationResultPair<
  RegisterResponse,
  Error,
  RegisterPatchRequest,
  string
> => {
  return useMutation(async ({ payload, id }: RegisterPatchRequest) => {
    const { data } = await callAuthenticatedApi<RegisterResponse>(
      `${config.api.recruiter.register}/${id}`,
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        method: 'PATCH',
        data: payload,
      }
    )
    return data
  })
}

interface SendProfileByEmailRequest {
  candidateId: string
  recipients: Array<string>
  cvFile: Blob
}

export const useSendProfileByEmail = (): MutationResultPair<
  {},
  Error,
  SendProfileByEmailRequest,
  string
> => {
  return useMutation(async ({ candidateId, recipients, cvFile }: SendProfileByEmailRequest) => {
    const payload = new FormData()
    payload.append('email', recipients.join(','))
    payload.append('file', cvFile)

    const { data } = await callAuthenticatedApi<{}>(
      `${config.api.recruiter.sendProfile.replace(':id', candidateId)}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/ld+json',
        },
        method: 'POST',
        data: payload,
      }
    )
    return data
  })
}
