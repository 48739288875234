import {
  EnvironmentOutlined,
  PlusOutlined,
  MessageOutlined,
  MailOutlined,
  PhoneOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import Avatar from 'app/AuthenticatedApp/components/Avatar'
import { Candidate, MediaObject } from 'app/types'
import cn from 'classnames'
import React, { FC, useMemo, useState, useEffect } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useApi } from 'app/contexts/ApiContext'
import ProfilePane from './Panes/ProfilePane'
import Comments from './Panes/Comments'
import ScorePane from './Panes/Score'
import styles from './styles.module.scss'
import { Drawer } from 'antd'
import { Context } from './container'
import { navigate } from '@reach/router'
import config from 'config'
import { useUser } from 'app/contexts/UserContext'
import { UserType } from 'app/contexts/AuthContext/types'
import ModalMail from './Modal/Mail'

interface Applicant {
  applicant: Candidate
  onClose?: Function
  context: Context
  isLoadingCv: boolean
  cvMedia?: MediaObject
}

const Applicant: FC<Applicant> = ({
  applicant,
  onClose = () => undefined,
  context,
  isLoadingCv,
  cvMedia,
}) => {
  const { formatMessage } = useIntl()
  const user = useUser()
  const [visible, setVisible] = useState<boolean>(true)
  const [tabKey, setTabKey] = useState<string>('profile')

  const { opportunityCandidates } = useApi()

  const [openModalMail, setOpenModalMail] = useState<boolean>(false)

  const [mutateAddCandidateToOpportunity] = opportunityCandidates.useAddCandidateToOpportunity()
  const lastPosition = useMemo(
    () => applicant?.workExperiences?.[applicant?.workExperiences?.length - 1],
    [applicant]
  )

  async function onAddToOpportunity(): Promise<void> {
    if (!applicant) return

    await mutateAddCandidateToOpportunity({
      candidate: `/api/candidates/${applicant.id}`,
      opportunity: '/api/opportunities/2864598f-83ca-4d03-9122-2de5ec04275f',
    })
  }

  useEffect(() => {
    setVisible(true)
  }, [applicant])

  return (
    <Drawer
      width={900}
      placement="right"
      closable={false}
      onClose={() => {
        setVisible(false)
        onClose()
      }}
      visible={visible}
    >
      <ModalMail
        onHide={() => setOpenModalMail(false)}
        open={openModalMail}
        candidate={applicant}
      />
      {applicant && (
        <>
          <div className={styles.header}>
            <div className={styles.avatarWrapper}>
              <Avatar
                image={applicant.profilePicture}
                size={52}
                firstname={applicant.firstname}
                lastname={applicant.lastname}
              />
            </div>
            <div>
              <div className={cn(styles.row, styles.name)}>
                {applicant.firstname} {applicant.lastname}
              </div>
              <div className={cn(styles.row, styles.subtitle)}>
                <div>{[applicant.function, lastPosition?.company].filter(Boolean).join(' | ')}</div>
              </div>
              <div className={cn(styles.row, styles.subtitle, styles.contactDetails)}>
                <div>
                  <EnvironmentOutlined className="mr-1" /> {applicant?.defaultMobility?.city}
                </div>
                <div>
                  <PhoneOutlined className="mr-1" /> {applicant?.phoneNumber}
                </div>
                <div>
                  <MailOutlined className="mr-1" /> {applicant?.email}
                </div>
              </div>
              <div className={cn(styles.row, styles.keyFigures)}>
                {formatMessage({ id: 'recruiter.applicant.incomeLabel' })}{' '}
                <span className={styles.figure}>
                  {applicant.expectedSalary ? (
                    <FormattedNumber
                      value={applicant.expectedSalary}
                      currency="EUR"
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currencyDisplay="narrowSymbol"
                      notation="compact"
                      compactDisplay="short"
                    />
                  ) : (
                    'N/A'
                  )}
                </span>
              </div>

              <div className={styles.buttonsGroup}>
                <Button
                  shape="round"
                  ghost={true}
                  icon={<PlusOutlined className="mr-1" />}
                  onClick={onAddToOpportunity}
                >
                  <FormattedMessage id="recruiter.applicant.addToOpportunity" />
                </Button>
                <Button
                  shape="round"
                  className="ml-3"
                  ghost={true}
                  icon={<MessageOutlined className="mr-1" />}
                  onClick={() => setTabKey('comments')}
                >
                  <FormattedMessage id="recruiter.applicant.comments.action" />
                </Button>
                <Button
                  data-cy="modal.applicant.sendMessage"
                  shape="round"
                  className="ml-3"
                  ghost={true}
                  icon={<MailOutlined className="mr-1" />}
                  onClick={() =>
                    navigate(
                      `${config.routes[user.type as NonNullable<UserType>].messaging}?receiver=${
                        applicant.id
                      }&receiverType=candidate`
                    )
                  }
                >
                  <FormattedMessage id="recruiter.applicant.comments.sendMessage" />
                </Button>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_API_BASE_URL}${cvMedia?.contentUrl}`}
                >
                  <Button
                    disabled={!applicant.cvFile}
                    loading={isLoadingCv}
                    shape="round"
                    className={cn('ml-3', { [styles.disabled]: !applicant.cvFile })}
                    ghost={true}
                    icon={<EyeOutlined className="mr-1" />}
                  >
                    {formatMessage({ id: 'recruiter.applicant.download.label' })}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.tabs}>
            <Tabs type="card" activeKey={tabKey} onTabClick={(key) => setTabKey(key)}>
              <Tabs.TabPane
                tab={formatMessage({ id: 'recruiter.applicant.profile' })}
                key="profile"
                className={styles.pane}
              >
                <ProfilePane applicant={applicant} setOpenModalMail={setOpenModalMail} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={formatMessage({ id: 'recruiter.applicant.score' })}
                key="score"
                className={styles.pane}
              >
                <ScorePane applicant={applicant} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={formatMessage({ id: 'recruiter.applicant.comments.title' })}
                key="comments"
                className={styles.pane}
              >
                <Comments applicant={applicant} context={context} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Drawer>
  )
}

export default Applicant
