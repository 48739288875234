import React, { FC, ReactElement } from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import NavLink from './NavLink'
import config from 'config'
import styles from './styles.module.scss'

export interface BreadcrumbItem {
  href: string
  value: string
}

export interface BreadcrumbProps {
  items: Array<BreadcrumbItem>
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items }): ReactElement => {
  return (
    <div className={styles.breadcrumb}>
      <AntBreadcrumb>
        <AntBreadcrumb.Item key={'home'}>
          <NavLink to={config.routes.home}>
            <HomeOutlined />
          </NavLink>
        </AntBreadcrumb.Item>
        {items.map(({ href, value }) => (
          <AntBreadcrumb.Item key={href}>
            <NavLink to={href}>{value}</NavLink>
          </AntBreadcrumb.Item>
        ))}
      </AntBreadcrumb>
    </div>
  )
}

export default Breadcrumb
