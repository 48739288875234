import { Message as MessageT, Thread } from 'app/types'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { IsFetchingMoreValue } from 'react-query'
import Body from './Body'
import Footer from './Footer'
import HeaderFind from './Header/Find'
import HeaderUser from './Header/User'
import HeaderBack from './Header/Back'
import styles from './styles.module.scss'

interface OwnProps {
  active: string
  loading: boolean
  thread?: Thread
  messages?: MessageT[]
  hasNextPage?: boolean
  handleLoadMore: CallableFunction
  isFetchingMore?: IsFetchingMoreValue
  disabled: boolean
  setFindProfile: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  setIsMessageView: Dispatch<SetStateAction<boolean>>
}

const Message: FC<OwnProps> = ({
  thread,
  messages,
  loading,
  hasNextPage,
  handleLoadMore,
  isFetchingMore,
  active,
  disabled,
  setFindProfile,
  isMobile,
  setIsMessageView,
}) => {
  return (
    <div className={styles.container}>
      {isMobile && <HeaderBack setIsMessageView={setIsMessageView} />}
      {disabled ? (
        <HeaderFind setFindProfile={setFindProfile} />
      ) : (
        thread && <HeaderUser thread={thread} />
      )}
      <Body
        active={active}
        disabled={disabled}
        loading={loading}
        messages={messages}
        hasNextPage={hasNextPage}
        handleLoadMore={handleLoadMore}
        isFetchingMore={isFetchingMore}
      />
      {thread && <Footer disabled={disabled} thread={thread} active={active} />}
    </div>
  )
}

export default Message
