import { Candidate, Consultant, Message, Recruiter } from './'

export enum ThreadStatus {
  ARCHIVED = 'archived',
}

export interface Thread {
  id: string
  consultant: Consultant
  recruiter: Recruiter
  candidate: Candidate
  last_message?: Message
  status: ThreadStatus
}
