import { RouteComponentProps } from '@reach/router'
import { useApi } from 'app/contexts/ApiContext'
import { UserType } from 'app/contexts/AuthContext/types'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import Messaging from './component'
import Loader from 'components/Loader'

export enum MessageStatus {
  ALL = 'all',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  UNREAD = 'unread',
}

// const MessagingContainer: FC<RouteComponentProps> = () => {

export interface CreatingMessage {
  receiver?: string
  receiverType?: NonNullable<UserType>
}

export interface RouteState {
  params: {
    state: CreatingMessage
  }
}

const MessagingContainer: FC<RouteComponentProps> = ({ location }) => {
  /**
   * Used to start conversation with someone
   */
  const search = location?.search || ''
  const { receiver, receiverType } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as CreatingMessage
  const [needToCreate, setNeedToCreate] = useState<boolean>(!!receiver && !!receiverType)

  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue] = useDebounce<string>(searchValue, 500)
  const [selectValue, setSelectValue] = useState<MessageStatus>(MessageStatus.ALL)
  const [active, setActive] = useState<string>('')
  const [openedFirst, setOpenedFirst] = useState<boolean>(false)

  const { thread } = useApi()
  const { isLoading: loadingThread, data: threads, isSuccess } = thread.useListThreads(
    debouncedSearchValue,
    selectValue
  )
  const [mutateCreateThread, { isLoading: creatingThread }] = thread.useCreateThread(isSuccess)

  useEffect(() => {
    setActive('')
    if (receiver && receiverType) {
      const hasThreadAlready = threads?.find((thread) => thread?.[receiverType]?.id === receiver)
      if (hasThreadAlready) {
        setActive(hasThreadAlready.id)
        setOpenedFirst(false)
        setNeedToCreate(false)
      } else {
        mutateCreateThread(
          { receiver, receiverType },
          {
            onSuccess(data) {
              setOpenedFirst(false)
              setActive(data.id)
            },
          }
        )
      }
    }
  }, [mutateCreateThread, receiver, receiverType, threads])

  if (needToCreate && creatingThread) {
    return <Loader isFullscreen />
  }

  /**
   * If no thread is activated, open first thread by default
   */
  if (!active && threads !== undefined && threads.length > 0) {
    const [firstThread] = threads
    setOpenedFirst(true)
    setActive(firstThread.id)
  }

  return (
    <Messaging
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectValue={selectValue}
      setSelectValue={setSelectValue}
      threads={threads}
      setActive={setActive}
      active={active}
      isLoading={loadingThread}
      openedFirst={openedFirst}
    />
  )
}

export default MessagingContainer
