import { Redirect } from '@reach/router'
import NotFound from 'app/AuthenticatedApp/NotFound/component'
import { useUser } from 'app/contexts/UserContext'
import React, { FC } from 'react'

export enum ROLES {
  CONSULTANT = 'ROLE_CONSULTANT',
  CANDIDATE = 'ROLE_CANDIDATE',
  RECRUITER = 'ROLE_RECRUITER',
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}

interface WithRolesProps {
  roles: Array<ROLES>
  fallbackUrl?: string
}

function withRoles<WrappedProps = {}>({ roles, fallbackUrl }: WithRolesProps) {
  return (Component: React.ComponentType<WrappedProps>): FC<WrappedProps> => {
    const WithRoles: FC<WrappedProps> = (props) => {
      const user = useUser()
      const isAllowedToContinue = true || roles.some((role) => user.roles.includes(role))

      if (isAllowedToContinue && ('validated' === user.status || 'recruited' === user.status)) {
        return <Component {...(props as WrappedProps)} />
      }
      if (!fallbackUrl) {
        return <NotFound />
      }

      return <Redirect noThrow to={fallbackUrl} />
    }

    return WithRoles
  }
}

export default withRoles
