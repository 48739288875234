import { callAuthenticatedApi } from 'app/contexts/ApiContext/utils'
import config from 'config'
import { useQuery, QueryResult } from 'react-query'
import { Recipient } from 'app/types'

export const useListRecipients = (filter: string): QueryResult<Recipient[]> => {
  return useQuery<Recipient[], Error>(
    'recipients',
    async () => {
      const { data } = await callAuthenticatedApi<Recipient[]>(
        `${config.api.recipient.get}?filter=${filter}`
      )
      return data
    },
    {
      enabled: !!filter,
    }
  )
}
