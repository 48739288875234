import { CloseOutlined, LoadingOutlined, FileOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import styles from './styles.module.scss'
import { MediaObject } from 'app/types'
import { useIntl } from 'react-intl'
import { Popconfirm } from 'antd'

interface OwnProps {
  attachment?: MediaObject
  isLoading: boolean
  canDelete: boolean
  removeUploadMedia: CallableFunction
}

const Files: FC<OwnProps> = ({ attachment, isLoading, canDelete, removeUploadMedia }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <div className={styles.file}>
        {isLoading ? (
          <p>{formatMessage({ id: 'global.messaging.message.body.conversation.file.loading' })}</p>
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_API_BASE_URL}${attachment?.contentUrl}`}
          >
            {attachment?.filename}
          </a>
        )}
        {isLoading ? (
          <LoadingOutlined className={styles.icon} />
        ) : canDelete ? (
          <Popconfirm
            placement="bottom"
            title={formatMessage({ id: 'global.messaging.message.body.conversation.file.delete' })}
            onConfirm={() => removeUploadMedia()}
            okText={formatMessage({ id: 'global.messaging.message.body.conversation.file.yes' })}
            cancelText={formatMessage({ id: 'global.messaging.message.body.conversation.file.no' })}
          >
            <CloseOutlined className={styles.icon} />
          </Popconfirm>
        ) : (
          <FileOutlined className={styles.icon} />
        )}
      </div>
    </div>
  )
}

export default Files
