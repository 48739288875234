import { Link, LinkProps } from '@reach/router'
import cn from 'classnames'
import React, { FC } from 'react'

export interface NavLinkProps extends LinkProps<{}> {
  activeClassName?: string
  children: React.ReactNode
}

const NavLink: FC<NavLinkProps> = ({
  className,
  activeClassName = '',
  children,
  ...props
}: React.PropsWithoutRef<NavLinkProps>) => {
  return (
    <Link
      {...props}
      getProps={(linkProps) => ({
        className: cn(className, { [activeClassName]: linkProps.isCurrent }),
      })}
    >
      {children}
    </Link>
  )
}

export default NavLink
