import React, { FC } from 'react'
import { Candidate } from 'app/types'
import { useApi } from 'app/contexts/ApiContext'

import Comments from './component'
import { Context } from '../../container'

interface OwnProps {
  applicant: Candidate
  context: Context
}

const CommentsContainer: FC<OwnProps> = ({ applicant, context }) => {
  const { candidate } = useApi()

  const [mutateAddReview, { isLoading }] = candidate.useMutationAddReview()

  const submit = (message: string): void => {
    mutateAddReview({ candidate: applicant.id, content: message, context })
  }

  return <Comments applicant={applicant} isLoading={isLoading} submit={submit} />
}
export default CommentsContainer
