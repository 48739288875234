import { useEffect, useRef } from 'react'

const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key]

function useWhyDidYouUpdate<T>(name: string, props: T): void {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps: Record<string, any> = useRef()

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys: Array<string> = Object.keys({ ...previousProps.current, ...props })
      // Use this object to keep track of changed props
      const changesObj: Record<string, any> = {}
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        console.log()

        if (getKeyValue(key)(previousProps.current) !== getKeyValue(key)(props)) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: getKeyValue(key)(props),
          }
        }
      })

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj)
      }
    }

    previousProps.current = props
  })
}

export default useWhyDidYouUpdate
