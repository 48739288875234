import { useState, useMemo } from 'react'
import { useApi } from 'app/contexts/ApiContext'
import { Candidate } from 'app/types'
import { MediaResponse } from 'app/contexts/ApiContext/services/media'
import { useIntl } from 'react-intl'
import { BlocItem } from './Document/Bloc/component'
import { TAG_HEIGHT } from './Document/Quality/component'
import textMetrics from 'text-metrics'

import moment from 'moment'

interface OwnProps {
  applicantId: string
}

interface Response {
  profile: {
    applicant?: Candidate
    languages: Array<[string, string]>
    educationalBackgrounds: Array<BlocItem>
    workExperiences: Array<BlocItem>
    urlAvatar: string
  }
  isLoading: boolean
  qualityBoxSizes: Array<number>
}

const useCandidateProfile = ({ applicantId }: OwnProps): Response => {
  const { candidate, media } = useApi()
  const [shouldLoadProfile, setShouldLoadProfile] = useState<boolean | null>(null)
  const [urlAvatar, setUrlAvatar] = useState<string>('')

  const { formatMessage } = useIntl()
  const { data: applicant, isLoading: isLoadingApplicant } = candidate.useGetCandidate(
    applicantId,
    {
      onSuccess(applicant: Candidate) {
        if (applicant.profilePicture) {
          setShouldLoadProfile(true)
        } else {
          setShouldLoadProfile(false)
        }
      },
    }
  )

  const { isLoading: isLoadingAvatar } = media.useGetUploadMedia(
    applicant?.profilePicture as string,
    {
      enabled: shouldLoadProfile,
      async onSuccess(media: MediaResponse) {
        setUrlAvatar(`${process.env.REACT_APP_API_BASE_URL}${media.contentUrl}`)
      },
    }
  )

  const isLoading = useMemo(
    () =>
      !applicant ||
      shouldLoadProfile === null ||
      (shouldLoadProfile && !urlAvatar) ||
      isLoadingApplicant ||
      isLoadingAvatar,
    [applicant, shouldLoadProfile, isLoadingApplicant, isLoadingAvatar, urlAvatar]
  )

  const languages: Array<[string, string]> = useMemo(
    () =>
      applicant?.spokenLanguages.map((lang) => [
        formatMessage({ id: `language.${lang.name}` }),
        formatMessage({ id: `languageLevel.${lang.level}` }),
      ]) || [],
    [applicant?.spokenLanguages]
  )

  const educationalBackgrounds: Array<BlocItem> = useMemo(
    () =>
      applicant?.educationalBackgrounds
        .sort((a, b) => new Date(b.graduationDate).getTime() - new Date(a.graduationDate).getTime())
        .map(({ schoolName, specialisation, graduationDate }) => ({
          title: schoolName,
          endDate: moment(graduationDate).format('MMMM YYYY'),
          site: specialisation,
        })) || [],
    [applicant?.educationalBackgrounds]
  )

  const workExperiences: Array<BlocItem> = useMemo(
    () =>
      applicant?.workExperiences
        .sort((a, b) => {
          if (a.isCurrent && b.isCurrent) {
            return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          } else if (a.isCurrent && !b.isCurrent) {
            return -1
          } else if (b.isCurrent && !a.isCurrent) {
            return 1
          }

          if (new Date(b.startDate).getFullYear() === new Date(a.startDate).getFullYear()) {
            return new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
          }

          return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        })
        .map(({ company, endDate, isCurrent, jobTitle, location, startDate, role }) => ({
          title: jobTitle,
          startDate: moment(startDate).format('MMMM YYYY'),
          endDate: isCurrent ? formatMessage({ id: 'today' }) : moment(endDate).format('MMMM YYYY'),
          site: company,
          location,
          description: role,
        })) || [],
    [applicant?.workExperiences]
  )

  const metrics = textMetrics.init({
    fontSize: '8px',
    lineHeight: '12px',
    width: 157,
  })

  const qualityBoxSizes =
    applicant?.qualities?.map(({ description }) => metrics.height(description) + TAG_HEIGHT) || []

  return {
    profile: useMemo(
      () => ({
        applicant,
        languages,
        educationalBackgrounds,
        workExperiences,
        urlAvatar,
      }),
      [applicant, languages, educationalBackgrounds, workExperiences, urlAvatar]
    ),
    qualityBoxSizes,
    isLoading,
  }
}

export default useCandidateProfile
