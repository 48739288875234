import {
  PaperClipOutlined,
  SmileOutlined,
  CloseOutlined,
  AuditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { Picker, BaseEmoji } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import React, { FC, useRef, useState, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import useOnClickOutside from 'use-onclickoutside'
import styles from './styles.module.scss'
import { JobDescription } from 'app/types'
import ModalList from 'recruiter/components/Modal/List'

interface OwnProps {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  setJobDescription: Dispatch<SetStateAction<string | undefined>>
  setJobDescriptionSelected: Dispatch<SetStateAction<string | undefined>>
  createMessage: CallableFunction
  uploadAttachment: CallableFunction
  cancelAttachment: CallableFunction
  cancelJobDescription: CallableFunction
  fileName?: string
  jobDescriptionSelected?: string
  isUploadingAttachment: boolean
  disabled: boolean
  loadingJobDescription: boolean
  jobDescriptionsList?: JobDescription[]
  allowJobDescription: boolean
}

const Actions: FC<OwnProps> = ({
  message,
  setMessage,
  createMessage,
  uploadAttachment,
  cancelAttachment,
  cancelJobDescription,
  fileName,
  isUploadingAttachment,
  disabled,
  loadingJobDescription,
  jobDescriptionsList,
  setJobDescriptionSelected,
  setJobDescription,
  jobDescriptionSelected,
  allowJobDescription,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const pickerRef = useRef(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { formatMessage } = useIntl()

  const [modalJobDescription, setModalJobDescription] = useState<boolean>(false)

  const assignJobDescriptionEl = useRef<HTMLDivElement>(null)

  useOnClickOutside(pickerRef, () => setShowEmojiPicker(false))

  const handleUpload = (ie: ChangeEvent<HTMLInputElement>): void => {
    const [file] = ie.target.files
    uploadAttachment(file)
  }

  const cancelUpload = (): void => {
    cancelAttachment()
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.inputWrapper}>
          <input
            ref={fileInputRef}
            multiple={false}
            type="file"
            onChange={handleUpload}
            hidden={true}
          />
          <PaperClipOutlined
            className={styles.icon}
            onClick={() => !disabled && fileInputRef?.current?.click()}
          />
          {fileName && (
            <Spin spinning={isUploadingAttachment}>
              <p onClick={() => cancelUpload()}>
                {fileName} <CloseOutlined />
              </p>
            </Spin>
          )}
        </div>
        <div className={styles.pickerWrapper} ref={pickerRef}>
          <SmileOutlined
            className={styles.icon}
            onClick={() => !disabled && setShowEmojiPicker(!showEmojiPicker)}
          />
          {showEmojiPicker && (
            <div className={styles.picker}>
              <Picker
                set="apple"
                title="Emoji"
                emoji="point_up"
                onSelect={(data: BaseEmoji) => {
                  setMessage(`${message}${data.native}`)
                }}
              />
            </div>
          )}
        </div>
        {allowJobDescription && (
          <div ref={assignJobDescriptionEl}>
            <Button
              data-cy="messaging.footer.jobDescription"
              type="default"
              icon={jobDescriptionSelected ? <DeleteOutlined /> : <AuditOutlined />}
              loading={loadingJobDescription}
              disabled={loadingJobDescription || jobDescriptionsList?.length === 0}
              shape="round"
              onClick={() => {
                jobDescriptionSelected ? cancelJobDescription() : setModalJobDescription(true)
              }}
            >
              {jobDescriptionSelected ? jobDescriptionSelected : 'Joindre une fiche de poste'}
            </Button>
          </div>
        )}
      </div>
      <div>
        <Button
          data-cy="messaging.footer.submit"
          disabled={isUploadingAttachment || disabled}
          className={styles.submit}
          type="primary"
          shape="round"
          onClick={() => createMessage()}
        >
          {formatMessage({ id: 'global.messaging.message.footer.actions.submit' })}
        </Button>
      </div>
      <ModalList
        setModalOpen={setModalJobDescription}
        modalOpen={modalJobDescription}
        refEl={assignJobDescriptionEl}
      >
        {jobDescriptionsList?.map(({ name, id }) => (
          <p
            key={id}
            onClick={() => {
              setJobDescription(`/api/job_descriptions/${id}`)
              setJobDescriptionSelected(name)
              setModalJobDescription(false)
            }}
          >
            {name}
          </p>
        ))}
      </ModalList>
    </div>
  )
}

export default Actions
