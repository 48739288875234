import React from 'react'
import styles from './styles.module.scss'

interface BulletProps {
  half?: boolean
  size?: number
  color?: string
  empty?: boolean
}

const Bullet: React.FC<BulletProps> = ({ half = false, size = 50, color, empty = false }) => {
  return (
    <div className={styles.wrapper} style={{ height: size, width: size, borderColor: color }}>
      <div
        className={styles.bullet}
        style={{
          height: size,
          width: half ? Math.floor(size / 2 - 1) : size,
          backgroundColor: empty ? 'white' : color,
        }}
      />
    </div>
  )
}

export default Bullet
