import { TextAreaProps } from 'antd/es/input'
import TextArea from 'antd/lib/input/TextArea'
import ErrorMessage from 'app/form/base/ErrorMessage'
import cn from 'classnames'
import { FormikErrors } from 'formik'
import React from 'react'
import { FormValues } from '../../../UnauthenticatedApp/Signin/CandidateForm/types'
import styles from './styles.module.scss'

interface OwnProps extends TextAreaProps {
  label: string
  error?:
    | string
    | false
    | string[]
    | FormikErrors<FormValues>
    | FormikErrors<FormValues>[]
    | undefined
  'data-cy'?: string
  name: string
}

const InputTextArea: React.FC<OwnProps> = ({ label, error, name, ...restProps }) => {
  return (
    <div className={cn(styles.group, { [styles.error]: error })}>
      <label htmlFor={name}>{label}</label>
      <TextArea name={name} {...restProps} />

      {error && (
        <div className={styles.error}>
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  )
}

export default InputTextArea
