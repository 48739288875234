import { ROLES } from 'app/AuthenticatedApp/helpers/withRoles'
import { Bank } from 'app/types/bank'

export interface LoginParams {
  email: string
  password: string
}

export interface AuthData {
  user: User | null
  jwt: string | null
  isLoggedIn: boolean
  isCandidate: boolean
  isRecruiter: boolean
  isConsultant: boolean
  isImpersonated: boolean
}

export interface AuthInterface {
  login: (formData: LoginParams) => Promise<void>
  renewToken: () => Promise<void>
  logout: () => void
  setUser: (token: string, refreshToken?: string) => void
  data: AuthData
  loading: boolean
}

export type UserType = 'recruiter' | 'candidate' | 'consultant' | null

export interface User {
  id: string
  firstname?: string
  lastname?: string
  status?: string
  type: UserType
  email: string
  roles: Array<ROLES>
  bankDetails?: Bank
}

export type Jwt = {
  id: string
  username: string
  firstname: string
  lastname: string
  status?: UserStatus
  type: UserType
  iat: number //Issued at, timestamp
  exp: number //Exiration time, timestamp
  roles: Array<ROLES>
}

export enum UserStatus {
  STATUS_INCOMPLETE = 'incomplete',
  STATUS_TO_VALIDATE = 'to_validate',
  STATUS_RECRUITED = 'recruited',
  STATUS_VALIDATED = 'validated',
  STATUS_SIGNED = 'signed',
  STATUS_EXPIRED = 'expired',
}
