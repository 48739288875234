import { JobDescription, JobDescriptionStatus } from 'app/types/jobDescription'
import config from 'config'
import {
  MutationResultPair,
  queryCache,
  QueryConfig,
  QueryResult,
  useMutation,
  useQuery,
} from 'react-query'
import { FormValues as JobDescriptionForm } from 'recruiter/JobDescription/Create/Form/types'
import { callAuthenticatedApi } from '../utils'

export const useJobDescriptionList = (
  filter?: string,
  options?: QueryConfig<JobDescription[]>
): QueryResult<JobDescription[]> => {
  return useQuery<JobDescription[], Error>(
    ['jobDescriptions', filter],
    async () => {
      const { data } = await callAuthenticatedApi<JobDescription[]>(
        `${config.api.jobDescription.list}${filter ?? ''}`
      )
      return data
    },
    {
      ...options,
    }
  )
}

type JobDescriptionRequest = JobDescriptionForm

type JobDescriptionResponse = JobDescriptionForm & { id: string }

export const useCreateJobDescription = (): MutationResultPair<
  JobDescriptionResponse,
  Error,
  JobDescriptionRequest,
  void
> => {
  return useMutation(
    async (payload: JobDescriptionRequest) => {
      const { data } = await callAuthenticatedApi<JobDescriptionResponse>(
        config.api.jobDescription.create,
        {
          method: 'POST',
          data: payload,
        }
      )
      return data
    },
    {
      onMutate: (newJobDescription) => {
        const previousOpportunities: JobDescription[] =
          queryCache.getQueryData('jobDescriptions') || []

        return queryCache.setQueryData('jobDescriptions', [
          ...previousOpportunities,
          newJobDescription,
        ])
      },
    }
  )
}

export type UpdateJobDescriptionRequest = Partial<JobDescriptionRequest> & {
  id: string
  status: JobDescriptionStatus
}

export const useUpdateJobDescription = (): MutationResultPair<
  JobDescriptionResponse,
  Error,
  UpdateJobDescriptionRequest,
  void
> => {
  return useMutation(
    async (payload: UpdateJobDescriptionRequest) => {
      const { data } = await callAuthenticatedApi<JobDescriptionResponse>(
        `${config.api.jobDescription.create}/${payload.id}`,
        {
          method: 'PATCH',
          data: payload,
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      return data
    },
    {
      onMutate: async (newJobDescription) => {
        const oldJobDescription = queryCache.getQueryData<JobDescription>([
          'jobDescription',
          { id: newJobDescription.id },
        ])

        queryCache.setQueryData(['jobDescription', { id: newJobDescription.id }], {
          ...oldJobDescription,
          ...newJobDescription,
        })
      },
      onSuccess: async (newJobDescription) => {
        await queryCache.invalidateQueries(['jobDescription', { id: newJobDescription.id }])
        await queryCache.invalidateQueries('jobDescriptions', { refetchInactive: true })
      },
    }
  )
}

export const useGetJobDescription = (id: string): QueryResult<JobDescription> => {
  return useQuery<JobDescription, Error>(['jobDescription', { id }], async () => {
    const { data } = await callAuthenticatedApi<JobDescription>(
      `${config.api.jobDescription.get}/${id}`
    )
    return data
  })
}
