import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  wrapper: {
    width: 188,
    textAlign: 'left',
  },
  category: {
    fontSize: 13,
    color: 'black',
    fontWeight: 'bold',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    marginTop: 18,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#666666',
  },
  level: {
    fontSize: 8,
    color: '#666666',
  },
})
interface OwnProps {
  languages: Array<[string, string]>
}

const Personnal: FC<OwnProps> = ({ languages }): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.category}>Langues</Text>
      <View style={styles.items}>
        {languages.map(([name, level], key) => (
          <View key={key}>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.level}>{level}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Personnal
