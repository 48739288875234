import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { Candidate } from 'app/types'

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 15,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'black',
  },
  text: {
    fontSize: 8,
    color: '#666666',
    marginTop: 8,
    lineHeight: 1.5,
    textAlign: 'justify',
  },
})
interface OwnProps {
  applicant: Candidate
}

const Description: FC<OwnProps> = ({ applicant }): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>Présentation</Text>
      <Text wrap style={styles.text}>
        {applicant.description}
      </Text>
    </View>
  )
}

export default Description
