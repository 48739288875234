import { Input as InputAntd } from 'antd'
import React, { FC, Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

const { TextArea } = InputAntd

interface OwnProps {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  createMessage: CallableFunction
  disabled: boolean
}

enum NativeEvent {
  Backward = 'deleteContentBackward',
  Enter = 'insertLineBreak',
}

export const nativeEventMapping: Array<NativeEvent | string> = [NativeEvent.Enter]

const Input: FC<OwnProps> = ({ message, setMessage, createMessage, disabled }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <TextArea
        disabled={disabled}
        data-cy="messaging.footer.input"
        value={message}
        onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
          const { inputType } = ev.nativeEvent as InputEvent

          if (message === '' && nativeEventMapping.includes(inputType)) {
            return
          }

          setMessage(ev.target.value)
        }}
        placeholder={formatMessage({ id: 'global.messaging.message.footer.input.placeholder' })}
        rows={1}
        autoSize={{ minRows: 1, maxRows: 4 }}
        onKeyPress={(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (!ev.shiftKey && ev.which === 13) {
            createMessage()
          }
        }}
      />
    </div>
  )
}

export default Input
