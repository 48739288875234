import { User } from 'app/contexts/AuthContext/types'
import { Bank } from 'app/types/bank'
import config from 'config'
import {
  MutationResultPair,
  queryCache,
  QueryConfig,
  QueryResult,
  useMutation,
  useQuery,
} from 'react-query'
import { callAuthenticatedApi } from '../utils'

type UpdateBankResponse = Bank
type UpdateBankRequest = Partial<Bank>

export const useUpdateBank = (): MutationResultPair<
  UpdateBankResponse,
  Error,
  UpdateBankRequest,
  string
> => {
  return useMutation(
    async (payload: UpdateBankRequest) => {
      const { data } = await callAuthenticatedApi<UpdateBankResponse>(
        `${config.api.bank.update}/${payload.id}`,
        {
          method: 'PUT',
          data: payload,
        }
      )
      return data
    },
    {
      // After success or failure, refetch
      onSettled: async () => {
        await queryCache.invalidateQueries('bank')
      },
    }
  )
}

type CreateBankResponse = Bank
type CreateBankRequest = Omit<Bank, 'id'>

export const useCreateBank = (): MutationResultPair<
  CreateBankResponse,
  Error,
  CreateBankRequest,
  string
> => {
  return useMutation(
    async (payload: CreateBankRequest) => {
      const { data } = await callAuthenticatedApi<CreateBankResponse>(config.api.bank.create, {
        method: 'POST',
        data: payload,
      })
      return data
    },
    {
      // After success or failure, refetch
      onSettled: async () => {
        await queryCache.invalidateQueries('bankDetails')
      },
    }
  )
}

type GetRecruiterBankDetailsResponse = Bank

export const useGetRecruiterBankDetails = (
  recruiterId: User['id'],
  options?: QueryConfig<GetRecruiterBankDetailsResponse>
): QueryResult<GetRecruiterBankDetailsResponse> => {
  return useQuery(
    'RecruiterBankDetails',
    async () => {
      const { data } = await callAuthenticatedApi<GetRecruiterBankDetailsResponse>(
        `${config.api.recruiter.get}/${recruiterId}/bank_details`,
        {
          method: 'GET',
        }
      )
      return data
    },
    {
      onSettled: async () => {
        await queryCache.invalidateQueries('bankDetails')
      },
      ...options,
    }
  )
}
