import { useApi } from 'app/contexts/ApiContext'
import { Message as MessageT, Thread } from 'app/types'
import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import Message from './component'

interface OwnProps {
  active: string
  disabled: boolean
  thread?: Thread
  setFindProfile: Dispatch<SetStateAction<boolean>>
  isThreadLoading: boolean
  isMobile: boolean
  setIsMessageView: Dispatch<SetStateAction<boolean>>
}

const MessageContainer: FC<OwnProps> = ({
  active,
  thread: currentThread,
  disabled,
  setFindProfile,
  isThreadLoading,
  isMobile,
  setIsMessageView,
}) => {
  const { thread } = useApi()

  const {
    isLoading: isLoadingMessage,
    data,
    canFetchMore,
    fetchMore,
    isFetchingMore,
  } = thread.useListMessages(active, disabled || !currentThread)

  const isLoading = useMemo(() => isThreadLoading || isLoadingMessage, [
    isThreadLoading,
    isLoadingMessage,
  ])

  const messages: Array<MessageT> | undefined = useCallback(() => {
    // Extract all paginated messages
    return data
      ?.flat()
      .map((data) => data?.['hydra:member'])
      .flat() as Array<MessageT>
  }, [data])()

  return (
    <Message
      setFindProfile={setFindProfile}
      disabled={disabled}
      active={active}
      loading={isLoading}
      thread={currentThread}
      messages={messages}
      hasNextPage={canFetchMore}
      handleLoadMore={fetchMore}
      isFetchingMore={isFetchingMore}
      isMobile={isMobile}
      setIsMessageView={setIsMessageView}
    />
  )
}

export default MessageContainer
