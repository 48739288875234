import { Modal } from 'antd'
import React from 'react'
import styles from './styles.module.scss'
import Container from './container'
import { Candidate } from 'app/types'

interface OwnProps {
  open: boolean
  candidate: Candidate
  onHide: () => void
}

const WrapperMail: React.FC<OwnProps> = ({ open, candidate, onHide }) => {
  return (
    <Modal
      className={styles.modal}
      width={530}
      maskClosable={true}
      closeIcon={<></>}
      footer={<></>}
      visible={open}
      onCancel={onHide}
    >
      <Container candidate={candidate} onHide={onHide} />
    </Modal>
  )
}

export default WrapperMail
