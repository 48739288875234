import { Modal } from 'antd'
import React, { Dispatch, FC, RefObject, SetStateAction, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

interface OwnProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>
  modalOpen: boolean
  children: React.ReactNode
  refEl: RefObject<HTMLElement>
  className?: string
  onConfirm?: () => void
  offset?: number
}

const ModalList: FC<OwnProps> = ({
  className,
  setModalOpen,
  modalOpen,
  children,
  refEl,
  onConfirm,
  offset = 0,
}) => {
  const [position, setPosition] = useState<DOMRect | null>(null)
  useEffect(() => {
    if (refEl && refEl.current) {
      setPosition(refEl.current.getBoundingClientRect())
    }
  }, [refEl])

  return (
    position && (
      <Modal
        className={cn(styles.modal, className)}
        onCancel={() => setModalOpen(false)}
        style={{
          top: position.top + position.height + 10 + offset,
          left: position.left + position.width / 2 - 330 / 2,
          margin: 'inherit',
        }}
        width={330}
        maskClosable={true}
        closeIcon={<></>}
        footer={<></>}
        visible={modalOpen}
        onOk={onConfirm}
      >
        {children}
      </Modal>
    )
  )
}

export default ModalList
