import React, { FC } from 'react'
import ModalMail from './component'
import { Formik } from 'formik'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import validationSchema from './validation'
import { useApi } from 'app/contexts/ApiContext'
import { Candidate } from 'app/types'
import { useExportCVBlob } from 'app/AuthenticatedApp/components/ExportCV'

interface OwnProps {
  candidate: Candidate
  onHide: () => void
}

const ModalMailContainer: FC<OwnProps> = ({ candidate, onHide }) => {
  const { formatMessage } = useIntl()
  const { recruiter } = useApi()

  const [mutateSendProfileByEmail, { isLoading }] = recruiter.useSendProfileByEmail()
  const { isLoading: isLoadingBlob, blob } = useExportCVBlob({ applicantId: candidate.id })

  const initialValues = {
    recipients: [''],
  }

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        try {
          mutateSendProfileByEmail(
            { candidateId: candidate.id, cvFile: blob as Blob, recipients: values.recipients },
            {
              onSuccess() {
                onHide()
                resetForm()
              },
            }
          )
        } catch (e) {
          e.forEach((err: string): void => {
            notification.open({
              message: formatMessage({ id: 'notifications.error' }),
              description: err,
              type: 'error',
            })
          })
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(formatMessage)}
    >
      <ModalMail isSubmitting={isLoading} isGenerating={isLoadingBlob} onHide={onHide} />
    </Formik>
  )
}

export default ModalMailContainer
