import { ROLES } from 'app/AuthenticatedApp/helpers/withRoles'
import { useApi } from 'app/contexts/ApiContext'
import { useUser } from 'app/contexts/UserContext'
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import Actions from './component'

interface OwnProps {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  setJobDescription: Dispatch<SetStateAction<string | undefined>>
  setJobDescriptionSelected: Dispatch<SetStateAction<string | undefined>>
  createMessage: CallableFunction
  uploadAttachment: CallableFunction
  cancelAttachment: CallableFunction
  cancelJobDescription: CallableFunction
  fileName?: string
  jobDescriptionSelected?: string
  isUploadingAttachment: boolean
  disabled: boolean
}

const Step1Container: FC<OwnProps> = ({
  message,
  setMessage,
  createMessage,
  uploadAttachment,
  cancelAttachment,
  cancelJobDescription,
  setJobDescriptionSelected,
  fileName,
  jobDescriptionSelected,
  isUploadingAttachment,
  disabled,
  setJobDescription,
}) => {
  const { jobDescription } = useApi()
  const user = useUser()

  const allowJobDescription = useMemo<boolean>(
    () =>
      [ROLES.CONSULTANT, ROLES.RECRUITER, ROLES.ADMIN].some((role) => user.roles.includes(role)),
    [user.roles]
  )

  const {
    isLoading: loadingJobDescription,
    data: jobDescriptionsList,
  } = jobDescription.useJobDescriptionList(undefined, { enabled: allowJobDescription })

  return (
    <Actions
      loadingJobDescription={loadingJobDescription}
      jobDescriptionsList={jobDescriptionsList}
      message={message}
      setMessage={setMessage}
      createMessage={createMessage}
      uploadAttachment={uploadAttachment}
      cancelAttachment={cancelAttachment}
      fileName={fileName}
      cancelJobDescription={cancelJobDescription}
      setJobDescription={setJobDescription}
      setJobDescriptionSelected={setJobDescriptionSelected}
      isUploadingAttachment={isUploadingAttachment}
      disabled={disabled}
      jobDescriptionSelected={jobDescriptionSelected}
      allowJobDescription={allowJobDescription}
    />
  )
}

export default Step1Container
