export enum languageName {
  de = 'language.de',
  en = 'language.en',
  ar = 'language.ar',
  ca = 'language.ca',
  zh = 'language.zh',
  ko = 'language.ko',
  hr = 'language.hr',
  da = 'language.da',
  es = 'language.es',
  et = 'language.et',
  fi = 'language.fi',
  fr = 'language.fr',
  el = 'language.el',
  he = 'language.he',
  hi = 'language.hi',
  hu = 'language.hu',
  is = 'language.is',
  it = 'language.it',
  ja = 'language.ja',
  lv = 'language.lv',
  lt = 'language.lt',
  lb = 'language.lb',
  nl = 'language.nl',
  no = 'language.no',
  pl = 'language.pl',
  pt = 'language.pt',
  ru = 'language.ru',
  sr = 'language.sr',
  sk = 'language.sk',
  sl = 'language.sl',
  sv = 'language.sv',
  th = 'language.th',
  cs = 'language.cs',
  tr = 'language.tr',
  uk = 'language.uk',
  vi = 'language.vi',
}

export enum languageLevel {
  notions = 'languageLevel.notions',
  intermediate = 'languageLevel.intermediate',
  advanced = 'languageLevel.advanced',
  fluent = 'languageLevel.fluent',
  bilingual = 'languageLevel.bilingual',
}
