import React from 'react'
import ReactDOM from 'react-dom'
import { ReactQueryDevtools } from 'react-query-devtools'
import { App, AppProviders } from './app'
import './styles/index.less'
import './styles/index.scss'

require('dotenv').config()

ReactDOM.render(
  <AppProviders>
    <App />
    {JSON.parse(process.env.REACT_APP_DEBUG as string) && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </AppProviders>,
  document.getElementById('root')
)
