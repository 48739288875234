import { Input } from 'antd'
import { InputProps } from 'antd/es/input'
import ErrorMessage from 'app/form/base/ErrorMessage'
import cn from 'classnames'
import { FormikErrors } from 'formik'
import React from 'react'
import { FormValues } from '../../../UnauthenticatedApp/Signin/CandidateForm/types'
import styles from './styles.module.scss'

interface OwnProps extends InputProps {
  label: string
  error?:
    | string
    | false
    | string[]
    | FormikErrors<FormValues>
    | FormikErrors<FormValues>[]
    | undefined
    | any
  'data-cy'?: string
  name: string
}

const InputText: React.FC<OwnProps> = ({ label, error, name, ...restProps }) => {
  return (
    <div className={cn(styles.group, { [styles.error]: error })}>
      <label htmlFor={name}>{label}</label>
      <Input name={name} {...restProps} />

      {error && <ErrorMessage name={name} component="div" className={styles.error} />}
    </div>
  )
}

export default InputText
