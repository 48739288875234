/* eslint-disable no-throw-literal */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export type Errors = Array<string>

export const callApi = async <T>(
  url: string,
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return await axios
    .request<T>({
      method: 'GET',
      url,
      ...config,
      headers: {
        accept: 'application/json',
        ...config.headers,
      },
    })
    .catch((err) => {
      throw err?.response?.data?.violations?.map(
        ({ propertyPath, message }: { propertyPath: string; message: string }) =>
          `${propertyPath} : ${message}`
      ) as Errors
    })
}

export const callAuthenticatedApi = async <T>(
  url: string,
  config: AxiosRequestConfig | undefined = {}
): Promise<AxiosResponse<T>> => {
  return await callApi<T>(url, {
    ...config,
    headers: {
      ...(config?.headers ?? {}),
    },
  })
}
