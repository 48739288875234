export default {
  common: {
    add: 'Add',
    remove: 'Remove',
  },

  notifications: {
    error: 'An error has occurred',
    success: 'Informations has been saved',
    bankDetails: 'The bank informations have been updated',
    profile: 'Your profile has been updated',
    fileError: 'Error while uploading file',
    invalidLink: 'The link is no longer valid',
    loginError: 'Authentication error. Please try again',
  },
  refreshToken: {
    message: 'Unable to renew your login ID',
  },
  validation: {
    field: {
      required: 'This field is required',
      invalid: 'This field is invalid',
      badFormat: 'Format not supported',
      passwordMisMatch: 'Passwords do not match',
      terms: 'You must agree with the terms',
      password: 'Password must contain at least 8 characters',
      email: 'Please enter a valid email',
      typeNumber: 'Please enter only numbers',
      date: {
        endBeforeStart: 'End date cannot be before start date',
      },
    },
    phone: 'Invalid phone number',
  },
  languageLevel: {
    notions: 'Notions',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
    fluent: 'Fluent',
    bilingual: 'Bilingual',
  },
  contractTypes: {
    permanent: 'Permanent',
    fixedTerm: 'Fixed term',
    internship: 'Internship',
    professionalTraining: 'Profesionnal training',
    freelance: 'Freelance',
  },
  educationLevel: {
    5: '< Bachelor',
    6: 'Bachelor',
    7: 'Master',
    8: 'PhD',
  },
  language: {
    de: 'German',
    en: 'English',
    ar: 'Arabic',
    ca: 'Catalan',
    zh: 'Chinese',
    ko: 'Korean',
    hr: 'Croatian',
    da: 'Danish	',
    es: 'Spanish',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    el: 'Greek',
    he: 'Hebrew',
    hi: 'Hindi',
    hu: 'Hungarian',
    is: 'Icelandic',
    it: 'Italian',
    ja: 'Japanese',
    lv: 'Latvian',
    lt: 'Lithuanian',
    lb: 'Luxembourgish',
    nl: 'Dutch',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    sl: 'Slovenian',
    sv: 'Swedish',
    th: 'Thai',
    cs: 'Czech',
    tr: 'Turkish',
    uk: 'Ukrainian',
    vi: 'Vietnamese',
  },
  companyType: {
    startup: 'Startup',
    sme: 'SME',
    group: 'Major group',
  },
  minimumWorkExperienceDuration: `{ count, plural,
    =0 {less than one year}
    one {{count} year}
    =20 {{count} years and more}
    other {{count} years}
  }`,
  mission: {
    status: {
      pending: 'Mission Pending',
      // eslint-disable-next-line @typescript-eslint/camelcase
      cancelled_company: 'Mission cancelled by the company',
      // eslint-disable-next-line @typescript-eslint/camelcase
      cancelled_isidore: 'Mission cancelled by isidore',
      // eslint-disable-next-line @typescript-eslint/camelcase
      recruited_isidore: 'Mission recruited by isidore',
      archived: 'Mission archived',
      validated: 'Mission validated',
    },
  },
  today: 'Today',
  remainingDays: 'Days remaining',
}
