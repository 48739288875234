/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useState, useEffect } from 'react'
import Document from './Document'
import { saveAs } from 'file-saver'
import { BlobProvider, PDFViewer } from '@react-pdf/renderer'
import { Candidate } from 'app/types'
import {
  EyeOutlined,
  CheckOutlined,
  LoadingOutlined,
  ExportOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'
import { BlocItem } from './Document/Bloc/component'
import { Button, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { Size } from './container'

import styles from './styles.module.scss'

interface OwnProps {
  applicant?: Candidate
  languages: Array<[string, string]>
  educationalBackgrounds: Array<BlocItem>
  workExperiences: Array<BlocItem>
  isLoadingData: boolean
  urlAvatar: string
  debug?: boolean
  size: Size
  qualityBoxSizes: Array<number>
}

const ExportCV: FC<OwnProps> = ({
  applicant,
  languages,
  educationalBackgrounds,
  workExperiences,
  isLoadingData,
  urlAvatar,
  debug,
  size,
  qualityBoxSizes,
  ...props
}): React.ReactElement => {
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const [shouldDownload, setShouldDownload] = useState<boolean>(false)

  const { formatMessage } = useIntl()

  useEffect(() => {
    if (isDownloaded) {
      setTimeout(() => {
        setShouldDownload(false)
        setIsDownloaded(false)
      }, 3000)
    }
  }, [isDownloaded])

  if (debug && !isLoadingData && applicant) {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 100,
        }}
      >
        <PDFViewer style={{ width: '100%', height: '100%' }}>
          <Document
            applicant={applicant}
            languages={languages}
            educationalBackgrounds={educationalBackgrounds}
            workExperiences={workExperiences}
            urlAvatar={urlAvatar}
            qualityBoxSizes={qualityBoxSizes}
          />
        </PDFViewer>
      </div>
    )
  }

  return (
    <>
      {isDownloaded ? (
        <>
          {size === 'default' && (
            <>
              <Button
                data-cy="drawer.applicant.downloadCv"
                shape="round"
                className="ml-3"
                ghost={true}
                icon={<CheckOutlined className="mr-1" />}
              >
                {formatMessage({ id: 'recruiter.applicant.download.finished' })}
              </Button>
            </>
          )}
          {size === 'small' && <CheckOutlined className={styles.small} />}
        </>
      ) : shouldDownload ? (
        applicant && (
          <BlobProvider
            document={
              <Document
                applicant={applicant}
                languages={languages}
                educationalBackgrounds={educationalBackgrounds}
                workExperiences={workExperiences}
                urlAvatar={urlAvatar}
                qualityBoxSizes={qualityBoxSizes}
              />
            }
          >
            {({ blob, url, loading, error }) => {
              if (!loading && !isDownloaded && blob) {
                saveAs(
                  blob,
                  `${applicant.firstname}${applicant.lastname ? `-${applicant.lastname}` : ''}.pdf`
                )
                setIsDownloaded(true)
              }
              return (
                <>
                  {size === 'default' && (
                    <Button
                      data-cy="drawer.applicant.downloadCv"
                      shape="round"
                      className="ml-3"
                      ghost={true}
                      icon={
                        loading ? (
                          <LoadingOutlined className="mr-1" />
                        ) : (
                          <CheckOutlined className="mr-1" />
                        )
                      }
                    >
                      {loading
                        ? formatMessage({ id: 'recruiter.applicant.download.loading' })
                        : formatMessage({ id: 'recruiter.applicant.download.downloaded' })}
                    </Button>
                  )}
                  {size === 'small' && <Loading3QuartersOutlined className={styles.small} />}
                </>
              )
            }}
          </BlobProvider>
        )
      ) : (
        <>
          {size === 'default' && (
            <Button
              data-cy={`drawer.applicant.downloadCv${isLoadingData ? '.loading' : ''}`}
              shape="round"
              className="ml-3"
              loading={isLoadingData}
              ghost={true}
              icon={<EyeOutlined className="mr-1" />}
              onClick={() => setShouldDownload(true)}
            >
              {formatMessage({ id: 'recruiter.applicant.download.label' })}
            </Button>
          )}
          {size === 'small' && (
            <Tooltip
              placement="top"
              title={formatMessage({ id: 'recruiter.applicant.download.tooltip' })}
              color="var(--gray-dark)"
            >
              <ExportOutlined className={styles.small} onClick={() => setShouldDownload(true)} />
            </Tooltip>
          )}
        </>
      )}
    </>
  )
}

export default ExportCV
