import { Button, notification } from 'antd'
import DrawerApplicant from 'app/AuthenticatedApp/components/Drawer/Applicant'
import useInterlocutor from 'app/AuthenticatedApp/Messaging/hook/useInterlocutor'
import { useApi } from 'app/contexts/ApiContext'
import { Candidate, Thread, ThreadStatus } from 'app/types'
import cn from 'classnames'
import React, { FC, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Avatar from '../../../components/Avatar'
import styles from './styles.module.scss'

interface OwnProps {
  thread: Thread
}

const Header: FC<OwnProps> = ({ thread }) => {
  const { formatMessage } = useIntl()
  const { thread: threadApi } = useApi()
  const [mutateArchiveThread, { isLoading: isArchivingThread }] = threadApi.useArchiveThread()

  const [open, setOpen] = useState<boolean>(false)
  const { interlocutor, interlocutorType } = useInterlocutor(thread)

  const isCandidate = useMemo(() => interlocutorType === 'candidate', [interlocutorType])

  return (
    <div className={styles.container} data-cy="messaging.message.header">
      <div
        className={cn({
          [styles.hover]: isCandidate,
        })}
        {...(isCandidate ? { onClick: () => setOpen(true) } : {})}
      >
        <div
          data-cy={`messaging.message.header-${interlocutor.firstname}-${interlocutor.lastname}`}
        >
          <Avatar {...{ ...interlocutor, userType: interlocutorType }} />
        </div>
      </div>
      {thread.status !== ThreadStatus.ARCHIVED && (
        <Button
          className={styles.button}
          type="default"
          loading={isArchivingThread}
          shape="round"
          size="small"
          onClick={async () => {
            try {
              await mutateArchiveThread({ id: thread.id })
              notification.open({
                message: formatMessage({ id: 'notifications.success' }),
                type: 'success',
              })
            } catch (e) {
              notification.open({
                message: formatMessage({ id: 'notifications.error' }),
                type: 'error',
              })
            }
          }}
        >
          {formatMessage({ id: 'global.messaging.message.header.archive' })}
        </Button>
      )}

      {open && (
        <DrawerApplicant
          context="messages"
          applicant={interlocutor as Candidate}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}

export default Header
