import React from 'react'
import Avatar from '../../components/Avatar'
import styles from './styles.module.scss'

interface OwnProps {
  isLoading: boolean
}

const CardPlaceholder: React.FC<OwnProps> = ({ isLoading }) => {
  return <div className={styles.card}>{isLoading && <Avatar isLoading />}</div>
}
export default CardPlaceholder
