import { Checkbox } from 'antd'
import Avatar from 'app/AuthenticatedApp/Messaging/components/Avatar'
import useWhyDidYouUpdate from 'app/hooks/useWhyDidYouUpdate'
import { useUser } from 'app/contexts/UserContext'
import { Message } from 'app/types'
import { isEmpty, isEqual, xorWith } from 'lodash'
import moment from 'moment'
import React, { Dispatch, Fragment, RefObject, SetStateAction, useEffect, useMemo } from 'react'
import File from './File'
import JobDescription from './JobDescription'
import styles from './styles.module.scss'

interface OwnProps {
  messages?: Message[]
  shouldScroll: boolean
  conversationsRef: RefObject<HTMLDivElement>
  active: string
  setShouldScroll: Dispatch<SetStateAction<boolean>>
}

export function Conversation({
  messages,
  shouldScroll,
  conversationsRef,
  active,
  setShouldScroll,
}: OwnProps): JSX.Element | null {
  const user = useUser()

  useEffect(() => {
    if (conversationsRef.current) {
      if (shouldScroll) {
        conversationsRef.current.scrollIntoView()
        setShouldScroll(true)
      }
    }
  }, [conversationsRef, messages, active, shouldScroll, setShouldScroll])

  const [readedMessage] = useMemo<Array<Message>>(
    () => messages?.filter((message) => message.read && message.author.id === user.id) || [],
    [messages, user.id]
  )

  /**
   * @Debug
   */
  useWhyDidYouUpdate<Partial<OwnProps>>('Conversation', { messages })

  if (!messages) {
    return null
  }

  return (
    <>
      {messages?.map((message, i) => (
        <div className={styles.container} key={i}>
          <div className={styles.header}>
            <div className={styles.author}>
              {<Avatar {...message.author} userType="candidate" small={true} />}
            </div>
            <p className={styles.date}>{moment(message.createdAt).format('DD MMM YYYY - HH:mm')}</p>
            {message === readedMessage && (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }} className={styles.date}>
                  <Checkbox defaultChecked={true} disabled className={styles.checkbox} />
                  <p>Lu</p>
                </div>
              </>
            )}
          </div>
          <div className={styles.messageWrapper}>
            <p className={styles.message}>
              {message.message.split('\n').map((item, key) => (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              ))}
            </p>
            {message.jobDescription && (
              <JobDescription
                authorId={message.author.id}
                messageId={message.id}
                jobDescriptionId={message.jobDescription}
              />
            )}
            {message.attachment && (
              <File authorId={message.author.id} attachment={message.attachment} />
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(Conversation, (prevProps, nextProps): boolean => {
  return isEmpty(xorWith(prevProps.messages, nextProps.messages, isEqual))
})
