import * as auth from './auth'
import * as bank from './bank'
import * as candidate from './candidate'
import * as jobDescription from './jobDescription'
import * as library from './library'
import * as media from './media'
import * as missions from './missions'
import * as opportunities from './opportunities'
import * as opportunityCandidates from './opportunityCandidates'
import * as recruiter from './recruiter'
import * as thread from './thread'
import * as recipients from './recipients'

export default {
  auth,
  candidate,
  library,
  recruiter,
  jobDescription,
  opportunities,
  opportunityCandidates,
  missions,
  media,
  bank,
  thread,
  recipients,
}
