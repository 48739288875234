import { UpdateOpportunityForm } from 'app/AuthenticatedApp/Recruiter/Opportunity/Creation/Form/types'
import { Candidate, Opportunity } from 'app/types'
import config from 'config'
import { MutationResultPair, queryCache, QueryResult, useMutation, useQuery } from 'react-query'
import { callAuthenticatedApi } from '../utils'

type CreateOpportunityRequest = UpdateOpportunityForm

export const useListOpportunities = (filters: object = {}): QueryResult<Opportunity[]> => {
  return useQuery<Opportunity[], Error>('opportunities', async () => {
    const { data } = await callAuthenticatedApi<Opportunity[]>(config.api.opportunities.get, {
      params: filters,
    })
    return data
  })
}

type UpdateOpportunityResponse = Opportunity
type UpdateOpportunityRequest = Opportunity

export const useUpdateOpportunity = (): MutationResultPair<
  UpdateOpportunityResponse,
  Error,
  UpdateOpportunityRequest,
  string
> => {
  return useMutation(
    async ({ recruiter, ...payload }: UpdateOpportunityRequest) => {
      const { data } = await callAuthenticatedApi<UpdateOpportunityResponse>(
        `${config.api.opportunities.update}/${payload.id}`,
        {
          method: 'PUT',
          data: payload,
        }
      )
      return data
    },
    {
      // After success or failure, refetch
      onSettled: async () => {
        await queryCache.invalidateQueries('opportunities')
      },
    }
  )
}

interface CreateOpportunityResponse extends UpdateOpportunityForm {
  id: string
}

export const useCreateOpportunity = (): MutationResultPair<
  CreateOpportunityResponse,
  Error,
  CreateOpportunityRequest,
  void
> => {
  return useMutation(
    async (payload: CreateOpportunityRequest) => {
      const { data } = await callAuthenticatedApi<CreateOpportunityResponse>(
        config.api.opportunities.create,
        {
          method: 'POST',
          data: payload,
        }
      )
      return data
    },
    {
      onMutate: (newOpportunity) => {
        const previousOpportunities: Opportunity[] = queryCache.getQueryData('opportunities') || []

        return queryCache.setQueryData('opportunities', [...previousOpportunities, newOpportunity])
      },
    }
  )
}

export const useGetOpportunity = (id: string): QueryResult<Opportunity> => {
  return useQuery<Opportunity, Error>(['opportunity', { id }], async () => {
    const { data } = await callAuthenticatedApi<Opportunity>(
      `${config.api.opportunities.get}/${id}`
    )
    return data
  })
}

export const useGetOpportunityAcceptedCandidates = (id: string): QueryResult<Candidate[]> => {
  return useQuery<Candidate[], Error>(['opportunityCandidateAccepted', { id }], async () => {
    const { data } = await callAuthenticatedApi<Candidate[]>(
      `${config.api.opportunities.get}/${id}/${config.api.opportunities.accceptedCandidatesSuffix}`
    )

    return data
  })
}

export const useGetOpportunityNewCandidates = (id: string): QueryResult<Candidate[]> => {
  return useQuery<Candidate[], Error>(['opportunityCandidateNew', { id }], async () => {
    const { data } = await callAuthenticatedApi<Candidate[]>(
      `${config.api.opportunities.get}/${id}/${config.api.opportunities.newCandidatesSuffix}`
    )

    return data
  })
}

export const useGetOpportunityReachedCandidates = (id: string): QueryResult<Candidate[]> => {
  return useQuery<Candidate[], Error>(['opportunityCandidateReached', { id }], async () => {
    const { data } = await callAuthenticatedApi<Candidate[]>(
      `${config.api.opportunities.get}/${id}/${config.api.opportunities.reachedCandidatesSuffix}`
    )

    return data
  })
}

export const useGetOpportunityDeclinedCandidates = (id: string): QueryResult<Candidate[]> => {
  return useQuery<Candidate[], Error>(['opportunityCandidateDeclined', { id }], async () => {
    const { data } = await callAuthenticatedApi<Candidate[]>(
      `${config.api.opportunities.get}/${id}/${config.api.opportunities.declinedCandidatesSuffix}`
    )

    return data
  })
}
