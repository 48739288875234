import React, { FC, useMemo } from 'react'
import { Candidate } from 'app/types'
import { useIntl } from 'react-intl'
import Timeline from 'app/AuthenticatedApp/components/Timeline'
import ExportCV from 'app/AuthenticatedApp/components/ExportCV'
import { MailOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'
import cn from 'classnames'
import { Tooltip } from 'antd'

const ProfilePane: FC<{
  applicant: Candidate
  setOpenModalMail: (val: boolean) => void
}> = ({ applicant, setOpenModalMail }) => {
  const { formatMessage } = useIntl()

  const experiences = useMemo(() => {
    return applicant.workExperiences
      .sort((a, b) => {
        if (a.isCurrent && b.isCurrent) {
          return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        } else if (a.isCurrent && !b.isCurrent) {
          return -1
        } else if (b.isCurrent && !a.isCurrent) {
          return 1
        }

        if (new Date(b.startDate).getFullYear() === new Date(a.startDate).getFullYear()) {
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
        }

        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      })
      .map((xp) => {
        return {
          title: formatMessage(
            { id: 'recruiter.applicant.xp.title' },
            { position: xp.jobTitle, company: xp.company }
          ),
          startDate: new Date(xp.startDate).getFullYear(),
          endDate: new Date(xp.endDate).getFullYear(),
          isCurrent: xp.isCurrent,
          location: xp.location,
          description: xp.role,
        }
      })
  }, [applicant, formatMessage])

  const degrees = useMemo(() => {
    return applicant.educationalBackgrounds
      .sort((a, b) => new Date(b.graduationDate).getTime() - new Date(a.graduationDate).getTime())
      .map((degree) => {
        return {
          title: formatMessage(
            { id: 'recruiter.applicant.education.title' },
            { diploma: degree.specialisation, school: degree.schoolName }
          ),
          startDate: new Date(degree.graduationDate).getFullYear(),
        }
      })
  }, [applicant, formatMessage])

  return (
    <>
      <div className={styles.profilePane}>
        <div className={styles.actions}>
          <Tooltip
            placement="top"
            title={formatMessage({ id: 'recruiter.applicant.mail.tooltip' })}
            color="var(--gray-dark)"
          >
            <MailOutlined
              data-cy="drawer.applicant.profile.sendProfile"
              onClick={() => setOpenModalMail(true)}
              className={cn(styles.icon, 'mr-4')}
            />
          </Tooltip>
          <ExportCV size="small" applicantId={applicant.id} />
        </div>
        <div>
          <div className={styles.bloc}>
            <div className={styles.label}>
              {formatMessage({ id: 'recruiter.applicant.summary' })}
            </div>
            <div className={styles.summaryContent}>{applicant.description}</div>
          </div>
          <div className={styles.bloc}>
            <Timeline
              label={formatMessage({ id: 'recruiter.applicant.xp.label' })}
              steps={experiences}
            />
          </div>
          <div className={styles.bloc}>
            <Timeline
              label={formatMessage({ id: 'recruiter.applicant.education.label' })}
              steps={degrees}
              secondary
              showLine={false}
            />
          </div>
        </div>
        <div>
          <div className={styles.bloc}>
            <div className={styles.label}>
              {formatMessage({ id: 'recruiter.applicant.languages.label' })}
            </div>
            <div className={styles.languages}>
              {applicant?.spokenLanguages?.map((lang, index) => (
                <span key={index}>
                  {formatMessage({ id: `language.${lang.name}` })} (
                  {formatMessage({ id: `languageLevel.${lang.level}` })}){' '}
                </span>
              ))}
            </div>
          </div>
          <div className={styles.bloc}>
            <div className={styles.label}>
              {formatMessage({ id: 'recruiter.applicant.hobbies.label' })}
            </div>
            <div>
              {applicant.hobbies.map((hobby, key) => (
                <p key={key}>{hobby}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfilePane
