import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik'
import * as Yup from 'yup'

type AnySchema =
  | Yup.BooleanSchema
  | Yup.StringSchema
  | Yup.NumberSchema
  | Yup.ArraySchema<unknown, unknown>

type ObjectSchema<U extends object> = Yup.NotRequiredArraySchema<
  Yup.Shape<object | undefined, U>,
  object
>

export function useCustomField<T, U = void>(
  name: string,
  schema: null | (U extends object ? ObjectSchema<U> : AnySchema)
): [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>] {
  return useField<T>({
    name,
    validate: async (val) => {
      try {
        await schema?.validate(val)
      } catch ({ errors }) {
        const [error] = errors
        return error
      }
    },
  })
}
