import { Button } from 'antd'
import InputTextArea from 'app/form/base/InputTextArea'
import { Candidate } from 'app/types'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

const Comments: FC<{
  applicant: Candidate
  submit: Function
  isLoading: boolean
}> = ({ applicant, isLoading, submit }) => {
  const { formatMessage } = useIntl()
  const [value, setValue] = useState<string>(applicant?.recruiterReviews?.content)
  return (
    <div className={styles.comments}>
      <InputTextArea
        rows={10}
        label={formatMessage({ id: 'recruiter.applicant.comments.label' })}
        placeholder={formatMessage({
          id: 'recruiter.applicant.comments.placeholder',
        })}
        onBlur={() => console.log()}
        name="comments"
        id="comments"
        data-cy="applicant.comments"
        onChange={(v) => setValue(v.target.value)}
        value={value}
      />
      <Button
        className={styles.submitComment}
        type="default"
        shape="round"
        loading={isLoading}
        disabled={!value || isLoading}
        onClick={() => submit(value)}
      >
        {formatMessage({ id: 'recruiter.applicant.comments.submit' })}
      </Button>
    </div>
  )
}

export default Comments
