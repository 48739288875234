import { JobDescription } from 'app/types'
import React, { FC, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

interface OwnProps {
  jobDescription: JobDescription
}

const CenterColumn: FC<OwnProps> = ({ jobDescription }) => {
  const { formatMessage } = useIntl()

  const title = (str: string): ReactNode => <h3 className={styles.title}>{str}</h3>
  const text = (str?: string): ReactNode => <p className={styles.text}>{str}</p>
  const empty = formatMessage({ id: 'recruiter.jobDescription.details.centerColumn.empty' })

  return (
    <div className={styles.wrapper}>
      {title(
        formatMessage({ id: 'recruiter.jobDescription.details.centerColumn.companyPresentation' })
      )}
      {text(jobDescription.companyDescription || empty)}
      {title(
        formatMessage({ id: 'recruiter.jobDescription.details.centerColumn.missionPresentation' })
      )}
      {text(jobDescription.description || empty)}
      {title(
        formatMessage({ id: 'recruiter.jobDescription.details.centerColumn.requiredProfile' })
      )}
      {text(jobDescription.soughtProfile || empty)}
    </div>
  )
}

export default CenterColumn
