import React, { FC } from 'react'
import ExportCv from './component'
import useCandidateProfile from './useCandidateProfile'
export type Size = 'small' | 'default'

interface OwnProps {
  applicantId: string
  debug?: boolean
  size?: Size
}

const ExportCVContainer: FC<OwnProps> = ({ applicantId, debug, size = 'default' }) => {
  const { isLoading, profile, qualityBoxSizes } = useCandidateProfile({ applicantId })

  const { languages, applicant, educationalBackgrounds, workExperiences, urlAvatar } = profile

  return (
    <ExportCv
      isLoadingData={isLoading}
      languages={languages}
      applicant={applicant}
      educationalBackgrounds={educationalBackgrounds}
      workExperiences={workExperiences}
      urlAvatar={urlAvatar}
      debug={debug}
      size={size}
      qualityBoxSizes={qualityBoxSizes}
    />
  )
}
export default ExportCVContainer
