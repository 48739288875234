import useInterlocutor from 'app/AuthenticatedApp/Messaging/hook/useInterlocutor'
import { useApi } from 'app/contexts/ApiContext'
import { useUser } from 'app/contexts/UserContext'
import { Thread } from 'app/types'
import React, { FC, useCallback, useEffect, useState } from 'react'
import Footer from './component'

interface OwnProps {
  thread: Thread
  active: string
  disabled: boolean
}

const FooterContainer: FC<OwnProps> = ({ thread: currentThread, active, disabled }) => {
  const { thread, media } = useApi()
  const user = useUser()
  const { interlocutor, interlocutorType } = useInterlocutor(currentThread)
  const [message, setMessage] = useState<string>('')
  const [attachment, setAttachment] = useState<string | undefined>(undefined)
  const [jobDescription, setJobDescription] = useState<string | undefined>(undefined)
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const [jobDescriptionSelected, setJobDescriptionSelected] = useState<string | undefined>(
    undefined
  )
  const [mutateCreateMessage] = thread.useCreateMessage()
  const [mutateUploadMedia, { isLoading: isUploadingAttachment }] = media.useMutationUploadMedia()

  const createMessage = useCallback(() => {
    if (message !== '' || attachment !== undefined || jobDescription !== undefined) {
      mutateCreateMessage({
        threadId: currentThread.id,
        receiver: interlocutor,
        receiverType: interlocutorType,
        message,
        author: user,
        attachment,
        jobDescription,
      })
      setMessage('')
      setAttachment(undefined)
      setJobDescription(undefined)
      setJobDescriptionSelected(undefined)
      setFileName(undefined)
    }
  }, [
    message,
    attachment,
    jobDescription,
    mutateCreateMessage,
    currentThread.id,
    interlocutor,
    interlocutorType,
    user,
  ])

  const uploadAttachment = useCallback(
    (file) => {
      setFileName(file.name)
      mutateUploadMedia({ file }).then((media) => {
        setAttachment(`/api/media_objects/${media?.id}`)
      })
    },
    [mutateUploadMedia]
  )

  const cancelAttachment = useCallback(() => {
    setFileName(undefined)
    setAttachment(undefined)
  }, [])

  const cancelJobDescription = useCallback(() => {
    setJobDescription(undefined)
    setJobDescriptionSelected(undefined)
  }, [])

  useEffect(() => {
    setMessage('')
    setFileName(undefined)
    setAttachment(undefined)
    setJobDescription(undefined)
    setJobDescriptionSelected(undefined)
  }, [active])

  return (
    <Footer
      disabled={disabled}
      message={message}
      setMessage={setMessage}
      setJobDescription={setJobDescription}
      jobDescriptionSelected={jobDescriptionSelected}
      setJobDescriptionSelected={setJobDescriptionSelected}
      cancelJobDescription={cancelJobDescription}
      createMessage={createMessage}
      uploadAttachment={uploadAttachment}
      cancelAttachment={cancelAttachment}
      fileName={fileName}
      isUploadingAttachment={isUploadingAttachment}
    />
  )
}

export default FooterContainer
