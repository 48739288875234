import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { Candidate } from 'app/types'

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#513cff',
    marginTop: 15,
  },
  text: {
    fontSize: 8,
    color: '#666666',
    marginTop: 8,
    lineHeight: 1.5,
    textAlign: 'justify',
  },
})

interface OwnProps {
  applicant: Candidate
}

const Advice: FC<OwnProps> = ({ applicant }): React.ReactElement => {
  return (
    <View>
      <Text style={styles.title}>Avis</Text>
      <Text style={styles.text}>
        {applicant.isidoreOpinion
          ? applicant.isidoreOpinion
          : "Aucun commentaire n'est renseigné pour le moment."}
      </Text>
    </View>
  )
}

export default Advice
