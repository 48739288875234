import React, { FC } from 'react'
import styles from './styles.module.scss'
import { ErrorMessage as Error, ErrorMessageProps } from 'formik'

type OwnProps = ErrorMessageProps

const ErrorMessage: FC<OwnProps> = ({ name }) => {
  return <Error name={name} component="div" className={styles.error} />
}

export default ErrorMessage
