import { FileType, MediaObject } from 'app/types'
import config from 'config'
import { MutationResultPair, QueryConfig, QueryResult, useMutation, useQuery } from 'react-query'
import { callAuthenticatedApi } from '../utils'

export interface MediaRequest {
  file: FileType['file']
}

export interface MediaResponse {
  contentUrl: string
  id: string
}

export const useMutationUploadMedia = (): MutationResultPair<
  MediaResponse,
  Error,
  MediaRequest,
  string
> => {
  return useMutation(
    async (payload: MediaRequest) => {
      const file = new FormData()
      file.append('file', payload.file as File)

      const { data } = await callAuthenticatedApi<MediaResponse>(config.api.media.create, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/ld+json',
        },
        data: file,
      })
      return data
    },
    {
      throwOnError: true,
    }
  )
}

export const useGetUploadMedia = (
  id: string,
  options?: QueryConfig<MediaObject>
): QueryResult<MediaObject> => {
  return useQuery<MediaObject, Error>(
    ['mediaObject', { id }],
    async () => {
      const { data } = await callAuthenticatedApi<MediaObject>(
        `${process.env.REACT_APP_API_BASE_URL}${id}`
      )
      return data
    },
    { ...options }
  )
}

export const useRemoveUploadMedia = (
  options?: object
): MutationResultPair<{}, Error, string, string> => {
  return useMutation(
    async (id: string) => {
      const { data } = await callAuthenticatedApi<{}>(
        `${process.env.REACT_APP_API_BASE_URL}${id}`,
        {
          method: 'DELETE',
        }
      )
      return data
    },
    {
      ...options,
      throwOnError: true,
    }
  )
}
