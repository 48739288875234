import AntFR from 'antd/es/locale/fr_FR'
import AntGB from 'antd/es/locale/en_GB'
import { Locale } from './index'

import moment from 'moment'
import 'moment/locale/fr'

export const antLocale: Function = (locale: keyof Locale) => {
  switch (locale) {
    case 'fr-FR':
      moment.locale('fr')
      return AntFR
    case 'en-GB':
      moment.locale('en')
      return AntGB
    default:
      throw new Error('Unknown language')
  }
}
