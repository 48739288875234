import { Rate } from 'antd'
import Bullet from 'recruiter/components/Bullet'
import { Candidate } from 'app/types'
import React from 'react'
import styles from './styles.module.scss'

interface OwnProps {
  applicant: Candidate
}

const ScorePane: React.FC<OwnProps> = ({ applicant }) => {
  return (
    <div>
      <section className={styles.wrapper}>
        <div className={styles.title}>Note isidore</div>
        <Rate
          value={applicant.score / 2}
          character={() => <Bullet size={17} />}
          allowHalf={true}
          disabled={true}
        />
        <div className={styles.rate}>{Math.round((applicant.score / 2) * 100) / 100 || 0} / 5</div>
      </section>

      <section>
        <>
          <div className={styles.subTitle}>Commentaire isidore</div>
          <div className={styles.content}>
            {applicant.isidoreOpinion
              ? applicant.isidoreOpinion
              : "Aucun commentaire n'est renseigné pour le moment."}
          </div>
        </>
      </section>

      <section>
        <div className={styles.subTitle}>Compétences</div>
        {applicant.qualities?.length ? (
          <div className={styles.qualities}>
            {applicant.qualities.map((quality, idx) => (
              <div key={`${quality.title}-${idx}`} className={styles.quality}>
                <div className={styles.qualityTitleWrapper}>
                  <div>{quality.title}</div>
                  <div>{quality.score} / 10</div>
                </div>
                {quality.description && (
                  <div className={styles.qualityDescription}>{quality.description}</div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div>Aucune compétence n'est renseigné pour le moment.</div>
        )}
      </section>
    </div>
  )
}

export default ScorePane
