import { LocationProvider } from '@reach/router'
import { ConfigProvider } from 'antd'
import { ApiProvider } from 'app/contexts/ApiContext'
import { AuthProvider } from 'app/contexts/AuthContext'
import { UserProvider } from 'app/contexts/UserContext'
import React, { FC } from 'react'
import { IntlProvider } from 'react-intl'
import translations, { Locale } from 'translations'
import { antLocale } from 'translations/utils'
import { setLocale } from 'yup'
import * as yupLocales from 'yup-locales'

const AppProviders: FC = ({ children }) => {
  const lang = ['en-GB', 'en-US'].includes(navigator.language) ? 'en-GB' : navigator.language
  const language = translations.hasOwnProperty(lang)
    ? (lang as keyof Locale)
    : ('fr-FR' as keyof Locale)

  const messages = translations[language]

  /**
   * @Todo Use a system that manages the locale differently
   */
  if (yupLocales?.[window.navigator.language.slice(0, 2)]) {
    setLocale(yupLocales?.[window.navigator.language.slice(0, 2)])
  }

  return (
    <LocationProvider>
      <IntlProvider locale={language} messages={messages}>
        <ConfigProvider locale={antLocale(`${language}`)}>
          <ApiProvider>
            <AuthProvider>
              <UserProvider>{children}</UserProvider>
            </AuthProvider>
          </ApiProvider>
        </ConfigProvider>
      </IntlProvider>
    </LocationProvider>
  )
}
export default AppProviders
