import React, { useEffect, useMemo, useState } from 'react'
import { pdf } from '@react-pdf/renderer'
import Document from './Document'
import useCandidateProfile from './useCandidateProfile'

export type Size = 'small' | 'default'

interface BlobResponse {
  isLoading: boolean
  blob?: Blob
}

export const useExportCVBlob = ({ applicantId }: { applicantId: string }): BlobResponse => {
  const { isLoading, profile, qualityBoxSizes } = useCandidateProfile({ applicantId })
  const [blob, setBlob] = useState<Blob | undefined>()

  useEffect(() => {
    if (!isLoading && profile.applicant) {
      pdf(
        <Document
          applicant={profile.applicant}
          languages={profile.languages}
          educationalBackgrounds={profile.educationalBackgrounds}
          workExperiences={profile.workExperiences}
          urlAvatar={profile.urlAvatar}
          qualityBoxSizes={qualityBoxSizes}
        />
      )
        .toBlob()
        .then((blob) => {
          setBlob(blob)
        })
    }
  }, [profile])

  return {
    isLoading: isLoading && !profile.applicant,
    blob: useMemo(() => blob, [blob?.size]),
  }
}

export default useExportCVBlob
