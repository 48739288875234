import { JobDescription } from 'app/types'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'
import moment from 'moment'

interface OwnProps {
  jobDescription: JobDescription
}

const LeftColumn: FC<OwnProps> = ({ jobDescription }) => {
  const { formatMessage } = useIntl()
  return (
    <div className={styles.wrapper}>
      <ul>
        <li>
          <span>
            {formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.location' })}
          </span>
          {jobDescription.location.split(',').map((text, key) => (
            <p key={key}>{text}</p>
          ))}
        </li>
        <li>
          <span>
            {formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.contract' })}
          </span>
          {jobDescription.contractType.name}
        </li>
        <li>
          <span>{formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.start' })} </span>
          {jobDescription.asapStart
            ? formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.asap' })
            : moment(jobDescription.startDate).format('DD.MM.YYYY')}
        </li>
        <li>
          <span>{formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.salary' })}</span>
          {jobDescription.minimumSalary} {`->`} {jobDescription.maximumSalary}
        </li>
        <li>
          <span>
            {formatMessage({ id: 'recruiter.jobDescription.details.leftColumn.experience' })}
          </span>
          {formatMessage(
            {
              id: 'minimumWorkExperienceDuration',
            },
            { count: jobDescription.minimumWorkExperienceDuration }
          )}
        </li>
      </ul>
    </div>
  )
}

export default LeftColumn
