import React, { FC, SetStateAction, Dispatch } from 'react'
import Actions from './Actions'
import Input from './Input'
import styles from './styles.module.scss'

interface OwnProps {
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  setJobDescription: Dispatch<SetStateAction<string | undefined>>
  setJobDescriptionSelected: Dispatch<SetStateAction<string | undefined>>
  createMessage: CallableFunction
  uploadAttachment: CallableFunction
  cancelAttachment: CallableFunction
  cancelJobDescription: CallableFunction
  fileName?: string
  jobDescriptionSelected?: string
  isUploadingAttachment: boolean
  disabled: boolean
}

const Footer: FC<OwnProps> = ({
  message,
  setMessage,
  createMessage,
  uploadAttachment,
  cancelAttachment,
  fileName,
  isUploadingAttachment,
  disabled,
  setJobDescription,
  setJobDescriptionSelected,
  jobDescriptionSelected,
  cancelJobDescription,
}) => {
  return (
    <div className={styles.container}>
      <Input
        disabled={disabled}
        message={message}
        setMessage={setMessage}
        createMessage={createMessage}
      />
      <Actions
        disabled={disabled}
        message={message}
        setMessage={setMessage}
        setJobDescription={setJobDescription}
        cancelJobDescription={cancelJobDescription}
        createMessage={createMessage}
        uploadAttachment={uploadAttachment}
        cancelAttachment={cancelAttachment}
        setJobDescriptionSelected={setJobDescriptionSelected}
        fileName={fileName}
        jobDescriptionSelected={jobDescriptionSelected}
        isUploadingAttachment={isUploadingAttachment}
      />
    </div>
  )
}

export default Footer
