import React, { ChangeEvent, FC } from 'react'
import { useIntl } from 'react-intl'
import InputText from '../base/InputText'
import { useCustomField } from '../hooks/useCustomField'
import { FormInputEmailType } from './type'
import { FormInputEmailValidation } from './validation'
import { InputProps } from 'antd/es/input'

const INPUT_DEFAULT_NAME = 'email'

interface OwnProps extends InputProps {
  name?: string
  value: FormInputEmailType
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  dataCy?: string
}
const InputEmail: FC<OwnProps> = ({ value, onChange, name, dataCy, ...restProps }) => {
  const { formatMessage } = useIntl()
  const inputName = name ?? INPUT_DEFAULT_NAME

  const [field, meta, helpers] = useCustomField<FormInputEmailType>(
    inputName,
    FormInputEmailValidation(formatMessage)
  )

  return (
    <InputText
      {...field}
      {...restProps}
      label={formatMessage({ id: 'form.email.label' })}
      placeholder={formatMessage({ id: 'form.email.placeholder' })}
      onChange={(e) => onChange || helpers.setValue(e.target.value)}
      id={inputName}
      type="email"
      data-cy={dataCy}
      error={meta.touched && meta.error}
    />
  )
}

export default InputEmail
