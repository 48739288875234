import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { Candidate } from 'app/types'

const styles = StyleSheet.create({
  wrapper: {
    width: 188,
    marginTop: 25,
    textAlign: 'left',
  },
  category: {
    fontSize: 13,
    color: 'black',
    fontWeight: 'bold',
  },
  items: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: 10,
    color: '#666666',
  },
})
interface OwnProps {
  applicant: Candidate
}

const Hobbies: FC<OwnProps> = ({ applicant }): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.category}>Loisirs</Text>
      <View style={styles.items}>
        {applicant.hobbies.map((name, i) => (
          <View key={i}>
            <Text style={[styles.name, i > 0 ? { marginTop: 5 } : {}]}>{name}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Hobbies
