import { Button } from 'antd'
import { Message } from 'app/types'
import Loader from 'components/Loader'
import React, { FC, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { IsFetchingMoreValue } from 'react-query'
import Conversation from './Conversation'
import styles from './styles.module.scss'

interface OwnProps {
  active: string
  messages?: Message[]
  loading: boolean
  hasNextPage?: boolean
  handleLoadMore: CallableFunction
  isFetchingMore?: IsFetchingMoreValue
  disabled: boolean
}

const Body: FC<OwnProps> = ({
  active,
  loading,
  messages,
  hasNextPage,
  handleLoadMore,
  isFetchingMore,
  disabled,
}) => {
  const { formatMessage } = useIntl()

  const [shouldScroll, setShouldScroll] = useState<boolean>(true)

  const conversationsRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styles.container}>
      <div className={styles.conversationWrapper}>
        {loading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            {hasNextPage && (
              <div
                className={styles.loadMore}
                onClick={() => {
                  setShouldScroll(false)
                  handleLoadMore()
                }}
              >
                <Button type="default" loading={!!isFetchingMore}>
                  {formatMessage({ id: 'global.messaging.message.body.loadMore' })}
                </Button>
              </div>
            )}
            <div className={styles.conversationList} data-cy="messaging.body.messages">
              {!disabled && (
                <Conversation
                  active={active}
                  conversationsRef={conversationsRef}
                  messages={messages}
                  shouldScroll={shouldScroll}
                  setShouldScroll={setShouldScroll}
                />
              )}
            </div>
          </>
        )}
        <div ref={conversationsRef} />
      </div>
    </div>
  )
}

export default Body
