import { CloseOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { default as React, FC, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './styles.module.scss'

const modalRoot = document.getElementById('modalRoot')

interface FullscreenModalProps {
  onClose?: () => {}
  canClose?: boolean
  scheme?: 'blue'
}

const FullscreenModal: FC<FullscreenModalProps> = ({
  children,
  onClose = () => window.history.back(),
  canClose = true,
  scheme,
}) => {
  const [container] = useState(document.createElement('div'))
  useEffect(() => {
    if (!modalRoot) {
      return
    }
    modalRoot.appendChild(container)
    return () => {
      modalRoot.removeChild(container)
    }
  }, [container])
  return ReactDOM.createPortal(
    <div className={cn(styles.fullscreenModal, { [styles.blue]: scheme === 'blue' })}>
      {canClose && (
        <div className={styles.close}>
          <CloseOutlined role="button" aria-label="close" onClick={() => onClose()} />
        </div>
      )}
      {children}
    </div>,
    container
  )
}

export default FullscreenModal
