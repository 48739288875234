import config from 'config'
import { MutationResultPair, useMutation } from 'react-query'
import { callApi } from '../utils'

interface LoginRequest {
  email: string
  password: string
}

interface LoginResponse {
  token: string
  refresh_token: string
}

export const useMutationLogin = (): MutationResultPair<
  LoginResponse,
  Error,
  LoginRequest,
  string
> => {
  return useMutation(async (payload: LoginRequest) => {
    const { data } = await callApi<LoginResponse>(config.api.login, {
      method: 'POST',
      data: payload,
    })
    return data
  })
}

type RenewTokenRequest = string
type RenewTokenResponse = LoginResponse

export const useMutateRenewToken = (): MutationResultPair<
  RenewTokenResponse,
  Error,
  RenewTokenRequest,
  string
> => {
  return useMutation(async (token: RenewTokenRequest) => {
    const { data } = await callApi<RenewTokenResponse>(config.api.renewToken, {
      method: 'POST',
      data: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        refresh_token: token,
      },
    })

    return data
  })
}

interface RecoverPasswordRequest {
  email: string
}

export const useMutationRecoverPassword = (): MutationResultPair<
  {},
  Error,
  RecoverPasswordRequest,
  string
> => {
  return useMutation(
    async (payload: RecoverPasswordRequest) => {
      const { data } = await callApi<{}>(config.api.password.recover, {
        method: 'POST',
        data: payload,
      })
      return data
    },
    {
      throwOnError: true,
    }
  )
}

interface ResetPasswordRequest {
  password: string
  token: string
}

interface ResetPasswordResponse {
  email: string
}

export const useMutationResetPassword = (): MutationResultPair<
  ResetPasswordResponse,
  Error,
  ResetPasswordRequest,
  string
> => {
  return useMutation(
    async (payload: ResetPasswordRequest) => {
      const { data } = await callApi<ResetPasswordResponse>(config.api.password.reset, {
        method: 'POST',
        data: payload,
      })
      return data
    },
    {
      throwOnError: true,
    }
  )
}
