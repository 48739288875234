import { MessageStatus } from 'app/AuthenticatedApp/Messaging/container'
import { UserList } from 'app/AuthenticatedApp/Messaging/hook/useInterlocutor'
import { User, UserType } from 'app/contexts/AuthContext/types'
import { Message, Thread } from 'app/types'
import config from 'config'
import {
  InfiniteQueryResult,
  MutationResultPair,
  queryCache,
  QueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query'
import { Hydra } from '../types'
import { callAuthenticatedApi } from '../utils'

export const useListThreads = (
  searchValue?: string,
  selectValue: MessageStatus = MessageStatus.ALL
): QueryResult<Thread[]> => {
  return useQuery<Thread[], Error>(
    ['threads', { selectValue, searchValue }],
    async () => {
      const q = searchValue ? `&search=${searchValue}` : ''
      const { data } = await callAuthenticatedApi<Thread[]>(
        `${config.api.thread.list}?scope=${selectValue}${q}`
      )
      return data
    },
    {
      refetchInterval: 20000,
    }
  )
}

interface CreateThreadRequest {
  receiver: string
  receiverType: UserType
}

export const useCreateThread = (
  isSuccess: boolean
): MutationResultPair<Thread, Error, CreateThreadRequest, () => void> => {
  return useMutation(
    async (payload: CreateThreadRequest) => {
      const { data } = await callAuthenticatedApi<Thread>(config.api.thread.create, {
        method: 'POST',
        data: {
          receiver: `/api/${payload.receiverType}s/${payload.receiver}`,
        },
      })
      return data
    },
    {
      throwOnError: true,
      onSuccess() {
        queryCache.removeQueries('threads')
      },
    }
  )
}

export const useListMessages = (
  id: string,
  desactive: boolean
): InfiniteQueryResult<Hydra<Message[]>> => {
  return useInfiniteQuery<Hydra<Message[]>, Error>(
    ['thread', { id }],
    async (key, { id }, nextId = 1) => {
      const { data } = await callAuthenticatedApi<Hydra<Message[]>>(
        `${config.api.thread.list}/${id}/messages?page=${nextId}`,
        {
          headers: {
            accept: 'application/ld+json',
          },
        }
      )
      return data
    },
    {
      enabled: !desactive,
      refetchInterval: 5000,
      getFetchMore: (listMessage: Hydra<Message[]>) => {
        const nextLink = listMessage?.['hydra:view']?.['hydra:next']?.split('=')

        if (!nextLink) {
          return null
        }

        return nextLink[1]
      },
    }
  )
}

interface CreateMessageRequest {
  receiver: UserList
  receiverType: UserType
  message: string
  threadId: string
  author: User
  attachment?: string
  jobDescription?: string
}

export const useCreateMessage = (): MutationResultPair<
  Message,
  Error,
  CreateMessageRequest,
  () => void
> => {
  return useMutation(
    async (payload: CreateMessageRequest) => {
      const { data } = await callAuthenticatedApi<Message>(config.api.message.create, {
        method: 'POST',
        data: {
          message: payload.message,
          receiver: `/api/${payload.receiverType}s/${payload.receiver.id}`,
          attachment: payload.attachment,
          jobDescription: payload.jobDescription,
        },
      })
      return data
    },
    {
      throwOnError: true,
      onMutate: ({ author, threadId, message, receiver, attachment, jobDescription }) => {
        const [previousMessages, ...rest] = queryCache.getQueryData<Hydra<Message[]>[]>([
          'thread',
          { id: threadId },
        ])

        queryCache.setQueryData(['thread', { id: threadId }], () => [
          ...[
            {
              'hydra:member': [
                {
                  author,
                  createdAt: new Date().toString(),
                  id: Date.now() + Math.random(),
                  message,
                  read: false,
                  receiver,
                  thread: threadId,
                  attachment,
                  jobDescription,
                },
                ...previousMessages?.['hydra:member'],
              ],
            },
          ],
          ...rest,
        ])

        return () => queryCache.setQueryData(['thread', { id: threadId }], () => previousMessages)
      },
    }
  )
}

type ArchiveThreadRequest = { id: Thread['id'] }

export const useArchiveThread = (): MutationResultPair<
  Thread,
  Error,
  ArchiveThreadRequest,
  Thread[]
> => {
  return useMutation(
    async (payload) => {
      const { data } = await callAuthenticatedApi<Thread>(
        `${config.api.thread.archive}/${payload.id}/archive`,
        { method: 'GET' }
      )
      return data
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries('threads')
      },
    }
  )
}

interface PatchMessageRequest extends Nullable<Partial<CreateMessageRequest>> {
  messageId: string
}

export const usePatchMessage = (): MutationResultPair<
  Message,
  Error,
  PatchMessageRequest,
  () => void
> => {
  return useMutation(
    async (payload: PatchMessageRequest) => {
      const { data } = await callAuthenticatedApi<Message>(
        `${config.api.message.create}/${payload.messageId}`,
        {
          method: 'PATCH',
          data: {
            ...payload,
          },
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      return data
    },
    {
      throwOnError: true,
    }
  )
}
