export default {
  common: {
    add: 'Ajouter',
    remove: 'Supprimer',
  },
  notifications: {
    error: 'Une erreur est survenue',
    success: 'Les informations ont bien été enregistrées',
    bankDetails: 'Les informations bancaires ont bien été mise à jour',
    profile: 'Votre profil a bien été mis à jour',
    fileError: "Erreur lors de l'envoi du fichier",
    invalidLink: "Le lien n'est plus valide",
    loginError: "Échec de l'authentification, veuillez réessayer.",
  },
  refreshToken: {
    message: 'Impossible de renouveler votre identifiant de connexion',
  },
  validation: {
    field: {
      required: 'Ce champ est requis',
      invalid: 'Ce champ est invalide',
      badFormat: 'Format non supporté',
      passwordMisMatch: 'Les mots de passe ne correspondent pas',
      terms: "Merci d'accepter les CGV",
      password: 'Le mot de passe doit contenir au moins 8 caractères',
      email: 'Veuillez saisir un email valide',
      typeNumber: 'Veuillez saisir uniquement des chiffres',
      date: {
        endBeforeStart: 'La date de fin ne peut être antérieure à la date de début',
      },
    },
    phone: 'Numéro de téléphone invalide',
  },
  languageLevel: {
    notions: 'Notions',
    intermediate: 'Intermédiaire',
    advanced: 'Avancé',
    fluent: 'Courant',
    bilingual: 'Bilingue',
  },
  contractTypes: {
    permanent: 'CDI',
    fixedTerm: 'CDD',
    internship: 'Stage',
    professionalTraining: 'Contrat pro',
    freelance: 'Freelance',
  },
  educationLevel: {
    5: '< à Bachelor',
    6: 'Licence ou équivalent',
    7: 'Master ou équivalent (ex: ingénieur)',
    8: 'Doctorat ou équivalent',
  },

  language: {
    de: 'Allemand',
    en: 'Anglais',
    ar: 'Arabe',
    ca: 'Catalan',
    zh: 'Chinois (Mandarin)',
    ko: 'Coréen',
    hr: 'Croate',
    da: 'Danois',
    es: 'Espagnol',
    et: 'Estonien',
    fi: 'Finnois',
    fr: 'Français',
    el: 'Grec',
    he: 'Hébreu',
    hi: 'Hindi',
    hu: 'Hongrois',
    is: 'Islandais',
    it: 'Italien',
    ja: 'Japonais',
    lv: 'Letton',
    lt: 'Lituanien',
    lb: 'Luxembourgeois',
    nl: 'Néerlandais',
    no: 'Norvégien',
    pl: 'Polonais',
    pt: 'Portugais',
    ru: 'Russe',
    sr: 'Serbe',
    sk: 'Slovaque',
    sl: 'Slovène',
    sv: 'Suédois',
    th: 'Thaï',
    cs: 'Tchèque',
    tr: 'Turc',
    uk: 'Ukrainien',
    vi: 'Vietnamien',
  },
  companyType: {
    startup: 'Startup',
    sme: 'PME',
    group: 'Grand groupe',
  },
  minimumWorkExperienceDuration: `{ count, plural,
      =0 {moins d'un an}
      one {{count} an}
      =20 {{count} ans et plus}
      other {{count} ans}
    }`,
  missionStatus: {
    pending: 'Mission en cours',
    // eslint-disable-next-line @typescript-eslint/camelcase
    cancelled_company: "Mission annulée par l'entreprise",
    // eslint-disable-next-line @typescript-eslint/camelcase
    cancelled_isidore: 'Mission annulée par isidore',
    // eslint-disable-next-line @typescript-eslint/camelcase
    recruited_isidore: 'Mission recrutée par isidore',
    archived: 'Mission archivée',
    validated: 'Mission validée',
  },
  today: "Aujourd'hui",
  remainingDays: 'Jours restants',
}
