import { EditOutlined, FolderOutlined } from '@ant-design/icons'
import { navigate } from '@reach/router'
import { Button } from 'antd'
import SubMenu from 'app/AuthenticatedApp/components/SubMenu'
import { useApi } from 'app/contexts/ApiContext'
import { JobDescription, JobDescriptionStatus } from 'app/types'
import config from 'config'
import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'
import CenterColumn from './CenterColumn'
import LeftColumn from './LeftColumn'
import styles from './styles.module.scss'

interface OwnProps {
  jobDescription: JobDescription
  showBreadcrumb?: boolean
  editable?: boolean
}

const Details: FC<OwnProps> = ({ jobDescription, showBreadcrumb, editable }) => {
  const { formatMessage, formatDate } = useIntl()
  const { jobDescription: jobDescriptionApi } = useApi()
  const [mutateUpdateJobDescription, { isLoading }] = jobDescriptionApi.useUpdateJobDescription()

  const toggleJobDescriptionStatus = useCallback(
    () =>
      mutateUpdateJobDescription({
        id: jobDescription.id,
        status:
          jobDescription.status === JobDescriptionStatus.ACTIVE
            ? JobDescriptionStatus.ARCHIVED
            : JobDescriptionStatus.ACTIVE,
      }),
    [jobDescription.id, jobDescription.status, mutateUpdateJobDescription]
  )
  return (
    <>
      <div className={styles.content}>
        {showBreadcrumb && (
          <SubMenu
            items={[
              {
                href: config.routes.recruiter.jobDescription.list,
                value: formatMessage({ id: 'recruiter.jobDescription.list.breadcrumb' }),
              },
              {
                href: '#',
                value: jobDescription.name,
              },
            ]}
          />
        )}

        <p className={styles.createdAt}>{`${formatMessage({
          id: 'recruiter.jobDescription.details.createdAt',
        })} ${formatDate(new Date(jobDescription.createdAt))}`}</p>
        <div className={styles.topPage}>
          <h1>{jobDescription.name}</h1>
          <div className={styles.actions}>
            {editable && (
              <Button
                icon={<EditOutlined />}
                type="default"
                shape="round"
                onClick={() =>
                  navigate(
                    config.routes.recruiter.jobDescription.update.replace(':id', jobDescription.id),
                    {
                      state: {
                        jobDescription,
                        editing: true,
                      },
                    }
                  )
                }
              >
                {formatMessage({ id: 'recruiter.jobDescription.details.topPage.edit' })}
              </Button>
            )}
            <Button
              icon={<FolderOutlined />}
              type="default"
              shape="round"
              onClick={() => toggleJobDescriptionStatus()}
              loading={isLoading}
              data-cy="archive"
            >
              {jobDescription.status === JobDescriptionStatus.ACTIVE
                ? formatMessage({ id: 'recruiter.jobDescription.details.topPage.archive' })
                : formatMessage({ id: 'recruiter.jobDescription.details.topPage.active' })}
            </Button>
          </div>
        </div>
        <div className={styles.columnWrapper}>
          <LeftColumn jobDescription={jobDescription} />
          <CenterColumn jobDescription={jobDescription} />
        </div>
      </div>
    </>
  )
}

Details.defaultProps = {
  showBreadcrumb: true,
  editable: true,
}

export default Details
