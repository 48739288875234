import { Candidate } from 'app/types/candidate'
import config from 'config'
import { QueryConfig, QueryResult, useQuery } from 'react-query'
import { callAuthenticatedApi } from '../utils'

interface CandidatesQueryResult {
  data: Candidate[]
}

export const useCandidateList = (
  filters: object = {},
  options: QueryConfig<Candidate[]>
): QueryResult<Candidate[]> => {
  return useQuery<Candidate[], Error>(
    'candidates',
    async () => {
      const { data } = await callAuthenticatedApi<Candidate[]>(config.api.candidate.list, {
        params: filters,
      })
      return data
    },
    {
      ...options,
    }
  )
}

interface CandidateQueryResult {
  data: Candidate
}

export const useCandidate = (id: string): QueryResult<Candidate> => {
  return useQuery<Candidate, Error>(`candidate-${id}`, async () => {
    const { data } = await callAuthenticatedApi<Candidate>(`${config.api.candidate.list}/${id}`)
    return data
  })
}
