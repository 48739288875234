import SubMenu from 'app/AuthenticatedApp/components/SubMenu'
import { UserType } from 'app/contexts/AuthContext/types'
import { useUser } from 'app/contexts/UserContext'
import { Thread } from 'app/types'
import config from 'config'
import React, { Dispatch, FC, SetStateAction, useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { MessageStatus } from './container'
import Header from './Header'
import Message from './Message'
import Sidebar from './Sidebar'
import styles from './styles.module.scss'
import useMediaQuery from 'app/hooks/useMediaQuery'

interface OwnProps {
  threads?: Thread[]
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  selectValue: MessageStatus
  setSelectValue: Dispatch<SetStateAction<MessageStatus>>
  active: string
  setActive: Dispatch<SetStateAction<string>>
  isLoading: boolean
  openedFirst: boolean
}

const Messaging: FC<OwnProps> = ({
  threads,
  searchValue,
  setSearchValue,
  selectValue,
  setSelectValue,
  active,
  setActive,
  isLoading,
  openedFirst,
}) => {
  const { formatMessage } = useIntl()

  const user = useUser()

  const isMobile = useMediaQuery(config.breakpoints.md)
  const [isMessageView, setIsMessageView] = useState<boolean>(!!active)

  const currentThread = useMemo<Thread | undefined>(
    () => threads?.find(({ id }) => id === active) as Thread,
    [active, threads]
  )

  const [findProfile, setFindProfile] = useState<boolean>(!isLoading && !currentThread)
  const isMessageDisabled = useMemo(() => !isLoading && (findProfile || !threads?.length), [
    findProfile,
    isLoading,
    threads,
  ])

  useEffect(() => {
    if (isMobile && openedFirst) {
      setIsMessageView(false)
    }
  }, [isMobile, openedFirst])

  return (
    <>
      <SubMenu
        items={[
          {
            href: config.routes[user.type as NonNullable<UserType>].messaging,
            value: formatMessage({ id: 'global.messaging.breadcrumb' }),
          },
        ]}
      />
      <div className={styles.wrapper}>
        {(!isMobile || !isMessageView) && (
          <Header
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setFindProfile={setFindProfile}
            setIsMessageView={setIsMessageView}
          />
        )}

        <div className={styles.content}>
          {(!isMobile || !isMessageView) && (
            <Sidebar
              threads={threads}
              active={active}
              setActive={setActive}
              setFindProfile={setFindProfile}
              desactive={findProfile}
              isLoading={isLoading}
              setIsMessageView={setIsMessageView}
            />
          )}
          {(!isMobile || isMessageView) && (
            <Message
              setFindProfile={setFindProfile}
              active={active}
              disabled={isMessageDisabled}
              thread={currentThread}
              isThreadLoading={isLoading}
              isMobile={isMobile}
              setIsMessageView={setIsMessageView}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Messaging
