import React, { FC } from 'react'
import { Text, StyleSheet, View, Image } from '@react-pdf/renderer'
import moment from 'moment'
import { Candidate } from 'app/types'
import { generateInitials } from 'app/AuthenticatedApp/components/Avatar/component'

// https://mybyways.com/blog/convert-svg-to-png-using-your-browser
import UserIcon from './icons/user.png'
import MailIcon from './icons/mail.png'
import LocationIcon from './icons/location.png'
import PhoneIcon from './icons/phone.png'

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#513cff',
    borderRadius: 5,
    width: 188,
    paddingTop: 20,
    paddingBottom: 20,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  nameWrapper: {
    marginTop: 15,
  },
  name: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22,
  },
  contactWrapper: {
    marginTop: 20,
    textAlign: 'left',
    paddingLeft: 15,
    paddingRight: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  contact: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  icon: {
    width: 10,
    height: 10,
    marginRight: 10,
  },
  circle: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#ccc',
    position: 'relative',
  },
  avatarName: {
    fontSize: 25,
    height: '100%',
    marginTop: 15,
  },
})
interface OwnProps {
  applicant: Candidate
  urlAvatar: string
}

const Personnal: FC<OwnProps> = ({ applicant, urlAvatar }): React.ReactElement => (
  <View style={styles.wrapper}>
    <View style={styles.center}>
      {urlAvatar ? (
        <Image
          style={styles.avatar}
          source={{ uri: urlAvatar, method: 'GET', body: null, headers: {} }}
        />
      ) : (
        <View style={styles.circle}>
          <Text style={styles.avatarName}>
            {generateInitials(applicant.firstname, applicant.lastname)}
          </Text>
        </View>
      )}
    </View>
    <View style={styles.nameWrapper}>
      <Text style={styles.name}>{applicant.firstname}</Text>
      {applicant.lastname && <Text style={styles.name}>{applicant.lastname}</Text>}
    </View>
    <View style={styles.contactWrapper}>
      <View style={styles.row}>
        <Image style={styles.icon} source={UserIcon} />
        <Text style={styles.contact}>{moment(applicant.dateOfBirth).format('DD.MM.YYYY')}</Text>
      </View>
      <View style={styles.row}>
        <Image style={styles.icon} source={MailIcon} />
        <Text style={styles.contact}>{applicant.email}</Text>
      </View>
      <View style={styles.row}>
        <Image style={styles.icon} source={LocationIcon} />
        <Text style={styles.contact}>{applicant.defaultMobility?.city}</Text>
      </View>
      <View style={styles.row}>
        <Image style={styles.icon} source={PhoneIcon} />
        <Text style={styles.contact}>{applicant.phoneNumber}</Text>
      </View>
    </View>
  </View>
)

export default Personnal
