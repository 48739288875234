import { useApi } from 'app/contexts/ApiContext'
import React, { FC, useMemo } from 'react'
import AvatarContainer from './component'

export interface OwnProps {
  image?: string
  size?: number
  firstname?: string
  lastname?: string
  className?: string
  isloading?: boolean
}

const ApplicantContainer: FC<OwnProps> = ({
  image,
  size,
  firstname,
  lastname,
  className,
  isloading: isParentLoading,
}) => {
  const { media } = useApi()

  const { isLoading: isLoadingAvatar, data } = media.useGetUploadMedia(image as string, {
    enabled: !!image,
  })

  const isLoading = useMemo(() => {
    return isParentLoading || isLoadingAvatar
  }, [isLoadingAvatar, isParentLoading])

  return (
    <AvatarContainer
      image={image}
      size={size}
      firstname={firstname}
      lastname={lastname}
      className={className}
      isLoadingAvatar={isLoading}
      mediaObject={data}
    />
  )
}
export default ApplicantContainer
