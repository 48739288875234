import React, { FC, useMemo } from 'react'
import File from './component'
import { useApi } from 'app/contexts/ApiContext'
import { useUser } from 'app/contexts/UserContext'

interface OwnProps {
  attachment: string
  authorId: string
}

const FileContainer: FC<OwnProps> = ({ attachment, authorId }) => {
  const { media } = useApi()
  const user = useUser()

  const { isLoading, data } = media.useGetUploadMedia(attachment)

  const [
    mutateRemoveUploadMedia,
    { isLoading: isDeletting, isSuccess: isDeleteSuccess },
  ] = media.useRemoveUploadMedia()

  const canDelete = useMemo(() => authorId === user.id, [authorId, user])

  if (isDeleteSuccess) {
    return null
  }

  return (
    <File
      isLoading={isLoading || isDeletting}
      attachment={data}
      canDelete={canDelete}
      removeUploadMedia={() => mutateRemoveUploadMedia(attachment)}
    />
  )
}

export default FileContainer
