import React, { FC } from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import LocationPinkIcon from './icons/location-pink.png'
import LocationBlueIcon from './icons/location-blue.png'
import Divider from '../Divider'

type COLOR = '#ff4e7b' | '#513cff'

const IconMap: Map<COLOR, string> = new Map([
  ['#ff4e7b', LocationPinkIcon],
  ['#513cff', LocationBlueIcon],
])

export interface BlocItem {
  startDate?: string
  endDate: string
  title: string
  site: string
  location?: string
  description?: string
}

interface OwnProps {
  items: Array<BlocItem>
  color: COLOR
  title: string
}

const Bloc: FC<OwnProps> = ({ items, color, title }): React.ReactElement => {
  return (
    <View
      style={{
        marginTop: 5,
      }}
      wrap={false}
    >
      <Divider />
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 16,
          color,
          marginTop: 5,
        }}
      >
        {title}
      </Text>
      {items.map(({ startDate, endDate, title, site, location, description }, key) => (
        <View style={{ width: '100%' }} key={key} wrap={false}>
          <View
            style={{
              maxWidth: '100%',
              width: '100%',
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                borderRightWidth: 1,
                borderRightColor: color,
                minWidth: 70,
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <Text style={{ fontSize: 8, fontWeight: 'bold', color: '#666666' }}>{endDate}</Text>
              {startDate && <Text style={{ fontSize: 8, color: '#B5BAB7' }}>{startDate}</Text>}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10,
                maxWidth: '100%',
                marginRight: 70,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  color: 'black',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  display: 'flex',
                  minHeight: 15,
                }}
                wrap
              >
                <View wrap>
                  <Text style={{ fontWeight: 'bold' }}>{title}</Text>
                </View>
                <Text> </Text>
                <View wrap>
                  <Text>chez</Text>
                </View>
                <Text> </Text>
                <View>
                  <Text
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {site}
                  </Text>
                </View>
              </Text>
              {location && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{ width: 8, height: 8, marginRight: 3 }}
                    source={IconMap.get(color) as string}
                  />
                  <Text style={{ fontSize: 8, color }}>{location}</Text>
                </View>
              )}
            </View>
          </View>
          <View>
            <Text
              wrap
              style={{
                marginLeft: 80,
                color: '#666666',
                fontSize: 8,
                lineHeight: 1.5,
                marginTop: 5,
              }}
            >
              {description}
            </Text>
          </View>
        </View>
      ))}
    </View>
  )
}

export default Bloc
