import { Avatar as AntAvatar, Skeleton } from 'antd'
import { MediaObject } from 'app/types'
import cn from 'classnames'
import React, { FC } from 'react'
import styles from './styles.module.scss'

export interface AvatarProps {
  image?: string
  size?: number
  firstname?: string
  lastname?: string
  className?: string
  isLoadingAvatar: boolean
  mediaObject?: MediaObject
}

export const generateInitials = (firstname?: string, lastname?: string): string =>
  [firstname?.[0] ?? '', lastname?.[0] ?? ''].join('').toUpperCase()

const Avatar: FC<AvatarProps> = ({
  image,
  firstname,
  lastname,
  size = 32,
  className,
  isLoadingAvatar,
  mediaObject,
  ...props
}) => {
  const classNames = cn('mr-2', styles.avatar, className)

  if (!image && !isLoadingAvatar) {
    const initials = generateInitials(firstname, lastname)
    return (
      <AntAvatar
        style={{
          fontSize: size / 2,
        }}
        className={classNames}
        size={size}
        {...props}
      >
        {initials}
      </AntAvatar>
    )
  }

  if (isLoadingAvatar) {
    return <Skeleton.Avatar className="mr-1" active />
  } else {
    return (
      <AntAvatar
        className={classNames}
        size={size}
        src={`${process.env.REACT_APP_API_BASE_URL}${mediaObject?.contentUrl}`}
        {...props}
      />
    )
  }
}

export default Avatar
