import * as Yup from 'yup'

export const modalEducationSchema = (formatMessage: Function): Yup.ObjectSchema =>
  Yup.object().shape({
    recipients: Yup.array()
      .of(Yup.string())
      .min(1)
      .required(formatMessage({ id: 'validation.field.required' })),
  })

export default modalEducationSchema
