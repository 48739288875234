import { Opportunity } from './'

export enum JobDescriptionStatus {
  ARCHIVED = 'archived',
  ACTIVE = 'active',
}

export type JobDescription = {
  id: string
  name: string
  status: JobDescriptionStatus
  contractType: ContractType
  location: string
  startDate: string
  asapStart: boolean
  minimumSalary: number
  maximumSalary: number
  minimumWorkExperienceDuration: number | undefined
  minimumEducationLevel: number | undefined
  companyDescription: string
  description: string
  soughtProfile: string
  createdAt: string
  updatedAt: string
  opportunities?: Array<Opportunity>
}

interface ContractType {
  id: string
  code: string
  name: string
}
