import { navigate, RouteComponentProps } from '@reach/router'
import { useAuth } from 'app/contexts/AuthContext'
import { UserStatus } from 'app/contexts/AuthContext/types'
import config from 'config'
import React, { useEffect } from 'react'

interface OwnProps extends RouteComponentProps {
  token?: string
  refreshToken?: string
}

const CandidateLinkedinAuth: React.FC<OwnProps> = ({ token, refreshToken }) => {
  const { setUser, data } = useAuth()
  useEffect(() => {
    ;(async () => {
      if (!token || !refreshToken) return navigate(config.routes.candidate.login)

      if (!data.user) setUser(token, refreshToken)

      if (data.user?.status === UserStatus.STATUS_INCOMPLETE)
        return navigate(config.routes.candidate.signin, {
          state: { user: data.user },
        })

      return navigate(config.routes.candidate.dashboard)
    })()
  }, [data, setUser, token, refreshToken])

  return <div>Linkedin auth</div>
}

export default CandidateLinkedinAuth
