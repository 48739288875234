import React, { FC } from 'react'
import { Candidate } from 'app/types'
import { useApi } from 'app/contexts/ApiContext'

import Applicant from './component'

export type Context = 'library' | 'missions' | 'messages'

interface OwnProps {
  applicant: Candidate
  onClose?: Function
  context: Context
}

const ApplicantContainer: FC<OwnProps> = ({ applicant, onClose, context }) => {
  const { candidate, media } = useApi()

  /**
   * Make a request to retrieve the candidate to mark him/her as seen by the recruiter.
   */
  candidate.useGetCandidate(applicant.id)

  const { isLoading: isLoadingCv, data: cvMedia } = media.useGetUploadMedia(
    applicant.cvFile as string,
    {
      enabled: applicant.cvFile,
    }
  )

  return (
    <Applicant
      isLoadingCv={isLoadingCv}
      cvMedia={cvMedia}
      context={context}
      applicant={applicant}
      onClose={onClose}
    />
  )
}
export default ApplicantContainer
