import { Button } from 'antd'
import InputEmail from 'app/form/InputEmail'
import { useFormikContext } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'
import FormValues from './types'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import cn from 'classnames'

interface OwnProps {
  isSubmitting: boolean
  isGenerating: boolean
  onHide: () => void
}

const ModalMail: React.FC<OwnProps> = ({ isSubmitting, onHide, isGenerating }) => {
  const { formatMessage } = useIntl()

  const { values, setValues, errors, handleSubmit } = useFormikContext<FormValues>()

  return (
    <div className={styles.form}>
      <>
        <h1>{formatMessage({ id: `recruiter.applicant.mail.modal.title` })}</h1>
        {values.recipients.map((name, index) => (
          <div className={styles.row} key={index}>
            <InputEmail
              value={name}
              name={`recipients.${index}`}
              id={`recipients.${index}`}
              dataCy={`drawer.applicant.profile.mail.recipient.${index}`}
            />
            {values.recipients.length > 1 && (
              <CloseOutlined
                onClick={() =>
                  setValues({
                    ...values,
                    recipients: [
                      ...values.recipients.slice(0, index),
                      ...values.recipients.slice(index + 1),
                    ],
                  })
                }
                className={cn(styles.delete, { [styles.hasError]: errors?.recipients?.[index] })}
              />
            )}
          </div>
        ))}
        <div className={styles.add}>
          <Button
            type="primary"
            shape="round"
            data-cy="drawer.applicant.profile.mail.recipient.add"
            onClick={() => {
              setValues({
                ...values,
                recipients: [...values.recipients, ''],
              })
            }}
          >
            + {formatMessage({ id: 'common.add' })}
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.underline}
            type="link"
            data-cy="modal.education.cancel"
            disabled={isSubmitting || isGenerating}
            onClick={async () => {
              onHide()
            }}
          >
            {formatMessage({ id: 'recruiter.applicant.mail.modal.cancel' })}
          </Button>
          <Button
            icon={<PlusOutlined />}
            type="default"
            shape="round"
            data-cy="modal.education.submit"
            loading={isSubmitting || isGenerating}
            disabled={isSubmitting || isGenerating}
            onClick={async () => {
              handleSubmit()
            }}
          >
            {formatMessage({
              id: `recruiter.applicant.mail.modal.${isGenerating ? 'generating' : 'submit'}`,
            })}
          </Button>
        </div>
      </>
    </div>
  )
}

export default ModalMail
