import React, { FC, useMemo, useCallback } from 'react'
import JobDescription from './component'
import { useApi } from 'app/contexts/ApiContext'
import { useUser } from 'app/contexts/UserContext'

interface OwnProps {
  jobDescriptionId: string
  authorId: string
  messageId: string
}

const JobDescriptionContainer: FC<OwnProps> = ({ jobDescriptionId, authorId, messageId }) => {
  const { jobDescription, thread } = useApi()
  const user = useUser()

  const { isLoading, data } = jobDescription.useGetJobDescription(
    jobDescriptionId.split('/').pop() as string
  )

  const [
    mutatePatchMessage,
    { isLoading: isDeletting, isSuccess: isDeleteSuccess },
  ] = thread.usePatchMessage()

  const removeJobDescription = useCallback(
    () => mutatePatchMessage({ messageId, jobDescription: null }),
    [messageId, mutatePatchMessage]
  )

  const canDelete = useMemo(() => authorId === user.id, [authorId, user])

  if (isDeleteSuccess) {
    return null
  }

  return (
    <JobDescription
      isLoading={isLoading || isDeletting}
      jobDescription={data}
      canDelete={canDelete}
      removeJobDescription={removeJobDescription}
    />
  )
}

export default JobDescriptionContainer
