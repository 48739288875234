import { useLocation } from '@reach/router'
import { useAuth } from 'app/contexts/AuthContext'
import config from 'config'
import React, { FC, useMemo } from 'react'
import AuthenticatedApp from './AuthenticatedApp'
import { UserStatus } from './contexts/AuthContext/types'
import UnauthenticatedApp from './UnauthenticatedApp'
import Loader from 'components/Loader'

enum BackgroundColors {
  WHITE = 'var(--white)',
  GRAY = 'var(--gray)',
}

const App: FC = () => {
  const {
    data: { isLoggedIn, user },
    loading,
  } = useAuth()

  const { pathname } = useLocation()

  const colorsRouteMap = new Map<string, BackgroundColors>([
    [config.routes.candidate.messaging, BackgroundColors.GRAY],
    [config.routes.candidate.dashboard, BackgroundColors.GRAY],
    [config.routes.recruiter.messaging, BackgroundColors.GRAY],
    [config.routes.recruiter.cvLibrary, BackgroundColors.GRAY],
    [config.routes.recruiter.profile, BackgroundColors.GRAY],
    [config.routes.recruiter.mission.list, BackgroundColors.GRAY],
    [config.routes.recruiter.mission.details, BackgroundColors.GRAY],
  ])

  const bgColor = useMemo(() => colorsRouteMap.get(pathname) || 'var(--white)', [
    colorsRouteMap,
    pathname,
  ])

  if (loading) {
    return <Loader isFullscreen />
  }

  return (
    <div style={{ backgroundColor: bgColor, minHeight: '100%' }}>
      {isLoggedIn && user?.status !== UserStatus.STATUS_INCOMPLETE ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  )
}

export default App
