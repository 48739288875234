import { MessageOutlined, SearchOutlined, ControlOutlined } from '@ant-design/icons'
import { Button, Input, Select, Drawer, Radio } from 'antd'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useIntl } from 'react-intl'
import { MessageStatus } from '../container'
import styles from './styles.module.scss'
import config from 'config'
import useMediaQuery from 'app/hooks/useMediaQuery'

interface OwnProps {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  selectValue: MessageStatus
  setSelectValue: Dispatch<SetStateAction<MessageStatus>>
  setFindProfile: Dispatch<SetStateAction<boolean>>
  setIsMessageView: Dispatch<SetStateAction<boolean>>
}

const Header: React.FC<OwnProps> = ({
  searchValue,
  setSearchValue,
  selectValue,
  setSelectValue,
  setFindProfile,
  setIsMessageView,
}) => {
  const { formatMessage } = useIntl()

  const isMobile = useMediaQuery(config.breakpoints.md)
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <h2>{formatMessage({ id: 'global.messaging.header.title' })}</h2>
          <Button
            icon={<MessageOutlined />}
            className={styles.new}
            shape="round"
            size="large"
            data-cy="messaging.header.create"
            onClick={() => {
              setFindProfile(true)
              setIsMessageView(true)
            }}
          >
            {formatMessage({ id: 'global.messaging.header.newMessage' })}
          </Button>
        </div>
        <div className={styles.searchWrapper}>
          <Input
            className={styles.searchInput}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size="large"
            placeholder={formatMessage({ id: 'global.messaging.header.search' })}
            suffix={
              isMobile ? <ControlOutlined onClick={() => setVisible(true)} /> : <SearchOutlined />
            }
          />
          <Select
            className={styles.select}
            defaultActiveFirstOption={true}
            value={selectValue}
            size="large"
            showArrow
            onChange={(v) => setSelectValue(v)}
          >
            {Object.entries(MessageStatus).map(([k, v]) => (
              <Select.Option key={k} value={v}>
                {formatMessage({ id: `global.messaging.header.filters.${v}` })}
              </Select.Option>
            ))}
          </Select>
          <Button className={styles.submit} size="large" type="default" shape="round">
            {formatMessage({ id: 'global.messaging.header.submit' })}
          </Button>
        </div>
      </div>
      <Drawer
        className={styles.drawer}
        title={formatMessage({ id: 'global.messaging.drawer.title' })}
        placement="bottom"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Radio.Group
          onChange={(v) => {
            setSelectValue(v.target.value)
            setVisible(false)
          }}
          value={selectValue}
        >
          {Object.entries(MessageStatus).map(([k, v]) => (
            <Radio key={k} value={v}>
              {formatMessage({ id: `global.messaging.header.filters.${v}` })}
            </Radio>
          ))}
        </Radio.Group>
        <p onClick={() => setVisible(false)}>
          {formatMessage({ id: 'global.messaging.drawer.cancel' })}
        </p>
      </Drawer>
    </>
  )
}

export default Header
