export default {
  en: {
    consultant: {
      availability: 'Open to work',
      modal: {
        title: 'By deactivating this feature, your profile will no longer be visible to recruiters',
        text:
          '\n        You can, if you wish, discuss it with a consultant or confirm that you are no longer open to work',
        button: 'Chat with a consultant',
        link: 'I confim I am no longer open to work',
      },
      offers: 'Job offers',
      referral: 'Referral',
      dashboard: {
        navigation: 'Navigation en anglais',
      },
    },
    candidate: {
      navbarUser: {
        logout: 'Logout',
        profile: 'Profile',
      },
      dashboard: {
        cv: {
          file: {
            loading: 'Loading...',
          },
          title: 'My resume',
          add: 'Add a pdf',
        },
        modal: {
          delete: {
            title: {
              experience: 'Do you want to delete this experience?',
              education: 'Do you want to delete this training?',
            },
            description:
              'This operation will be final, would you like to confirm the deletion of this experience from your profile?',
            submit: 'Confirm deletion',
            cancel: 'Cancel',
          },
          education: {
            title: {
              create: 'Add a formation',
              update: 'Modify a formation',
            },
            form: {
              title: 'Educational backgrounds',
              schoolName: 'University/School',
              degree: 'Degree',
              specialisation: 'Specialisation',
              graduationDate: 'Graduation date',
              submit: {
                create: 'Create',
                update: 'Update',
              },
              cancel: 'Cancel',
            },
          },
        },
        profile: {
          modal: {
            experience: {
              title: {
                create: 'Add professional experience',
                update: 'Modify work experience',
              },
              form: {
                title: 'Work experience',
                jobTitle: 'Fonction',
                company: 'Company',
                role: 'Description',
                location: 'Location',
                startDate: 'Employment start',
                endDate: 'Employment end',
                current: 'This is my current job',
                submit: {
                  create: 'Add experience',
                  update: 'Update',
                },
                cancel: 'Cancel',
              },
            },
          },
          showMore: 'See full profile',
        },
        body: {
          resume: 'Resume',
          showMore: 'Show more',
          experience: 'Experience',
          education: 'Formation',
        },
        search: {
          title: 'My research',
          update: 'Update',
        },
      },
      profile: {
        submit: 'Save',
        form: {
          title: 'Personnal informations',
          firstname: {
            title: 'First name',
            placeholder: 'John',
          },
          lastName: {
            title: 'Name',
            placeholder: 'Doe',
          },
          function: {
            title: 'Role',
            placeholder: 'Developer',
          },
          dateOfBirth: {
            title: 'Date of birth',
          },
          email: {
            title: 'Email',
            placeholder: 'johndoe@enterprise.com',
          },
          phone: {
            title: 'Phone',
            placeholder: '060606060606',
          },
          location: {
            title: 'Location',
            placeholder: 'London',
          },
          checkbox: "C'est aussi mon lieu de recherche",
          description: {
            title: 'Description',
          },
          password: {
            title: 'Password',
            placeholder: '***********',
          },
          passwordConfirmation: {
            title: 'Fill in your password again',
            placeholder: '***********',
          },
        },
      },
      search: {
        breadcrumb: 'My search',
        success: {
          title: 'Informations have been saved',
          description: 'Your search informations have been updated',
        },
        upload: {
          success: 'File uploaded',
          error: 'Error while uploading file',
          deleteSuccess: 'File deleted',
          deleteError: 'Error while deleting file',
        },
        form: {
          title: 'Edit my search',
          submit: 'Save changes',
          cancel: 'Cancel',
          mobilities: {
            title: 'Location',
            placeholder: 'Please enter a location',
          },
          contractType: {
            title: 'Contract',
            placeholder: 'Choose at least one contract type',
          },
          currentSalary: {
            title: 'Current gross salary in € (package)',
            placeholder: '30 000',
          },
          expectedSalary: {
            title: 'Expected gross salary in € (package)',
            placeholder: '40 000',
          },
          companyType: {
            title: 'Company type',
            placeholder: 'Chose at least one company type',
          },
          file: {
            title: 'Join a resume',
            optionnal: 'optionnal',
          },
          terms: {
            title: 'I agree with the terms',
          },
        },
      },
    },
    recruiter: {
      applicant: {
        mail: {
          modal: {
            title: 'Recipients',
            submit: 'Send',
            generating: 'Generation of the CV',
            cancel: 'Cancel',
          },
          tooltip: 'Send resume by email',
        },
        incomeLabel: 'Income',
        profile: 'Profile',
        comments: {
          title: 'Your comments',
          label: 'Comment',
          placeholder: 'Write a comment',
          submit: 'Save',
          action: 'Comment',
          sendMessage: 'Send a message',
        },
        download: {
          label: 'See the cv',
          loading: 'Loading ...',
          downloaded: 'The file has been downloaded',
          finished: 'Finished',
          tooltip: 'Download the resume as PDF',
        },
        summary: 'Summary',
        languages: {
          label: 'Languages',
        },
        hobbies: {
          label: 'Hobbies',
        },
        xp: {
          label: 'Experiences',
          title: '{position} chez {company}',
        },
        education: {
          label: 'Education',
          title: '{diploma} chez {school}',
        },
        score: 'Isidore score',
        addToOpportunity: 'Ajouter à une opportunité',
      },
      navbar: {
        myOpportunities: {
          name: 'My opportunities',
        },
        myMissions: 'My missions',
        candidateSearch: {
          name: 'Search for candidates',
          suggestedProfiles: 'Suggested profiles',
          myOpportunities: 'My opportunities',
          jobDescriptions: 'Job descriptions',
          cvLibrary: 'Cv Library',
        },
        user: {
          logout: 'Logout',
          profile: 'My profile',
        },
        impersonate: {
          connectedAs: 'You are logged in as',
          logout: 'Logout and back to admin',
        },
      },
      modal: {
        recruitCandidate: {
          title: 'Congratulations on this recruitment!',
          subTitle: 'In order to validate this recruitment, please fill in this information.',
          form: {
            startDate: {
              title: 'Start date',
              placeholder: 'Select a date',
            },
            rawSalary: {
              title: 'Salary (fixed + variable)',
              placeholder: 'Enter gross annual salary',
            },
            contract: {
              title: 'Contract or promise of employment (optional)',
              placeholder: 'Attach the contract',
            },
            submit: 'Validate recruitment',
            cancel: 'Cancel',
          },
        },
      },
      dashboard: {
        mission: {
          empty: {
            title: 'Boost your recruitments!',
            subTitle: 'Let Isidore detect the rare pearl for you!',
            button: 'I entrust a mission',
          },
          list: {
            card: {
              empty: 'No candidates.',
              button: 'See the mission',
            },
            subTitle:
              '{ count, plural,\n              =0 {No profile proposed by your consultant Isidore}\n              =1 {A profile proposed by your Isidore consultant}\n              other {profiles proposed by your Isidore consultant}\n            }',
          },
        },
        profile: {
          title: 'Personal information',
          update: 'Edit',
          firstname: 'First name',
          lastname: 'Name',
          function: 'Function',
          phone: 'Phone',
          city: 'City',
          email: 'Mail',
          company: 'Company',
          adress: 'Billing address',
        },
        hello: 'Hello',
      },
      jobDescription: {
        create: {
          breadcrumb: 'Create a job description',
          title: 'Creation of a job description',
          editing: {
            breadcrumb: 'Modify a job description',
            title: 'Modification of the job description',
          },
          form: {
            name: {
              title: 'Name of the job',
              placeholder: 'Your job position',
            },
            contractType: {
              title: 'Type of contract',
              placeholder: 'Select a type of contract',
            },
            startDate: {
              title: 'Start Date',
              placeholder: 'Select a date',
            },
            asapStart: {
              title: 'As soon as possible',
            },
            location: {
              title: 'Location',
              placeholder: 'Enter a city or postal code',
            },
            remuneration: {
              minimumSalary: {
                title: 'Minimum remuneration (gross annual salary)',
                placeholder: '38 000',
              },
              maximumSalary: {
                title: 'Maximum remuneration (gross annual salary)',
                placeholder: '45 000',
              },
            },
            minimumWorkExperienceDuration: {
              title: 'Minimum experience',
              placeholder: 'Choose a level of experience',
              year: 'years',
              firstValue: 'less than one year',
              lastValue: '20 years old and over',
            },
            minimumEducationLevel: {
              title: 'Minimum level of education',
              placeholder: 'Choose a level of education',
            },
            companyDescription: {
              title: 'Presentation of the company',
              placeholder: 'Present your company (history, activity, premises, philosophy...)',
            },
            description: {
              title: 'Presentation of the mission',
              placeholder: 'Present the mission (roles, missions, team...)',
            },
            soughtProfile: {
              title: 'Profile sought',
              placeholder:
                'Present the profile you are looking for (level of education, experience, soft skills...)',
            },
            submit: 'validate the job description',
          },
        },
        details: {
          centerColumn: {
            companyPresentation: 'Presentation of the company',
            missionPresentation: 'Presentation of the mission',
            requiredProfile: 'Required profile',
            empty: 'Aucune description',
          },
          leftColumn: {
            location: 'Location',
            contract: 'Contract',
            start: 'Date of taking up the job',
            salary: 'Salary',
            experience: 'Experience',
            asap: 'Dès que possible',
          },
          createdAt: 'Created at',
          topPage: {
            edit: 'Modifier',
            archive: 'Archiver',
            active: 'Active',
          },
        },
        list: {
          breadcrumb: 'Job descriptions',
          header: {
            jobDescriptionCount: 'active job descriptions',
            create: 'Create job description',
            filter: {
              published: 'Active job descriptions',
              archived: 'Archived job descriptions',
            },
          },
          card: {
            edit: 'Edit',
            link: 'Joining an opportunity',
            view: 'See details',
            empty: 'No opportunities are available',
            createdAt: 'Create the',
            unarchive: 'Unarchive',
          },
        },
      },
      cvLibrary: {
        changeView: 'Change the view',
        breadcrumb: 'Cv Library',
        filters: {
          reset: 'Reset filters',
          search: 'The rare pearl ?',
          onlyFunction: 'Search in function only',
          location: 'Locality',
          onlyDwellingPlace: 'Only the place of residence',
          experience: 'Experience',
          experienceFormat:
            "{experience, plural,\n                =0 {'<1 year'}\n                one {# year}\n                other {# years}\n              }",
          salary: 'Salary',
          salaryFormat: '{salary}k€',
          contract: 'Contract',
          educationLevel: 'Level of education',
          language: 'Languages',
          onlyProfileWeek: 'Profile of the week only',
          onlyProfileViewed: 'Profile not viewed only',
          save: 'Save the search',
          searchButton: 'Search',
        },
        resumeTable: {
          title:
            '{ count, plural,\n                =0 {No applicant}\n                one {One applicant}\n                other {{count} applicants}\n              } listening for opportunities',
          table: {
            columns: {
              name: 'Name',
              function: 'Function',
              enterprise: 'Company',
              salary: 'Salary',
              location: 'Place of residence',
              score: 'Isidore score',
              profileOfTheWeek: 'Profile of the week',
              experience: 'Experience',
              experienceFormat:
                "{experience, plural,\n                    =0 {'<' 1 year}\n                    one {1 year}\n                    other {{experience} years}\n                  }",
            },
          },
        },
      },
      mission: {
        remainingDays: {
          legend: 'Remaining days',
          statusPending: 'Pending validation',
          timeOver: 'Time over',
        },
        create: {
          breadcrumb: 'Create mission',
          tabs: {
            mission: {
              title: 'Mission',
              subtitle: 'Please fill in the information on the position related to this mission',
              form: {
                name: {
                  title: 'Title of the mission',
                  placeholder: 'Enter the title of the assignment',
                },
                profileCount: {
                  title: 'Number of profiles sought for this position',
                  placeholder: 'Enter a name',
                },
                jobDescription: {
                  title: 'Job description ( required)',
                  placeholder: 'Attach job description',
                  create: 'Create a job description',
                },
                submit: 'Validate the mission',
              },
            },
            offerValidation: {
              title: 'Validation of the offer',
              subtitle: 'Please confirm the Isidore offer before proceeding.',
              info: {
                title: '1% per month for 18 months',
                firstText:
                  "Entrust us with your recruitment by creating your mission, it's all about success and we are looking for the ideal candidate specific to your needs.",
                content:
                  "Billing only by <span> success </span> and <span> very attractive </span>.\n                        representing <span> 1% of the gross annual package </span> of the candidate's remuneration per\n                        months for 18 months. If the candidate does not stay in your company, the invoicing\n                        stops.",
                conditions: 'Accept the general terms and conditions of sale',
                cgv: 'general terms and conditions of sale',
                submit: 'Confirm the offer',
              },
              aside: {
                a: 'Advice and support by a consultant',
                b: 'Sourcing specific to your needs',
                c: 'Preview of new candidates matching your criteria on Isidore.io',
                d:
                  'Pre-qualification of candidates by profile, video interview and presentation of your company',
                e: 'Follow-up of the candidates presented throughout your recruitment process',
                f:
                  'Loyalty of the employee during his integration and throughout the duration of the contract',
              },
            },
            confirm: {
              title: 'Confirmation',
              description:
                'Thank you for your request, an Isidore consultant will contact you shortly.',
              button: 'See the list of missions',
            },
          },
          error: {
            title: 'Creation of the mission',
            description:
              'An error has occurred. Please check the information entered and contact an administrator if the error persists.',
          },
        },
        details: {
          header: {
            createdAt: 'Créer le',
            actions: {
              renew: 'Renew',
              stop: 'Stop',
              resume: 'Resume',
            },
            declinedCandidate: 'See declined candidates',
            jobDescription: 'See job description',
          },
          item: {
            PROPOSED: {
              prefixDate: 'Reached the',
            },
            INTERESTING: {
              prefixDate: 'Reached the',
            },
            MEETING: {
              prefixDate: 'Meeting the',
            },
            RECRUITED: {
              prefixDate: 'Recruited the',
            },
            DECLINED: {
              prefixDate: 'Declined the',
            },
          },
          columns: {
            reached: 'Offered',
            interesting: 'Interesting',
            planned: 'Interview planned',
            recruited: 'Recruited',
          },
          modal: {
            title: 'Decline the candidate',
            description:
              'To decline the candidate and allow us to refine our selection, please select a reason',
            label: 'Decline reason',
            cancel: 'Cancel',
            validate: 'Validate the decline',
          },
          recover: {
            restore: 'Restore',
            cancel: 'Cancel',
          },
        },
        list: {
          empty: {
            title: 'free up your time and switch to mission mode!',
            teaser: '1% per month for 18 months',
            first:
              "Entrust us with your recruitment by creating your mission, it's all about success and we are looking for the ideal candidate specific to your needs.",
            second:
              "Billing only by <strong>success</strong> and <strong>very attractive</strong> representing <strong>1% of the annual gross</strong> package of the candidate's remuneration per month for 18 months. If the candidate does not stay in your company, the invoicing stops.",
            aside: {
              a: 'Advice and support by a consultant',
              b: 'Sourcing specific to your needs',
              c:
                'Preview presentation of new candidates on Isidore.io corresponding to your criteria.',
              d:
                'Pre-qualification of candidates by profile, video interview and presentation of your company',
              e: 'Follow-up of the candidates presented throughout your recruitment process',
              f: 'Loyalty of the employee during his integration and throughout the contract',
            },
            create: 'Create your first mission',
          },
          breadcrumb: 'My missions',
          header: {
            missionCount:
              '{ count, plural,\n                =0 {No mission}\n                =1 {1 mission}\n                other {{count} missions}\n              }',
            create: 'Create a mission',
            filter: {
              inProgress: 'In progress mission',
            },
          },
          card: {
            createdAt: 'Created the',
            candidate: {
              reached: 'Reached candidates',
              interesting: 'Interesting candidates',
              recruited: 'Recruited candidates',
            },
          },
        },
      },
      opportunity: {
        create: {
          breadcrumb: 'Creating an opportunity',
          title: 'Creating an opportunity',
          form: {
            name: {
              title: 'Name of the opportunity',
              placeholder: 'Votre opportunité',
            },
            presentation: {
              title: 'Presentation message for the candidate',
              placeholder: 'Your message type',
            },
            video: {
              title: 'Lien vidéo',
              placeholder: 'Video link',
            },
            job: {
              add: 'Choose a job description',
              create: 'Create a job description',
            },
            submit: 'Validate the opportinity',
          },
        },
        details: {
          edit: 'Edit the opportunity',
          archive: 'Archive the opportunity',
          createdAt: 'Created at',
          table: {
            acceptedCandidates: 'Candidates who accepted',
            reachedCandidates: 'Reached candidates',
            newCandidates: 'Candidates not reached',
            declinedCandidates: 'Candidates who declined',
            columns: {
              name: 'NAME',
              role: 'ROLE',
              company: 'COMPANY',
              salary: 'SALARY',
              location: 'LOCATION',
              experience: 'EXPERIENCE',
              rating: 'RATING',
              acceptedDate: 'ACCEPTED',
              offeredDate: 'OFFERED',
              declinedDate: 'DECLINED',
              status: 'STATUS',
              sendMessage: 'Send a message',
              sendOpportunity: 'Send an opportunity',
            },
          },
        },
        list: {
          breadcrumb: 'My opportunities',
          header: {
            opportunityCount: 'published opportunities',
            create: 'Create opportunity',
            filter: {
              published: 'Published opportunities',
              archived: 'Archived Opportunities',
            },
          },
          card: {
            candidate: {
              accepted: 'Accepted candidates',
              contacted: 'Candidates contacted',
              notContacted: 'Candidates not contacted',
            },
            archive: 'Archive the opportunity',
            creationDate: 'Created at {date}',
          },
        },
      },
      profile: {
        submit: 'Save',
        info: {
          title: 'Personnal informations',
          firstname: {
            title: 'First name',
            placeholder: 'John',
          },
          lastName: {
            title: 'Name',
            placeholder: 'Doe',
          },
          jobTitle: {
            title: 'Role',
            placeholder: 'Developer',
          },
          email: {
            title: 'Email',
            placeholder: 'johndoe@enterprise.com',
          },
          phone: {
            title: 'Phone',
            placeholder: '060606060606',
          },
          picture: {
            label: 'Company logo',
          },
        },
        company: {
          title: 'Company',
          companyName: {
            title: 'Company name',
            placeholder: 'Google',
          },
          jobLocation: {
            title: 'Work place',
            placeholder: 'Work place',
          },
          companySize: {
            title: 'Company size',
            placeholder: '32',
          },
          companyType: {
            title: 'Company type',
            placeholder: 'Agency',
          },
        },
        password: {
          title: 'Password',
          password: {
            title: 'Password',
            placeholder: '***********',
          },
          passwordConfirmation: {
            title: 'Fill in your password again',
            placeholder: '***********',
          },
        },
        bank: {
          title: 'Bank informations',
          accountHolder: {
            title: 'Account holder',
            placeholder: 'Green software Society',
          },
          IBAN: {
            title: 'IBAN code',
            placeholder: 'FR76 3321 8976 9087 2194 3453 9865',
          },
          BIC: {
            title: 'SWIFT/ BIC Code',
            placeholder: '',
          },
          billingAddress: {
            title: 'Billing address',
            placeholder: '32 avenue de la défense',
          },
          postalCode: {
            title: 'Zip code',
            placeholder: '7500',
          },
          city: {
            title: 'City',
            placeholder: 'Paris',
          },
        },
      },
    },
    global: {
      impersonate: {
        connectedAs: 'You are logged in as',
        logout: 'Logout and back to admin',
      },
      components: {
        timeline: {
          showMore: 'Next',
        },
      },
      avatar: {
        recruiter: 'Recruiter',
        candidate: 'Candidate',
        consultant: 'Consultant',
      },
      messaging: {
        header: {
          title: 'Messaging',
          newMessage: 'New message',
          filters: {
            all: 'All',
            active: 'Active',
            archived: 'Archived',
            unread: 'Unread',
          },
          search: 'Search in conversations',
          submit: 'Search',
        },
        drawer: {
          title: 'Filter messages on',
          cancel: 'Close',
        },
        message: {
          body: {
            conversation: {
              file: {
                no: 'No',
                yes: 'Yes',
                delete: 'Are you sure you want to remove this attachment?',
                loading: 'Loading the file...',
              },
              jobDescription: {
                no: 'No',
                yes: 'Yes',
                delete: 'Are you sure you want to remove the post form from the message ?',
                loading: 'Loading the job description...',
                prefix: 'Job description :',
              },
            },
            loadMore: 'Load more messages',
          },
          header: {
            back: {
              title: 'Back to the list of messages',
            },
            find: {
              title: 'New message',
              empty: 'No results matching your search',
              placeholder: 'Type a name, a function, a company...',
            },
            archive: 'Archive the conversation',
          },
          footer: {
            actions: {
              submit: 'Send',
            },
            input: {
              placeholder: 'Write your message...',
            },
          },
        },
        breadcrumb: 'Messagerie',
      },
    },
    anonymous: {
      pendingValidation: {
        logout: 'Log out',
        recruiter: {
          title: 'Your recruiter account as successfuly been created',
          message:
            'An Isididore Consultant will get back to you as soon as possible about your candidate search',
          emailLabel: 'Contact',
          emailValue: 'bonjour@isidore.io',
          phoneLabel: 'Phone',
          phoneValue: '01 85 08 35 38',
          emailUrl: 'mailto:bonjour@isidore.io',
          phoneUrl: 'phone:+33185083538',
        },
        candidate: {
          title: 'Your account request is waiting for validation',
          message: 'Your profile is waiting to be validated by a consultant',
          faqLink: 'FAQ',
        },
      },
      login: {
        title: 'Connexion',
        googleSignIn: 'Connect with your Google account',
        linkedinSignIn: 'Connect with Linkedin',
        or: 'or with an email',
        recruiter: {
          name: 'Recruiters',
          title: 'Espace recruteur',
        },
        candidate: {
          name: 'Candidates',
          title: 'Espace candidat',
        },
        goToSignin: 'Créer un compte',
        errors: {
          emailRequired: 'Email is required',
          invalidEmail: 'Email is invalid',
        },
        loginForm: {
          emailLabel: 'Email adress',
          emailPlaceholder: 'Your email adress',
          passwordLabel: 'Password',
          passwordPlaceholder: 'Your password',
          buttonLabel: 'Sign up',
          recoverPassword: 'Forgot your password ?',
        },
      },
      recoverPassword: {
        title: 'Restore password',
        baseline:
          'In order to reset your Isidore password, please enter the email address\n            used for this account.',
        form: {
          email: {
            title: 'Email address',
            placeholder: 'Your email address',
          },
          submit: 'Submit',
          cancel: 'Cancel',
          error: {
            message: 'Forgot your password',
            description:
              'An error has occurred. Please check the information entered and contact an administrator if the error persists.',
          },
        },
        footer: {
          description: 'You forgot your login email address ?',
          contact: 'Contact us',
        },
        success: {
          title: 'Check your mailbox',
          baseline: 'The information was sent to',
          information: 'Remember to check your spam.',
          button: 'Login',
        },
      },
      resetPassword: {
        title: 'Change Password',
        baseline: 'In order to change your Isidore password, please fill in the following fields.',
        missingToken: 'The access token is missing, please check that the link is valid..',
        form: {
          password: 'Password',
          confirm: 'Password verification',
          submit: 'Submit',
          cancel: 'Cancel',
        },
      },
      signin: {
        candidate: {
          title: 'Candidate zone',
          formTitle: 'Creation of candidate account',
          formSubtitle: 'Here are the informations of your LinkedIn account, you can modify them.',
          name: 'Candidates',
        },
        personnalInformations: 'Personnal informations',
        professionalExperiences: 'Profesionnal Experiences',
        profile: 'Profil',
        search: 'Your search',
        form: {
          error: {
            submit: {
              title: 'An error has occurred',
              message:
                'An error has occurred. Please check the information entered and contact an administrator if the error persists.',
            },
            next: 'The form contains errors, please read them by going up the page',
            upload: 'Sending the file caused an error.',
          },
          firstname: {
            title: 'First name',
            placeholder: 'John',
          },
          lastname: {
            title: 'Last name',
            placeholder: 'Doe',
          },
          dateOfBirth: 'Date of birth',
          linkedinURL: 'Linkedin profile link',
          profilePicture: 'Profile picture',
          cvFile: {
            title: 'Attach a resume',
            remove: 'Remove the resume',
            optional: 'optional',
          },
          email: {
            title: 'Email',
            placeholder: 'john@doe.com',
          },
          checkbox: 'This is also my place of research',
          next: 'Next',
          addPicture: 'Add a picture',
          removePicture: 'Remove the picture',
          acceptedFormats: 'Accepted formats are : jpg, png, max 1Mo',
          workExperiencesYearCount: {
            title: 'What is your number of years of global work experience',
            placeholder: 'Number of years of global work experience',
          },
          workExperiences: {
            title: 'Work experience',
            jobTitle: 'Fonction',
            company: 'Company',
            role: 'Description',
            location: 'Location',
            startDate: 'Employment start',
            endDate: 'Employment end',
            current: 'This is my current job',
          },
          educationalBackgrounds: {
            title: 'Educational backgrounds',
            schoolName: 'University/School',
            degree: 'Degree',
            specialisation: 'Specialisation',
            graduationDate: 'Graduation date',
          },
          educationLevel: {
            title: 'Education level',
            placeholder: 'Education level',
          },
          search: {
            title: 'Can you tell us more about your job search ?',
            mobilities: {
              title: 'Where are you searching? (several choices possible)',
              placeholder: 'Paris',
              city: 'Zone',
              mobilityRange: 'Range',
            },
            contractTypes: 'Contract types',
            salary: {
              currentSalary: 'Current gross salary in € (package)',
              expectedSalary: 'Expected gross salary in € (package)',
            },
            companyType: {
              title: 'In what types of companies do you want to work (several choices possible)?',
              placeholder: 'Type of company',
            },
            CV: {
              title: 'Join a resume',
              legend: '(optionnal)',
            },
            terms: 'I accept the general terms and conditions of use',
          },
          password: 'Password',
          confirm: 'Password verification',
          phoneNumber: {
            title: 'Phone',
            placeholder: '06xxxxxxxxxx',
          },
          location: {
            title: 'Location',
            placeholder: 'London',
          },
          spokenLanguages: {
            title: 'Languages',
            language: 'Language',
            languagePlaceholder: 'English',
            level: 'Level',
            levelPlaceholder: 'Fluent',
          },
          hobbies: {
            title: 'Hobbies',
            domain: 'Domain',
            domainPlaceholder: 'Photograph',
          },
          description: {
            title: 'Resume',
            placeholder: 'Present yourself!',
            error: 'The resume must be at least 20 characters long',
          },
          company: {
            title: 'Company',
            placeholder: 'World wild web academy',
          },
          jobTitle: {
            title: 'Job Title',
            placeholder: 'Front-end developer',
          },
          jobLocation: {
            title: 'Job location',
            placeholder: 'London',
          },
          companyType: {
            title: 'Company Type',
            placeholder: 'Startup',
          },
          companySize: {
            title: 'Company Size',
            placeholder: '100',
            label: {
              '1-10': '1-10',
              '11-20': '11-20',
              '21-20': '21-20',
              '51-100': '51-100',
              '101-250': '101-250',
              '250+': '250+',
            },
          },
          submit: 'Register',
        },
        recruiter: {
          title: 'Recruiter zone',
          formTitle: 'Creation of recruiter account',
          formSubtitle:
            'Please fill the following informations in order to create your Isidore account.',
          personnalInformations: 'Personnal informations',
          function: 'Fonction',
          name: 'Recruiters',
        },
        title: 'Create an account',
        googleSignIn: 'Connect with your Google account',
        linkedinSignIn: 'Connect with Linkedin',
        goToLoginLabel: 'Already have an account ?',
        goToLogin: 'Login',
        or: 'or',
        button: 'Create an account',
      },
    },
    form: {
      email: {
        label: 'Email',
        placeholder: 'johndoe@example.com',
      },
      location: {
        label: 'Location',
        placeholder: 'London',
      },
      firstname: {
        label: 'First name',
        placeholder: 'John',
      },
      lastname: {
        label: 'Last name',
        placeholder: 'Doe',
      },
      function: {
        label: 'Role',
        placeholder: '',
      },
      dateOfBirth: {
        label: 'Birth date',
        placeholder: '01/01/1990',
      },
      phone: {
        label: 'Phone number',
        placeholder: '0123456789',
      },
      description: {
        label: 'Description',
        placeholder: 'Present yourself!',
      },
      company: {
        label: 'Company',
        placeholder: ' ',
      },
      role: {
        label: 'Description',
        placeholder: ' ',
      },
      schoolName: {
        label: 'University/School',
        placeholder: ' ',
      },
      degree: {
        label: 'Degree',
        placeholder: ' ',
      },
      specialisation: {
        label: 'Specialisation',
        placeholder: ' ',
      },
      password: {
        label: 'Password',
        placeholder: '*******',
        error: 'The password must be at least 8 character long',
      },
      confirm: {
        label: 'Password confirmation',
        placeholder: '*******',
        error: 'The password must match',
      },
      mobilities: {
        label: 'Where are you searching? (several choices possible)',
        placeholder: 'London',
        info: '(Your place of residence)',
      },
      companySize: {
        title: 'Company Size',
        placeholder: '100',
        label: {
          '1-10': '1-10',
          '11-20': '11-20',
          '21-20': '21-20',
          '51-100': '51-100',
          '101-250': '101-250',
          '250+': '250+',
        },
      },
      companyType: {
        label: 'Company type',
        placeholder: 'Company type',
        startup: 'Startup',
        sme: 'SME',
        group: 'Major group',
      },
      profilePicture: {
        label: 'Profile picture',
        legend: 'Accepted formats are : jpg, png, max 1Mo',
        add: 'Add a picture',
        remove: 'Remove the picture',
      },
      currentSalary: {
        label: 'Current salary (gross annual euros)',
        placeholder: '30 000',
        min: 'Salary must be greater than',
      },
      expectedSalary: {
        label: 'Desired salary (gross annual euros)',
        placeholder: '40 000',
        min: 'Salary must be greater than',
      },
    },
  },
  fr: {
    consultant: {
      availability: "Je suis à l'écoute",
      modal: {
        title:
          'En désactivant cette fonctionnalité, votre profil ne sera plus visible auprès des recruteurs',
        text:
          'Vous pouvez si vous le souhaitez en discuter avec un consultant ou confirmer que vous\n          n’êtes plus à l’écoute',
        button: 'Discuter avec un consultant',
        link: 'Je confirme que je ne suis plus à l’écoute',
      },
      offers: 'Propositions de postes',
      referral: 'Parainage',
      dashboard: {
        navigation: 'Navigation',
      },
    },
    candidate: {
      navbarUser: {
        logout: 'Déconnexion',
        profile: 'Profil',
      },
      dashboard: {
        cv: {
          file: {
            loading: 'Chargement...',
          },
          title: 'Mon cv',
          add: 'Ajouter un pdf',
        },
        modal: {
          delete: {
            title: {
              experience: 'Voulez-vous supprimer cette expérience ?',
              education: 'Voulez-vous supprimer cette formation ?',
            },
            description:
              'Cette opération sera définitive, souhaitez-vous confirmer la suppression de cette expérience de votre profil ?',
            submit: 'Confirmer la suppression',
            cancel: 'Annuler',
          },
          education: {
            title: {
              create: 'Ajouter une formation',
              update: 'Modifier une formation',
            },
            form: {
              title: 'Formations',
              schoolName: 'Université/École',
              degree: 'Diplôme',
              specialisation: 'Spécialité',
              graduationDate: "Année d'obtention",
              submit: {
                create: 'Ajouter la formation',
                update: 'Modifier',
              },
              cancel: 'Annuler',
            },
          },
        },
        profile: {
          modal: {
            experience: {
              title: {
                create: 'Ajouter une expérience professionnelle',
                update: 'Modifier l’expérience professionnelle',
              },
              form: {
                title: 'Expériences professionnelles',
                jobTitle: 'Fonction',
                company: 'Entreprise',
                role: 'Description',
                location: 'Lieu',
                startDate: 'Mois et année de prise de poste',
                endDate: 'Mois et année de fin de poste',
                current: "J'occupe actuellement ce poste",
                submit: {
                  create: "Ajouter l'experience",
                  update: 'Modifier',
                },
                cancel: 'Annuler',
              },
            },
          },
          showMore: 'Voir profil complet',
        },
        body: {
          resume: 'Résumé',
          showMore: 'Afficher la suite',
          experience: 'Expérience',
          education: 'Formation',
        },
        search: {
          title: 'Ma recherche',
          update: 'Modifier',
        },
      },
      profile: {
        submit: 'Sauvegarder',
        form: {
          title: 'Informations personnelles',
          firstname: {
            title: 'Prénom',
            placeholder: 'John',
          },
          lastName: {
            title: 'Nom',
            placeholder: 'Doe',
          },
          function: {
            title: 'Fonction',
            placeholder: 'Fonction actuelle ou recherchée',
          },
          dateOfBirth: {
            title: 'Date de naissance',
          },
          email: {
            title: 'Email',
            placeholder: 'johndoe@enterprise.com',
          },
          phone: {
            title: 'Téléphone',
            placeholder: '060606060606',
          },
          location: {
            title: 'Lieu de résidence',
            placeholder: 'Paris',
          },
          checkbox: "C'est aussi mon lieu de recherche",
          description: {
            title: 'Résumé',
          },
          password: {
            title: 'Mot de passe',
            placeholder: '***********',
          },
          passwordConfirmation: {
            title: 'Saisissez à nouveau votre mot de passe',
            placeholder: '***********',
          },
        },
      },
      search: {
        breadcrumb: 'Ma recherche',
        upload: {
          success: 'Fichier envoyé',
          error: "Erreur lors de l'envoi du fichier",
          deleteSuccess: 'Fichier supprimé',
          deleteError: 'Erreur lors de la suppression du fichier',
        },
        success: {
          title: 'Les informations ont étés enregistrées',
          description: 'Vos préférences de recherche sont à jour',
        },
        form: {
          title: 'Modifier ma recherche',
          submit: 'Enregistrer les modifications',
          cancel: 'Annuler',
          mobilities: {
            title: 'Localisation',
            placeholder: 'Saisissez une localisation',
          },
          contractType: {
            title: 'Contrat',
            placeholder: 'Choisissez un ou des types de contrats',
          },
          currentSalary: {
            title: 'Salaire actuel (euros annuel brut)',
            placeholder: '30 000',
          },
          expectedSalary: {
            title: 'Salaire souhaité (euros annuel brut)',
            placeholder: '40 000',
          },
          companyType: {
            title: "Type d'entreprise",
            placeholder: "Choisissez un ou des types d'entreprises",
          },
          file: {
            title: 'Joinder un CV',
            optionnal: 'facultatif',
          },
          terms: {
            title: "J'accepte les conditions générales de vente",
          },
        },
      },
    },
    recruiter: {
      applicant: {
        mail: {
          modal: {
            title: 'Destinataires',
            submit: 'Envoyer',
            generating: 'Génération du CV',
            cancel: 'Annuler',
          },
          tooltip: 'Envoyer le cv par email',
        },
        incomeLabel: 'Salaire',
        profile: 'Profil',
        summary: 'Résumé',
        comments: {
          title: 'Vos commentaires',
          label: 'Commentaire',
          placeholder: 'Écrivez un commentaire',
          submit: 'Enregistrer',
          action: 'Commenter',
          sendMessage: 'Envoyer un message',
        },
        download: {
          label: 'Voir le cv',
          loading: 'Chargement ...',
          downloaded: 'Le fichier a bien été téléchargé',
          finished: 'Terminé',
          tooltip: 'Télécharger le cv en PDF',
        },
        languages: {
          label: 'Langues',
        },
        hobbies: {
          label: 'Hobbies',
        },
        xp: {
          label: 'Experiences',
          title: '{position} chez {company}',
        },
        education: {
          label: 'Formation',
          title: '{diploma} chez {school}',
        },
        addToOpportunity: 'Ajouter à une opportunité',
        score: "L'avis isidore",
      },
      navbar: {
        myOpportunities: {
          name: 'Mes opportunités',
        },
        myMissions: 'Mes missions',
        candidateSearch: {
          name: 'Recherche de candidats',
          suggestedProfiles: 'Suggestion de profils',
          myOpportunities: 'Mes opportunités',
          jobDescriptions: 'Fiches de poste',
          cvLibrary: 'Cvthèque',
        },
        user: {
          logout: 'Déconnexion',
          profile: 'Informations personnelles',
        },
        impersonate: {
          connectedAs: 'Vous êtes connecté en tant que',
          logout: 'Déconnexion et retour à l’admin',
        },
      },
      modal: {
        recruitCandidate: {
          title: 'Félicitations pour ce recrutement !',
          subTitle: 'Afin de valider ce recrutement, merci de renseigner ces informations',
          form: {
            startDate: {
              title: 'Date de début',
              placeholder: 'Sélectionner une date',
            },
            rawSalary: {
              title: 'Salaire (fixe + variable)',
              placeholder: 'Saisissez le salaire brut annuel',
            },
            contract: {
              title: "Contrat ou promesse d'embauche (facultatif)",
              placeholder: 'Joindre le contrat',
            },
            submit: 'Valider recrutement',
            cancel: 'Annuler',
          },
        },
      },
      dashboard: {
        mission: {
          empty: {
            title: 'Boostez vos recrutements !',
            subTitle: 'Confiez à Isidore le soin de détecter pour vous la perle rare !',
            button: 'Je confie une mission',
          },
          list: {
            card: {
              empty: 'Aucun candidat.',
              button: 'Voir la mission',
            },
            subTitle:
              '{ count, plural,\n              =0 {Aucun profil proposé par votre conseiller Isidore}\n              =1 {Un profil proposé par votre conseiller Isidore}\n              other {profils proposés par votre conseiller Isidore}\n            }',
          },
        },
        profile: {
          title: 'Infos perso',
          update: 'Modifier',
          firstname: 'Prénom',
          lastname: 'Nom',
          function: 'Fonction',
          phone: 'Téléphone',
          city: 'Ville',
          email: 'Mail',
          company: 'Entreprise',
          adress: 'Adresse de facturation',
        },
        hello: 'Bonjour',
      },
      jobDescription: {
        create: {
          breadcrumb: 'Créer une fiche de poste',
          title: 'Création d’une fiche de poste',
          editing: {
            breadcrumb: 'Modifier une fiche de poste',
            title: 'Modification de la fiche de poste',
          },
          form: {
            name: {
              title: 'Nom du poste',
              placeholder: 'Votre poste',
            },
            contractType: {
              title: 'Type de contrat',
              placeholder: 'Choisissez un type de contrat',
            },
            startDate: {
              title: 'Date de début',
              placeholder: 'Sélectionner une date',
            },
            asapStart: {
              title: 'Dès que possible',
            },
            location: {
              title: 'Localisation',
              placeholder: 'Rentrer une ville ou un code postal',
            },
            remuneration: {
              minimumSalary: {
                title: 'Rémunération minimum (salaire brut annuel)',
                placeholder: '38 000',
              },
              maximumSalary: {
                title: 'Rémunération maximum (salaire brut annuel)',
                placeholder: '45 000',
              },
            },
            minimumWorkExperienceDuration: {
              title: 'Expérience minimum',
              placeholder: 'Choisissez un niveau d’expérience',
              year: 'ans',
              firstValue: "moins d'un an",
              lastValue: '20 ans et plus',
            },
            minimumEducationLevel: {
              title: "Niveau d'étude minimum",
              placeholder: 'Choisissez un niveau d’études',
            },
            companyDescription: {
              title: 'Présentation de l’entreprise',
              placeholder: 'Présentez votre entreprise (histoire, activité, locaux, philosophie…)',
            },
            description: {
              title: 'Présentation de la mission',
              placeholder: 'Présentez la mission (rôles, missions, équipe…)',
            },
            soughtProfile: {
              title: 'Profil recherché',
              placeholder:
                'Présentez le profil recherché (niveau d’étude, expérience, soft skills…)',
            },
            submit: 'valider la fiche de poste',
          },
        },
        details: {
          centerColumn: {
            companyPresentation: 'Présentation de l’entreprise',
            missionPresentation: 'Présentation de la mission',
            requiredProfile: 'Profil recherché',
            empty: 'Aucune description',
          },
          leftColumn: {
            location: 'Localisation',
            contract: 'Contrat',
            start: 'Date de prise de poste',
            salary: 'Salaire',
            experience: 'Expérience',
            asap: 'Dès que possible',
          },
          createdAt: 'Crée le',
          topPage: {
            edit: 'Modifier',
            archive: 'Archiver',
            active: 'Activer',
          },
        },
        list: {
          breadcrumb: 'Fiches de postes',
          header: {
            jobDescriptionCount: 'fiche(s) de poste',
            create: 'Créer ficher de poste',
            filter: {
              published: 'Fiches de poste actives',
              archived: 'Fiches de poste archivées',
            },
          },
          card: {
            edit: 'Modifier',
            link: 'Joindre à une opportunité',
            view: 'Voir le détail',
            empty: "Aucune opportunité n'est disponible",
            createdAt: 'Crée le',
            unarchive: 'Désarchiver',
          },
        },
      },
      cvLibrary: {
        changeView: 'Changer la vue',
        breadcrumb: 'Cvthèque',
        filters: {
          reset: 'Réinitialiser les filtres',
          search: 'La perle rare ?',
          onlyFunction: 'Rechercher dans la fonction uniquement',
          location: 'Localité',
          onlyDwellingPlace: "Uniquement le lieu d'habitation",
          experience: 'Expérience',
          experienceFormat:
            "{experience, plural,\n                =0 {'<1 an'}\n                one {# an}\n                other {# ans}\n              }",
          salary: 'Salaire',
          salaryFormat: '{salary}k€',
          contract: 'Contrat',
          educationLevel: "Niveau d'études",
          language: 'Langues',
          onlyProfileWeek: 'Profil de la semaine uniquement',
          onlyProfileViewed: 'Profil non consulté uniquement',
          save: 'Sauvegarder la recherche',
          searchButton: 'Rechercher',
        },
        resumeTable: {
          title:
            "{ count, plural,\n                =0 {Aucun candidat}\n                one {Un candidat}\n                other {{count} candidats}\n              } à l'écoute",
          table: {
            columns: {
              name: 'Nom',
              function: 'Fonction',
              enterprise: 'Entreprise',
              salary: 'Salaire',
              location: 'Lieu résidence',
              score: 'Note isidore',
              profileOfTheWeek: 'Profil de la semaine',
              experience: 'Expérience',
              experienceFormat:
                "{experience, plural,\n                    =0 {'<' 1 an}\n                    one {1 an}\n                    other {{experience} ans}\n                  }",
            },
          },
        },
      },
      mission: {
        remainingDays: {
          legend: 'Jours restants',
          statusPending: 'En validation',
          timeOver: 'Dépassé',
        },
        create: {
          breadcrumb: 'Créer une mission',
          tabs: {
            mission: {
              title: 'Mission',
              subtitle: 'Veuillez renseigner les informations sur le poste lié à cette mission',
              form: {
                name: {
                  title: 'Intitulé de la mission',
                  placeholder: 'Saisissez le titre de la mission',
                },
                profileCount: {
                  title: 'Nombre de profils recherchés pour ce poste',
                  placeholder: 'Entrez un nombre entier',
                },
                jobDescription: {
                  title: 'Fiche de poste (obligatoire)',
                  placeholder: 'Joindre fiche de poste',
                  create: 'Créer une fiche de poste',
                },
                submit: 'Valider la mission',
              },
            },
            offerValidation: {
              title: 'Validation de l’offre',
              subtitle: 'Veuillez confirmer l’offre Isidore avant de poursuivre.',
              info: {
                title: '1% par mois pendant 18 mois',
                firstText:
                  'Confiez-nous votre recrutement en créant votre mission, c’est au succès et nous recherchons le candidat idéal spécifique à votre besoin.',
                content:
                  'Une facturation uniquement au <span> succès </span> et <span> très attractive </span>\n                      représentant <span> 1% du package annuel brut </span> de la rémunération du candidat par\n                      mois pendant 18 mois. Si le candidat ne reste pas dans votre société, la facturation\n                      s’arrête.',
                conditions: 'Accepter les',
                cgv: 'conditions générales de vente',
                submit: 'Confirmer l’offre',
              },
              aside: {
                a: 'Conseil et accompagnement par un consultant',
                b: 'Sourcing spécifique à votre besoin',
                c:
                  'Présentation en avant première des nouveaux candidats sur Isidore.io correspondant à vos critères',
                d:
                  'Pré-qualification des candidats par profil, entretien en visio et présentation de votre entreprise',
                e: 'Suivi des candidats présentés tout au long de votre process de recrutement',
                f:
                  ' Fidélisation du collaborateur durant son intégration et tout au long du contrat',
              },
            },
            confirm: {
              title: 'Confirmation',
              description:
                'Merci de votre demande, un consultant Isidore va prendre contact avec vous prochainement.',
              button: 'Voir la liste des missions',
            },
          },
          error: {
            title: 'Création de la mission',
            description:
              "Une erreur s'est produite. Veuillez vérifier les informations saisies et contacter un administrateur si l'erreur persiste.",
          },
        },
        details: {
          header: {
            createdAt: 'Crée le',
            actions: {
              renew: 'Renouveler',
              stop: 'Arrêter',
              resume: 'Reprendre',
            },
            declinedCandidate: 'Voir les candidats refusés',
            jobDescription: 'Voir la fiche de poste',
          },
          item: {
            PROPOSED: {
              prefixDate: 'Proposé le',
            },
            INTERESTING: {
              prefixDate: 'Proposé le',
            },
            MEETING: {
              prefixDate: 'Entretien le',
            },
            RECRUITED: {
              prefixDate: 'Recruté le',
            },
            DECLINED: {
              prefixDate: 'Refusé le',
            },
          },
          columns: {
            reached: 'Proposé',
            interesting: 'Intéressant',
            planned: 'Entretien planifié',
            recruited: 'Recruté',
          },
          modal: {
            title: 'Refuser le candidat',
            description:
              "Pour refuser le candidat et nous permettre d'affiner notre selection, merci de selectionner un motif",
            label: 'Motif du refus',
            cancel: 'Annuler',
            validate: 'Valider le refus',
          },
          recover: {
            restore: 'Restaurer',
            cancel: 'Annuler',
          },
        },
        list: {
          empty: {
            title: 'libérez votre temps et passez en mode mission !',
            teaser: '1% par mois pendant 18 mois',
            first:
              'Confiez-nous votre recrutement en créant votre mission, c’est au succès et nous recherchons le candidat idéal spécifique à votre besoin.',
            second:
              'Une facturation uniquement au <strong>succès</strong> et <strong>très attractive</strong> représentant <strong>1% du package annuel brut</strong> de la rémunération du candidat par mois pendant 18 mois. Si le candidat ne reste pas dans votre société, la facturation s’arrête.',
            aside: {
              a: 'Conseil et accompagnement par un consultant',
              b: 'Sourcing spécifique à votre besoin',
              c:
                'Présentation en avant première des nouveaux candidats sur Isidore.io correspondant à vos critères',
              d:
                'Pré-qualification des candidats par profil, entretien en visio et présentation de votre entreprise',
              e: 'Suivi des candidats présentés tout au long de votre process de recrutement',
              f: 'Fidélisation du collaborateur durant son intégration et tout au long du contrat',
            },
            create: 'Créez votre première mission',
          },
          breadcrumb: 'Mes missions',
          header: {
            missionCount:
              '{ count, plural,\n              =0 {Aucune mission}\n              =1 {1 mission}\n              other {{count} missions}\n            }',
            create: 'Créer mission',
            filter: {
              inProgress: 'Mission en cours',
            },
          },
          card: {
            createdAt: 'Crée le',
            candidate: {
              reached: 'Candidats proposés',
              interesting: 'Candidats interéssants',
              recruited: 'Candidats recrutés',
            },
          },
        },
      },
      opportunity: {
        create: {
          breadcrumb: "Création d'une opportunité",
          title: "Création d'une opportunité",
          form: {
            name: {
              title: "Nom de l'opportunité",
              placeholder: 'Votre opportunité',
            },
            presentation: {
              title: 'Message de présentation pour le candidat',
              placeholder: 'Votre message type',
            },
            video: {
              title: 'Lien vidéo',
              placeholder: 'url de votre video',
            },
            job: {
              add: 'Choisir une fiche de poste',
              create: 'Créer une fiche de poste',
            },
            submit: "Valider l'opportunité",
          },
        },
        details: {
          edit: "Modifier l'opportunité",
          archive: "Archiver l'opportunité",
          createdAt: 'Crée le',
          table: {
            acceptedCandidates: 'Candidats ayant accepté',
            reachedCandidates: 'Candidats contactés',
            newCandidates: 'Candidats non contactés',
            declinedCandidates: 'Candidates ayant refusé',
            columns: {
              name: 'NOM',
              role: 'FONCTION',
              company: 'ENTREPRISE',
              salary: 'SALAIRE',
              location: 'LIEU',
              experience: 'EXPERIENCE',
              rating: 'NOTE ISIDORE',
              acceptedDate: 'ACCEPTE',
              offeredDate: 'PROPOSE',
              declinedDate: 'REFUSE',
              status: 'STATUT',
              sendMessage: 'Envoyer un message',
              sendOpportunity: 'Envoyer une opportunité',
            },
          },
        },
        list: {
          breadcrumb: 'Mes opportunités',
          header: {
            opportunityCount: 'opportunités publiées',
            create: 'Créer opportunité',
            filter: {
              published: 'Opportunités publiées',
              archived: 'Opportunités archivées',
            },
          },
          card: {
            candidate: {
              accepted: 'Candidats acceptés',
              contacted: 'Candidats contactés',
              notContacted: 'Candidats non contactés',
            },
            archive: 'Archiver l’opportunité',
            creationDate: 'Créée le {date}',
          },
        },
      },
      profile: {
        submit: 'Sauvegarder',
        info: {
          title: 'Informations personnelles',
          firstname: {
            title: 'Prénom',
            placeholder: 'John',
          },
          lastName: {
            title: 'Nom',
            placeholder: 'Doe',
          },
          jobTitle: {
            title: 'Role',
            placeholder: 'Développeur',
          },
          email: {
            title: 'Email',
            placeholder: 'johndoe@enterprise.com',
          },
          phone: {
            title: 'Téléphone',
            placeholder: '060606060606',
          },
          picture: {
            label: "Logo de l'entreprise",
          },
        },
        company: {
          title: 'Entreprise',
          companyName: {
            title: "Nom de l'entreprise",
            placeholder: 'Google',
          },
          jobLocation: {
            title: 'Lieu de travail',
            placeholder: 'Paris',
          },
          companySize: {
            title: "Taille de l'entreprise",
            placeholder: '32',
          },
          companyType: {
            title: "Type d'entreprise",
            placeholder: 'ESN',
          },
        },
        password: {
          title: 'Mot de passe',
          password: {
            title: 'Mot de passe',
            placeholder: '***********',
          },
          passwordConfirmation: {
            title: 'Saisissez à nouveau votre mot de passe',
            placeholder: '***********',
          },
        },
        bank: {
          title: 'Coordonnées bancaires',
          accountHolder: {
            title: 'Titulaire du compte',
            placeholder: 'Green software Society',
          },
          IBAN: {
            title: 'Code IBAN',
            placeholder: 'FR76 1111 1111 1111 1111 1111 1111',
          },
          BIC: {
            title: 'Code SWIFT/ BIC',
            placeholder: 'AGRIFR...',
          },
          billingAddress: {
            title: 'Adresse de facturation',
            placeholder: '32 avenue de la défense',
          },
          postalCode: {
            title: 'Code postal',
            placeholder: '7500',
          },
          city: {
            title: 'Ville',
            placeholder: 'Paris',
          },
        },
      },
    },
    global: {
      impersonate: {
        connectedAs: 'Vous êtes connecté en tant que',
        logout: 'Déconnexion et retour à l’admin',
      },
      components: {
        timeline: {
          showMore: 'Suite',
        },
      },
      avatar: {
        recruiter: 'Recruteur',
        candidate: 'Candidat(e)',
        consultant: 'Consultant(e)',
      },
      messaging: {
        header: {
          title: 'Messagerie',
          newMessage: 'Nouveau message',
          filters: {
            all: 'Tous',
            active: 'Actifs',
            archived: 'Archivés',
            unread: 'Non lus',
          },
          search: 'Rechercher dans les conversations',
          submit: 'Chercher',
        },
        drawer: {
          title: 'Filtrer les messages sur',
          cancel: 'Fermer',
        },
        message: {
          body: {
            conversation: {
              file: {
                no: 'Non',
                yes: 'Oui',
                delete: 'Êtes vous sur de retirer cette pièce jointe ?',
                loading: 'Chargement du fichier...',
              },
              jobDescription: {
                no: 'Non',
                yes: 'Oui',
                delete: 'Êtes vous sur de retirer la fiche de poste du message ?',
                loading: 'Chargement de la fiche de poste...',
                prefix: 'Fiche de poste :',
              },
            },
            loadMore: 'Charger plus de messages',
          },
          header: {
            back: {
              title: 'Retour à la liste des messages',
            },
            find: {
              title: 'Nouveau message',
              empty: 'Aucun résultat correspond à votre recherche',
              placeholder: 'Tapez un nom, une fonction, une entreprise…',
            },
            archive: 'Archiver la conversation',
          },
          footer: {
            actions: {
              submit: 'Envoyer',
            },
            input: {
              placeholder: 'Rédigez votre message…',
            },
          },
        },
        breadcrumb: 'Messagerie',
      },
    },
    anonymous: {
      pendingValidation: {
        logout: 'Me déconnecter',
        recruiter: {
          title: 'Votre compte recruteur a bien été créé',
          message:
            'Un Consultant Isidore va vous recontacter au plus vite pour faire le point sur votre recherche de candidats',
          emailLabel: 'Contact : ',
          emailValue: 'bonjour@isidore.io',
          emailUrl: 'mailto:bonjour@isidore.io',
          phoneLabel: 'Téléphone : ',
          phoneValue: '01 85 08 35 38',
          phoneUrl: 'phone:+33185083538',
        },
        candidate: {
          title: 'Votre demande de compte a été enregistrée',
          message: 'Votre profil est en attente de validation avec un consultant',
          faqLink: 'FAQ',
        },
      },
      login: {
        title: 'Connexion',
        googleSignIn: 'Connexion via votre compte Google',
        linkedinSignIn: 'Connexion via Linkedin',
        or: 'ou avec une adresse email',
        recruiter: {
          name: 'Recruteurs',
          title: 'Espace recruteur',
        },
        candidate: {
          name: 'Candidats',
          title: 'Espace candidat',
        },
        goToSignin: 'Créer un compte',
        errors: {
          emailRequired: 'Veuillez entrer un email',
          invalidEmail: 'Veuillez entrer un email valide',
        },
        loginForm: {
          emailLabel: 'Adresse email',
          emailPlaceholder: 'Votre adresse email',
          passwordLabel: 'Mot de passe',
          passwordPlaceholder: 'Votre mot de passe',
          buttonLabel: 'Me connecter',
          recoverPassword: 'Mot de passe oublié ?',
        },
      },
      recoverPassword: {
        title: 'Restaurer le mot de passe',
        baseline:
          'Afin de réinitialiser votre mot de passe Isidore, veuillez indiquer l’adresse email\n            utilisée pour ce compte.',
        form: {
          email: {
            title: 'Adresse email',
            placeholder: 'Votre adresse email',
          },
          submit: 'Envoyer les instructions',
          cancel: 'Annuler',
          error: {
            message: 'Mot de passe oublié',
            description:
              "Une erreur s'est produite. Veuillez vérifier les informations saisies et contacter un administrateur si l'erreur persiste.",
          },
        },
        footer: {
          description: 'Vous avez oublié votre adresse email de connexion ?',
          contact: 'Contactez-nous',
        },
        success: {
          title: 'Consultez votre boîte mail',
          baseline: 'Les informations ont été envoyées sur',
          information: 'Pensez à vérifier vos spams.',
          button: 'Se connecter',
        },
      },
      resetPassword: {
        title: 'Changer le mot de passe',
        baseline:
          'Afin de changer votre mot de passe Isidore, veuillez renseigner les champs suivants.',
        missingToken: "Le jeton d'accès est manquant, veuillez vérifier que le lien soit valide.",
        form: {
          password: 'Mot de passe',
          confirm: 'Vérification de mot de passe',
          submit: 'Poursuivre',
          cancel: 'Annuler',
        },
      },
      signin: {
        candidate: {
          title: 'Espace candidat',
          formTitle: 'Création de compte candidat',
          formSubtitle:
            'Complétez votre profil afin que nous puissions vous proposer les opportunités qui vous correspondent le mieux.',
          name: 'Candidats',
        },
        personnalInformations: 'Informations personnelles',
        professionalExperiences: 'Expériences professionelles',
        profile: 'Profil',
        search: 'Votre recherche',
        form: {
          error: {
            submit: {
              title: 'Une erreur est survenue',
              message:
                "Une erreur s'est produite. Veuillez vérifier les informations saisies et contacter un administrateur si l'erreur persiste.",
            },
            next:
              'Le formulaire comporte des erreurs, veuillez en prendre connaissance en remontant la page',
            upload: "L'envoi du fichier a provoqué une erreur.",
          },
          firstname: {
            title: 'Prénom',
            placeholder: 'John',
          },
          lastname: {
            title: 'Nom',
            placeholder: 'Doe',
          },
          dateOfBirth: 'Date de naissance',
          linkedinURL: 'Lien profil Linkedin',
          profilePicture: 'Photo de profil',
          cvFile: {
            title: 'Joindre un CV',
            remove: 'Supprimer le CV',
            optional: 'facultatif',
          },
          email: {
            title: 'Email',
            placeholder: 'john@doe.com',
          },
          checkbox: "C'est aussi mon lieu de recherche",
          next: 'Poursuivre',
          addPicture: 'Ajouter une photo',
          removePicture: 'Supprimer la photo',
          acceptedFormats: 'Formats acceptés : jpg, png, max. 1Mo.',
          workExperiencesYearCount: {
            title: "Quelle est votre nombre d'années d'expérience au total ?",
            placeholder: 'Indiquez en chiffre le nombre d’années d’expérience',
          },
          workExperiences: {
            title: 'Expériences professionnelles',
            jobTitle: 'Fonction',
            company: 'Entreprise',
            role: 'Description',
            location: 'Lieu',
            startDate: 'Mois et année de prise de poste',
            endDate: 'Mois et année de fin de poste',
            current: "J'occupe actuellement ce poste",
          },
          educationalBackgrounds: {
            title: 'Formations',
            schoolName: 'Université/École',
            degree: 'Diplôme',
            specialisation: 'Spécialité',
            graduationDate: "Année d'obtention",
          },
          educationLevel: {
            title: "Niveau d'études",
            placeholder: "Niveau d'études",
          },
          search: {
            title: "Pouvez-nous nous en dire plus sur votre recherche d'emploi ?",
            mobilities: {
              title: 'Où recherchez-vous ? (plusieurs choix possibles)',
              placeholder: 'Paris',
              city: 'Zone',
              mobilityRange: 'Rayon',
            },
            contractTypes: 'Contrat recherché',
            salary: {
              currentSalary: 'Salaire actuel brut en € (package)',
              expectedSalary: 'Salaire souhaité brut en € (package)',
            },
            companyType: {
              title:
                "Dans quels types d'entreprises souhaitez-vous travailler (plusieurs choix possibles) ?",
              placeholder: "Type d'entreprise",
            },
            CV: {
              title: 'Joindre un CV',
              legend: '(facultatif)',
            },
            terms: "J'accepte les conditions générales d'utilisation",
          },
          password: 'Mot de passe',
          confirm: 'Vérification de mot de passe',
          phoneNumber: {
            title: 'Téléphone',
            placeholder: '06xxxxxxxxxx',
          },
          location: {
            title: 'Lieu de résidence',
            placeholder: 'Paris',
          },
          spokenLanguages: {
            title: 'Langues',
            language: 'Langue',
            languagePlaceholder: 'Anglais',
            level: 'Niveau',
            levelPlaceholder: 'Courant',
          },
          hobbies: {
            title: 'Centres d’intérêts',
            domain: 'Domaine',
            domainPlaceholder: 'Photographe',
          },
          description: {
            title: 'Résumé',
            placeholder: 'Présentez-vous !',
            error: 'Le résumé doit être au moins de 20 caractères',
          },
          company: {
            title: 'Entreprise',
            placeholder: 'World wild web academy',
          },
          jobTitle: {
            title: 'Fonction',
            placeholder: 'Développeur front-end',
          },
          jobLocation: {
            title: 'Lieu de travail',
            placeholder: 'Paris',
          },
          companyType: {
            title: "Type d'entreprise",
            placeholder: 'Startup',
          },
          companySize: {
            title: "Taille d'entreprise",
            placeholder: '100',
            label: {
              '1-10': '1-10',
              '11-20': '11-20',
              '21-20': '21-20',
              '51-100': '51-100',
              '101-250': '101-250',
              '250+': '+ de 250',
            },
          },
          submit: "S'inscrire",
        },
        recruiter: {
          title: 'Espace recruteur',
          formTitle: 'Création de compte recruteur',
          formSubtitle:
            'Afin de créer votre compte Isidore veuillez renseigner les informations suivantes.',
          personnalInformations: 'Informations personnelles',
          function: 'Fonction',
          name: 'Recruteurs',
        },
        title: 'Création de compte',
        googleSignIn: 'Connexion via votre compte Google',
        linkedinSignIn: 'Connexion via Linkedin',
        or: 'ou',
        goToLoginLabel: 'Vous avez déjà un compte ?',
        goToLogin: 'Connectez-vous !',
        button: 'Créer un compte manuellement',
      },
    },
    form: {
      email: {
        label: 'Email',
        placeholder: 'johndoe@example.com',
      },
      location: {
        label: 'Lieu',
        placeholder: 'Nantes',
      },
      firstname: {
        label: 'Prénom',
        placeholder: 'John',
      },
      lastname: {
        label: 'Nom',
        placeholder: 'Doe',
      },
      function: {
        label: 'Fonction actuelle ou recherchée',
        placeholder: ' ',
      },
      dateOfBirth: {
        label: 'Date de naissance',
        placeholder: '01/01/1990',
      },
      phone: {
        label: 'Numéro de téléphone',
        placeholder: '0123456789',
      },
      description: {
        label: 'Résumé',
        placeholder: 'Présentez-vous',
      },
      company: {
        label: 'Entreprise',
        placeholder: ' ',
      },
      role: {
        label: 'Description',
        placeholder: ' ',
      },
      schoolName: {
        label: 'Université / École',
        placeholder: ' ',
      },
      degree: {
        label: 'Diplôme',
        placeholder: ' ',
      },
      specialisation: {
        label: 'Spécialité',
        placeholder: ' ',
      },
      password: {
        label: 'Mot de passe',
        placeholder: '*******',
        error: 'Le mot de passe doit contenir au moins 8 caractères',
      },
      confirm: {
        label: 'Confirmation du mot de passe',
        placeholder: '*******',
        error: 'Le mot de passe doit correspondre',
      },
      mobilities: {
        label: 'Localisation',
        placeholder: 'Saisissez une localisation',
        info: '(Votre lieu de résidence)',
      },
      companySize: {
        title: "Taille de l'entreprise",
        placeholder: '100',
        label: {
          '1-10': '1-10',
          '11-20': '11-20',
          '21-20': '21-20',
          '51-100': '51-100',
          '101-250': '101-250',
          '250+': '250+',
        },
      },
      companyType: {
        label: "Type d'entreprise",
        placeholder: "Type d'entreprise",
        startup: 'Startup',
        sme: 'PME',
        group: 'Grand groupe',
      },
      profilePicture: {
        label: 'Photo de profil',
        legend: 'Formats acceptés : jpg, png, max. 1Mo.',
        add: 'Ajouter une photo',
        remove: 'Supprimer la photo',
      },
      currentSalary: {
        label: 'Salaire actuel (euros annuel brut)',
        placeholder: '30 000',
        min: 'Salaire doit être supérieur à',
      },
      expectedSalary: {
        label: 'Salaire souhaité (euros annuel brut)',
        placeholder: '40 000',
        min: 'Salaire doit être supérieur à',
      },
    },
  },
}
