import { User, UserType } from 'app/contexts/AuthContext/types'
import { useUser } from 'app/contexts/UserContext'
import { Candidate, Consultant, Recruiter, Thread } from 'app/types'
import _ from 'lodash'
import { useMemo } from 'react'

const getInterlocutorType = (thread: Thread, user: User): UserType => {
  const userType = user.type

  const possibleInterlocutor = _.omit(thread, userType as string) as Partial<Thread>

  return possibleInterlocutor.candidate
    ? 'candidate'
    : possibleInterlocutor.consultant
    ? 'consultant'
    : possibleInterlocutor.recruiter
    ? 'recruiter'
    : null
}

export type UserList = Candidate | Recruiter | Consultant

export default function useInterlocutor(
  thread: Thread
): { interlocutor: UserList; interlocutorType: UserType } {
  const user = useUser()

  const interlocutorType = useMemo<UserType>(() => getInterlocutorType(thread, user), [
    thread,
    user,
  ])

  const interlocutor = useMemo<UserList>(
    () => thread[interlocutorType as keyof Thread] as UserList,
    [thread, interlocutorType]
  )

  return {
    interlocutor,
    interlocutorType,
  }
}
