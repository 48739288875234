import { Context } from 'app/AuthenticatedApp/components/Drawer/Applicant/container'
import { Candidate } from 'app/types'
import {
  educationalBackgrounds,
  FormValues,
  workExperiences,
} from 'app/UnauthenticatedApp/Signin/CandidateForm/types'
import config from 'config'
import { MutationResultPair, queryCache, QueryResult, useMutation, useQuery } from 'react-query'
import { callApi, callAuthenticatedApi } from '../utils'
import { invalidateQueriesMap } from './missions'

type RegisterRequest = FormValues

interface RegisterResponse extends FormValues {
  id: string
}

export const useRegister = (): MutationResultPair<
  RegisterResponse,
  Error,
  RegisterRequest,
  string
> => {
  return useMutation(
    async (payload: RegisterRequest) => {
      const { data } = await callApi<RegisterResponse>(config.api.candidate.register, {
        method: 'POST',
        data: payload,
      })
      return data
    },
    {
      throwOnError: true,
    }
  )
}

interface PatchCandidateAvailabilityRequest extends Partial<RegisterRequest> {
  id: string
  visible?: boolean
}

export const usePatchAvailabilityCandidate = (): MutationResultPair<
  RegisterResponse,
  Error,
  PatchCandidateAvailabilityRequest,
  () => void
> => {
  return useMutation(
    async (payload: PatchCandidateAvailabilityRequest) => {
      const { data } = await callAuthenticatedApi<RegisterResponse>(
        `${config.api.candidate.update}/${payload.id}`,
        {
          method: 'PATCH',
          data: { visible: payload.visible },
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      return data
    },
    {
      throwOnError: true,
      onError: (err, _, rollback: () => void) => rollback(),
      onMutate: (payload) => {
        const oldCandidate = queryCache.getQueryData<Candidate>(['candidate', { id: payload.id }])
        queryCache.setQueryData(['candidate', { id: payload.id }], {
          ...oldCandidate,
          visible: payload.visible,
        })
        return () => oldCandidate
      },
    }
  )
}

interface PatchCandidateRequest extends Partial<RegisterRequest> {
  id: string
}

export const usePatchCandidate = (): MutationResultPair<
  RegisterResponse,
  Error,
  PatchCandidateRequest,
  string
> => {
  return useMutation(
    async (payload: PatchCandidateRequest) => {
      const { data } = await callAuthenticatedApi<RegisterResponse>(
        `${config.api.candidate.update}/${payload.id}`,
        {
          method: 'PATCH',
          data: payload,
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      return data
    },
    {
      throwOnError: true,
      onSettled: async (p) => {
        await queryCache.invalidateQueries(['candidate', { id: p?.id }])
      },
    }
  )
}

export interface WorkExperiencesRequest extends workExperiences {
  candidate: string
}

interface WorkExperiencesResponse {
  id: string
  company: string
  jobTitle: string
  location: string
  startDate: string
  endDate: string
  candidate: string
}

export const useMutationAddWorkExperiences = (): MutationResultPair<
  WorkExperiencesResponse,
  Error,
  WorkExperiencesRequest[],
  string
> => {
  return useMutation(async (payload: WorkExperiencesRequest[]) => {
    const { data } = await callAuthenticatedApi<WorkExperiencesResponse>(
      config.api.candidate.workExperiences,
      {
        method: 'POST',
        data: payload.map(({ candidate, ...rest }) => ({
          ...rest,
          candidate: `/api/candidates/${candidate}`,
        })),
      }
    )
    return data
  })
}

export interface EducationalBackgroundsRequest extends educationalBackgrounds {
  candidate: string
}

interface EducationalBackgroundsResponse {
  schoolName: string
  degree: string
  specialisation: string
  graduationDate: string
  candidate: string
}

export const useMutationAddEducationalBackgrounds = (): MutationResultPair<
  EducationalBackgroundsResponse,
  Error,
  EducationalBackgroundsRequest[],
  string
> => {
  return useMutation(async (payload: EducationalBackgroundsRequest[]) => {
    const { data } = await callAuthenticatedApi<EducationalBackgroundsResponse>(
      config.api.candidate.educationalBackgrounds,
      {
        method: 'POST',
        data: payload,
      }
    )
    return data
  })
}

interface AddReviewRequest {
  content: string
  candidate: string
  context: Context
}

interface AddReviewResponse {
  id: string
  content: string
  recruiter: string
  candidate: string
  createdAt: string
  updatedAt: string
}

export const useMutationAddReview = (): MutationResultPair<
  AddReviewResponse,
  Error,
  AddReviewRequest,
  () => void
> => {
  return useMutation(
    async (payload: AddReviewRequest) => {
      const { data } = await callAuthenticatedApi<AddReviewResponse>(config.api.candidate.review, {
        method: 'POST',
        data: {
          ...payload,
          candidate: `/api/candidates/${payload.candidate}`,
        },
      })
      return data
    },
    {
      throwOnError: true,
      onMutate: (payload) => {
        const candidateId = payload?.candidate?.split('/')?.pop()

        const updateCandidateReview = (
          cacheKey: Array<unknown> | string,
          candidates: Candidate[]
        ): void => {
          queryCache.setQueryData(
            cacheKey,
            candidates.map((v) =>
              candidateId === v.id ? { ...v, recruiterReviews: { content: payload.content } } : v
            )
          )
        }

        switch (payload.context) {
          case 'library':
            const candidates = queryCache.getQueryData(['candidates']) as Candidate[]

            updateCandidateReview('candidates', candidates)

            return () => {
              queryCache.setQueryData('candidates', candidates)
            }
          case 'missions':
            const missionId = document.location.pathname.split('/').pop()

            Array.from(invalidateQueriesMap.values()).forEach((query) => {
              const queryKey = [query, { id: missionId }]
              const candidates = queryCache.getQueryData(queryKey) as Candidate[]
              if (candidates?.find(({ id }) => id === candidateId)) {
                updateCandidateReview(queryKey, candidates)
              }
              return () => {
                queryCache.setQueryData(queryKey, candidates)
              }
            })

            return () => null
          default:
            return () => null
        }
      },
      onError: (err, _, rollback: () => void) => rollback(),
      onSettled: (p) => queryCache.invalidateQueries('candidates'),
    }
  )
}

export const useGetCandidate = (id: string, rest?: object): QueryResult<Candidate> => {
  return useQuery<Candidate, Error>(
    ['candidate', { id }],
    async () => {
      const { data } = await callAuthenticatedApi<Candidate>(`${config.api.candidate.get}/${id}`)
      return data
    },
    { ...rest }
  )
}
