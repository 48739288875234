import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { Candidate } from 'app/types'

const styles = StyleSheet.create({
  wrapper: {
    width: 188,
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#513cff',
    marginTop: 15,
  },
  note: {
    color: '#513cff',

    fontWeight: 400,
    marginTop: 5,
  },
})

interface OwnProps {
  applicant: Candidate
}

const Note: FC<OwnProps> = ({ applicant }): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>Note Isidore</Text>
      <Text style={styles.note}>{Math.round((applicant.score / 2) * 100) / 100 || 0}/5</Text>
    </View>
  )
}

export default Note
