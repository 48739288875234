import { View } from '@react-pdf/renderer'
import React, { FC } from 'react'

const Divider: FC = ({}): React.ReactElement => {
  return (
    <View style={{ width: '100%', display: 'flex', alignItems: 'flex-end', marginVertical: 10 }}>
      <View style={{ width: '95%', height: 1, backgroundColor: '#e8e8e8' }} />
    </View>
  )
}

export default Divider
