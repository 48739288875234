import { Thread } from 'app/types'
import React, { Dispatch, FC, SetStateAction } from 'react'
import Card from './Card'
import CardPlaceholder from './Placeholder'
import styles from './styles.module.scss'
import { useUser } from 'app/contexts/UserContext'

interface OwnProps {
  threads?: Thread[]
  active?: string
  desactive: boolean
  setActive: Dispatch<SetStateAction<string>>
  setFindProfile: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  setIsMessageView: Dispatch<SetStateAction<boolean>>
}

const Sidebar: FC<OwnProps> = ({
  active,
  threads,
  setActive,
  desactive,
  setFindProfile,
  isLoading,
  setIsMessageView,
}) => {
  const user = useUser()
  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar} data-cy="messaging.threads">
        {threads
          ?.filter((thread) => thread.last_message)
          ?.map((thread) => (
            <>
              <Card
                key={thread.id}
                thread={thread}
                isUnread={
                  thread?.last_message?.read === false &&
                  user.id !== thread?.last_message?.author?.id
                }
                isActive={thread.id === active && !desactive}
                onClick={() => {
                  setActive(thread.id)
                  setFindProfile(false)
                  setIsMessageView(true)
                }}
              />
            </>
          ))}

        <div className={styles.placeholderContainer}>
          {new Array(7).fill(true).map((v, i) => (
            <CardPlaceholder key={i} isLoading={isLoading} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
