import React, { FC, SetStateAction, Dispatch } from 'react'
import styles from './styles.module.scss'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

interface OwnProps {
  setIsMessageView: Dispatch<SetStateAction<boolean>>
}

const Header: FC<OwnProps> = ({ setIsMessageView }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <p onClick={() => setIsMessageView(false)}>
        {' '}
        <ArrowLeftOutlined /> {formatMessage({ id: 'global.messaging.message.header.back.title' })}
      </p>
    </div>
  )
}

export default Header
