import cn from 'classnames'
import React, { useMemo } from 'react'
import Avatar from '../../components/Avatar'
import { userTypeColorMap } from '../../components/Avatar/component'
import styles from './styles.module.scss'
import { Thread } from 'app/types'
import useInterlocutor from 'app/AuthenticatedApp/Messaging/hook/useInterlocutor'
import moment from 'moment'

interface OwnProps {
  isActive?: boolean
  isUnread?: boolean
  thread: Thread
  onClick: Function
}

const Card: React.FC<OwnProps> = ({ isActive, isUnread, thread, onClick }) => {
  const { interlocutor, interlocutorType } = useInterlocutor(thread)

  const color = useMemo(() => userTypeColorMap.get(interlocutorType), [interlocutorType])

  return (
    <div
      data-cy={`messaging.thread-${interlocutor.firstname}-${interlocutor.lastname}`}
      className={cn(styles.card, { [styles.active]: isActive }, color && styles[color], 'card')}
      onClick={() => onClick()}
    >
      <Avatar {...{ ...interlocutor, userType: interlocutorType }} isActive={isActive} />

      <div className={cn(styles.date, { [styles.unread]: isUnread })}>
        {thread?.last_message && moment(thread?.last_message?.createdAt).format('DD MMM YYYY')}
      </div>
    </div>
  )
}

export default Card
