import { UserType } from 'app/contexts/AuthContext/types'
import { useUser } from 'app/contexts/UserContext'
import Loader from 'components/Loader'
import React, { FC, Suspense } from 'react'

const RecruiterApp = React.lazy(() => import('./Recruiter'))
const CandidateApp = React.lazy(() => import('./Candidate'))
const ConsultantApp = React.lazy(() => import('./Consultant'))
const NotFound = React.lazy(() => import('./NotFound'))

export const routerElement = new Map<UserType, FC>([
  ['candidate', CandidateApp],
  ['recruiter', RecruiterApp],
  ['consultant', ConsultantApp],
])

const AuthenticatedApp: FC = () => {
  const user = useUser()

  const Router = routerElement.get(user.type)

  if (!Router) {
    return <NotFound />
  }

  return (
    <Suspense fallback={<Loader isFullscreen />}>
      <Router />
    </Suspense>
  )
}

export default AuthenticatedApp
