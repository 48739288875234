import { navigate } from '@reach/router'
import { Select } from 'antd'
import { useApi } from 'app/contexts/ApiContext'
import { UserType } from 'app/contexts/AuthContext/types'
import { useUser } from 'app/contexts/UserContext'
import InputSelect from 'app/form/base/InputSelect'
import config from 'config'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { useIntl } from 'react-intl'
import { queryCache } from 'react-query'
import styles from './styles.module.scss'

interface OwnProps {
  setFindProfile: Dispatch<SetStateAction<boolean>>
}

const Header: FC<OwnProps> = ({ setFindProfile }) => {
  const { recipients } = useApi()
  const user = useUser()
  const [filter, setFilter] = useState<string>('')
  const { formatMessage } = useIntl()

  const { data, refetch } = recipients.useListRecipients(filter)

  return (
    <div className={styles.container} data-cy="messaging.message.header">
      <h2>{formatMessage({ id: 'global.messaging.message.header.find.title' })}</h2>
      <div className={styles.inputWapper}>
        <InputSelect
          name=""
          label=""
          data-cy="message.header.find.input"
          bordered={false}
          allowClear
          placeholder={formatMessage({ id: 'global.messaging.message.header.find.placeholder' })}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={(v) => {
            setFilter(v)
            refetch()
          }}
          onChange={async (str) => {
            const [, , receiverType, receiver] = str.toString().split('/')
            queryCache.removeQueries('threads')
            await navigate(
              `${
                config.routes[user.type as NonNullable<UserType>].messaging
              }?receiver=${receiver}&receiverType=${receiverType.slice(0, -1)}`
            )
            setFindProfile(false)
          }}
        >
          <>
            {data?.length === 0 && (
              <Select.Option disabled={true} key={'empty'} value={''}>
                <strong>
                  {formatMessage({ id: 'global.messaging.message.header.find.empty' })}
                </strong>
              </Select.Option>
            )}
            {data?.map((recipient) => (
              <Select.Option key={recipient.id} value={`${recipient.id}`}>
                <strong>{recipient.firstname}</strong> <small>{recipient.lastname}</small>
                {recipient.company && <small> - {recipient.company}</small>}
              </Select.Option>
            ))}
          </>
        </InputSelect>
      </div>
    </div>
  )
}

export default Header
