/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC } from 'react'
import { Document as PdfDocument, Page, StyleSheet, View, Image } from '@react-pdf/renderer'

import { Candidate } from 'app/types'

import Personnal from './Personnal'
import Languages from './Languages'
import Hobbies from './Hobbies'
import Bloc from './Bloc'
import Description from './Description'
import { BlocItem } from './Bloc/component'
import Note from './Note'
import Advice from './Advice'
import Quality from './Quality'
import Divider from './Divider'

import Logo from './logo.png'

const styles = StyleSheet.create({
  page: { backgroundColor: 'white' },
  wrapper: { display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 90 },
  sectionLeft: {
    color: 'white',
    textAlign: 'center',
    marginTop: 30,
    marginLeft: 30,
    height: '100%',
  },
  sectionRight: {
    height: '100%',
    flex: 1,
    marginTop: 15,
    paddingLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
})

interface OwnProps {
  applicant: Candidate
  languages: Array<[string, string]>
  educationalBackgrounds: Array<BlocItem>
  workExperiences: Array<BlocItem>
  urlAvatar: string
  qualityBoxSizes: Array<number>
}

const Document: FC<OwnProps> = ({
  applicant,
  languages,
  educationalBackgrounds,
  workExperiences,
  urlAvatar,
  qualityBoxSizes,
}): React.ReactElement => (
  <PdfDocument>
    <Page size="A4" style={styles.page}>
      <View style={styles.wrapper}>
        <View style={styles.sectionLeft} fixed>
          <Personnal urlAvatar={urlAvatar} applicant={applicant} />
          <View style={{ marginTop: 20, borderRightWidth: 1, borderRightColor: '#e8e8e8' }}>
            <Note applicant={applicant} />
          </View>
        </View>
        <View style={styles.sectionRight}>
          <View style={{ width: '100%' }}>
            <Advice applicant={applicant} />
          </View>
          <Divider />
          <View style={{ width: '100%' }}>
            <Quality applicant={applicant} />
          </View>
        </View>
      </View>
      <Image
        style={{ position: 'absolute', bottom: 20, left: 20, width: 155, height: 30 }}
        source={Logo}
      />
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.wrapper}>
        <View style={styles.sectionLeft} fixed>
          <Personnal urlAvatar={urlAvatar} applicant={applicant} />
          <View style={{ marginTop: 20, borderRightWidth: 1, borderRightColor: '#e8e8e8' }}>
            <Languages languages={languages} />
            <Hobbies applicant={applicant} />
          </View>
        </View>
        <View style={styles.sectionRight}>
          <View style={{ width: '100%' }}>
            <Description applicant={applicant} />
          </View>
          <Bloc title="Expériences" color="#ff4e7b" items={workExperiences} />
          <Bloc title="Formations" color="#513cff" items={educationalBackgrounds} />
        </View>
      </View>
      <Image
        style={{ position: 'absolute', bottom: 20, left: 20, width: 155, height: 30 }}
        source={Logo}
      />
    </Page>
  </PdfDocument>
)

export default Document
