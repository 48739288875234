import { get } from 'lodash'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const config = {
  routes: {
    home: '/',
    signin: 'register',
    candidate: {
      signin: '/candidate-register',
      login: '/login',
      profile: '/candidate/profile',
      linkedinAuth: '/candidate-register/linkedin-auth/:token/:refreshToken',
      dashboard: '/candidate',
      search: '/candidate/search',
      recoverPassword: '/candidate/recovery',
      recoverPasswordSuccess: '/candidate/recovery/success',
      resetPassword: '/candidate/reset',
      messaging: '/candidate/messaging',
    },
    recruiter: {
      signin: '/company-register',
      login: '/login?recruiter',
      googleAuth: '/company-register/google-auth/:token/:refreshToken',
      profile: '/company/profile',
      jobDescription: {
        create: '/company/job-description/create',
        details: '/company/job-description/:id',
        update: '/company/job-description/modify/:id',
        list: '/company/job-description',
      },
      mission: {
        create: '/company/missions/create',
        list: '/company/missions',
        details: '/company/missions/:id',
      },
      cvLibrary: '/company/cv-library',
      dashboard: '/company',
      messaging: '/company/messaging',
      opportunity: {
        list: '/company/job-opportunities',
        create: '/company/job-opportunities/create',
        details: '/company/job-opportunities/:id',
      },
      recoverPassword: '/company/recovery',
      recoverPasswordSuccess: '/company/recovery/success',
      resetPassword: '/company/reset',
    },
    consultant: {
      messaging: '/consultant/messaging',
    },
    pendingValidation: '/pending-validation',
    faq: '/faq',
    cgv: 'https://isidore.io/terms-of-sale',
  },
  api: {
    googleAuth: `${API_BASE_URL}/connect/google`,
    linkedinAuth: `${API_BASE_URL}/connect/linkedin`,
    login: `${API_BASE_URL}/authentication_token`,
    renewToken: `${API_BASE_URL}/renew_token`,
    candidate: {
      get: `${API_BASE_URL}/api/candidates`,
      list: `${API_BASE_URL}/api/candidates`,
      update: `${API_BASE_URL}/api/candidates`,
      register: `${API_BASE_URL}/api/candidates`,
      workExperiences: `${API_BASE_URL}/api/work_experiences`,
      educationalBackgrounds: `${API_BASE_URL}/api/educational_backgrounds`,
      review: `${API_BASE_URL}/api/recruiter_reviews/publish`,
    },
    jobDescription: {
      get: `${API_BASE_URL}/api/job_descriptions`,
      list: `${API_BASE_URL}/api/job_descriptions`,
      create: `${API_BASE_URL}/api/job_descriptions`,
    },
    password: {
      recover: `${API_BASE_URL}/api/forgot_password`,
      reset: `${API_BASE_URL}/api/reset_password`,
    },
    recruiter: {
      register: `${API_BASE_URL}/api/recruiters`,
      get: `${API_BASE_URL}/api/recruiters`,
      sendProfile: `${API_BASE_URL}/api/send_profile/:id`,
    },
    bank: {
      get: `${API_BASE_URL}/api/bank_details`,
      create: `${API_BASE_URL}/api/bank_details`,
      update: `${API_BASE_URL}/api/bank_details`,
    },
    opportunities: {
      get: `${API_BASE_URL}/api/opportunities`,
      update: `${API_BASE_URL}/api/opportunities`,
      create: `${API_BASE_URL}/api/opportunities`,
      accceptedCandidatesSuffix: 'candidates/accepted',
      declinedCandidatesSuffix: 'candidates/declined',
      newCandidatesSuffix: 'candidates/new',
      reachedCandidatesSuffix: 'candidates/reached',
    },
    missions: {
      get: `${API_BASE_URL}/api/missions`,
      update: `${API_BASE_URL}/api/missions`,
      create: `${API_BASE_URL}/api/missions`,
      interestingCandidatesSuffix: 'candidates/interesting',
      meetingCandidatesSuffix: 'candidates/meeting',
      proposedCandidatesSuffix: 'candidates/proposed',
      recruitedCandidatesSuffix: 'candidates/recruited',
      declinedCandidatesSuffix: 'candidates/declined',
      updateCandidateStatusToMeeting: `${API_BASE_URL}/api/missions/meeting_candidate`,
      updateCandidateStatusToRecruited: `${API_BASE_URL}/api/missions/recruited_candidate`,
      updateCandidateStatusToProposed: `${API_BASE_URL}/api/missions/proposed_candidate`,
      updateCandidateStatusToInteresting: `${API_BASE_URL}/api/missions/interesting_candidate`,
      updateCandidateStatusToDeclined: `${API_BASE_URL}/api/missions/decline_candidate`,
    },
    message: {
      create: `${API_BASE_URL}/api/messages`,
    },
    thread: {
      list: `${API_BASE_URL}/api/threads`,
      create: `${API_BASE_URL}/api/threads`,
      archive: `${API_BASE_URL}/api/threads`,
    },
    recipient: {
      get: `${API_BASE_URL}/api/recipients`,
    },
    opportunityCandidates: {
      get: `${API_BASE_URL}`,
      list: `${API_BASE_URL}/api/opportunity_candidates`,
      create: `${API_BASE_URL}/api/opportunity_candidates`,
      reach: `${API_BASE_URL}/api/opportunities/reach_candidate`,
    },
    media: {
      create: `${API_BASE_URL}/api/media_objects`,
      get: `${API_BASE_URL}/api/media_objects`,
      delete: `${API_BASE_URL}/api/media_objects`,
    },
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1600,
  },
}

export default {
  ...config,
  get: (path: string): string => get(config, path, path),
}
