import React, { FC } from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'
import { Candidate } from 'app/types'

export const TAG_HEIGHT = 41

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#513cff',

    marginBottom: 10,
  },
  tag: {
    backgroundColor: '#eff5ff',
    borderWidth: 2,
    borderColor: '#acc5ff',
    paddingHorizontal: 3,
    paddingVertical: 3,
    borderRadius: 5,
    maxWidth: '125px',
  },
  quality: {
    maxWidth: '50%',
    width: '48%',
  },
  qualityTitle: {
    color: '#513cff',

    fontWeight: 'normal',
    fontSize: 10,
  },
  score: {
    fontWeight: 'bold',
    fontSize: 10,
    color: '#513cff',

    marginLeft: 5,
  },
  description: {
    fontSize: 8,
    color: '#666666',
    lineHeight: 1.5,
    textAlign: 'justify',
  },
})

interface OwnProps {
  applicant: Candidate
}

const Note: FC<OwnProps> = ({ applicant }): React.ReactElement => {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>Qualités</Text>
      <View
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        wrap
      >
        {applicant.qualities?.length ? (
          applicant.qualities.map((quality, idx) => (
            <View style={[styles.quality]} key={idx}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: TAG_HEIGHT,
                }}
              >
                <View style={styles.tag}>
                  <Text style={styles.qualityTitle}>{quality.title}</Text>
                </View>
                <Text style={styles.score}>{quality.score}/10</Text>
              </View>
              {quality.description && <Text style={styles.description}>{quality.description}</Text>}
            </View>
          ))
        ) : (
          <Text style={styles.description}>Aucune compétence n'est renseigné pour le moment.</Text>
        )}
      </View>
    </View>
  )
}

export default Note
