import { DeleteOutlined, LoadingOutlined, FileOutlined } from '@ant-design/icons'
import React, { FC, useState } from 'react'
import styles from './styles.module.scss'
import { JobDescription as JobDescriptionType } from 'app/types'
import { useIntl } from 'react-intl'
import { Popconfirm } from 'antd'
import { Modal } from 'antd'
import Details from 'recruiter/JobDescription/Details/component'

interface OwnProps {
  jobDescription?: JobDescriptionType
  isLoading: boolean
  canDelete: boolean
  removeJobDescription: CallableFunction
}

const JobDescription: FC<OwnProps> = ({
  jobDescription,
  isLoading,
  canDelete,
  removeJobDescription,
}) => {
  const { formatMessage } = useIntl()

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <div className={styles.container}>
      <div className={styles.file}>
        {isLoading ? (
          <p>
            {formatMessage({
              id: 'global.messaging.message.body.conversation.jobDescription.loading',
            })}
          </p>
        ) : (
          <p onClick={() => setShowModal(true)}>
            {' '}
            {`${formatMessage({
              id: 'global.messaging.message.body.conversation.jobDescription.prefix',
            })} ${jobDescription?.name}`}
          </p>
        )}
        {isLoading ? (
          <LoadingOutlined className={styles.icon} />
        ) : canDelete ? (
          <Popconfirm
            placement="bottom"
            title={formatMessage({
              id: 'global.messaging.message.body.conversation.jobDescription.delete',
            })}
            onConfirm={() => removeJobDescription()}
            okText={formatMessage({
              id: 'global.messaging.message.body.conversation.jobDescription.yes',
            })}
            cancelText={formatMessage({
              id: 'global.messaging.message.body.conversation.jobDescription.no',
            })}
          >
            <DeleteOutlined className={styles.icon} />
          </Popconfirm>
        ) : (
          <FileOutlined className={styles.icon} />
        )}
      </div>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={'60%'}
        className={styles.jobDescriptionModal}
      >
        {jobDescription && (
          <Details jobDescription={jobDescription} showBreadcrumb={false} editable={false} />
        )}
      </Modal>
    </div>
  )
}

export default JobDescription
