import { Router, Redirect } from '@reach/router'
import Loader from 'components/Loader'
import config from 'config'
import React, { FC, Suspense } from 'react'
import CandidateLinkedinAuth from './Signin/CandidateLinkedinAuth'
import styles from './styles.module.scss'

const SigninCandidate = React.lazy(() => import('./Signin/CandidateForm'))
const SigninRecruiter = React.lazy(() => import('./Signin/RecruiterForm'))
const RecruiterGoogleAuth = React.lazy(() => import('./Signin/RecruiterGoogleAuth'))
const RecoverPassword = React.lazy(() => import('./Password/Recover'))
const RecoverPasswordSuccess = React.lazy(() => import('./Password/Recover/Success'))
const ResetPassword = React.lazy(() => import('./Password/Reset'))
const Login = React.lazy(() => import('./Login'))
const Signin = React.lazy(() => import('./Signin'))

const UnauthenticatedApp: FC = () => {
  return (
    <Suspense fallback={<Loader isFullscreen />}>
      <Router className={styles.unauthenticatedWrapper}>
        <Login path={config.get('routes.candidate.login')} />
        <Login path={config.get('routes.recruiter.login')} />
        <Signin path={config.get('routes.signin')} />
        <SigninCandidate path={config.get('routes.candidate.signin')} />
        <SigninRecruiter path={config.get('routes.recruiter.signin')} />
        <RecruiterGoogleAuth path={config.get('routes.recruiter.googleAuth')} />
        <CandidateLinkedinAuth path={config.get('routes.candidate.linkedinAuth')} />
        <RecoverPassword path={config.get('routes.candidate.recoverPassword')} />
        <RecoverPassword path={config.get('routes.recruiter.recoverPassword')} />
        <RecoverPasswordSuccess path={config.get('routes.candidate.recoverPasswordSuccess')} />
        <RecoverPasswordSuccess path={config.get('routes.recruiter.recoverPasswordSuccess')} />
        <ResetPassword path={config.get('routes.candidate.resetPassword')} />
        <ResetPassword path={config.get('routes.recruiter.resetPassword')} />
        <Redirect default noThrow={true} from="/" to={config.get('routes.candidate.login')} />
      </Router>
    </Suspense>
  )
}

export default UnauthenticatedApp
