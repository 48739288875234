import config from 'config'
import { MutationResultPair, queryCache, QueryResult, useMutation, useQuery } from 'react-query'
import { callAuthenticatedApi } from '../utils'

type OpportunityCandidates = {
  candidate: string
  id: string
  opportunity: string
}

export const useListOpportunityCandidates = (): QueryResult<OpportunityCandidates[]> => {
  return useQuery<OpportunityCandidates[], Error>(['opportunitiesCandidates', {}], async () => {
    const { data } = await callAuthenticatedApi<OpportunityCandidates[]>(
      config.api.opportunityCandidates.list
    )

    return data
  })
}

export const useGetOpportunityCandidates = (uri: string): QueryResult<OpportunityCandidates> => {
  return useQuery<OpportunityCandidates, Error>(['opportunityCandidates', { uri }], async () => {
    const { data } = await callAuthenticatedApi<OpportunityCandidates>(
      `${config.api.opportunityCandidates.get}${uri}`
    )
    return data
  })
}

interface AddCandidateToOpportunityRequest {
  opportunity: string
  candidate: string
}

type AddCandidateToOpportunityResponse = Record<string, string>

export const useAddCandidateToOpportunity = (): MutationResultPair<
  AddCandidateToOpportunityResponse,
  Error,
  AddCandidateToOpportunityRequest,
  string
> => {
  return useMutation(async (payload: AddCandidateToOpportunityRequest) => {
    const { data } = await callAuthenticatedApi<AddCandidateToOpportunityResponse>(
      config.api.opportunityCandidates.create,
      {
        method: 'POST',
        data: payload,
      }
    )
    return data
  })
}

export const useReachCandidate = (): MutationResultPair<
  AddCandidateToOpportunityResponse,
  Error,
  AddCandidateToOpportunityRequest,
  void
> => {
  return useMutation(
    async (payload: AddCandidateToOpportunityRequest) => {
      const { data } = await callAuthenticatedApi<AddCandidateToOpportunityResponse>(
        config.api.opportunityCandidates.reach,
        {
          method: 'POST',
          data: payload,
        }
      )
      return data
    },
    {
      onSettled: async () => {
        await queryCache.invalidateQueries('opportunityCandidateNew')
        await queryCache.invalidateQueries('opportunityCandidateReached')
      },
    }
  )
}
