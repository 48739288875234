import { useLocation, useNavigate } from '@reach/router'
import { notification } from 'antd'
import { ROLES } from 'app/AuthenticatedApp/helpers/withRoles'
import config from 'config'
import jwtDecode from 'jwt-decode'
import { parse } from 'query-string'
import React, { FC, useCallback, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useMutateRenewToken, useMutationLogin } from '../ApiContext/services/auth'
import { AuthData, AuthInterface, Jwt, LoginParams } from './types'
import axios from 'axios'

const initialState = {
  user: null,
  jwt: null,
  isLoggedIn: false,
  isRecruiter: false,
  isCandidate: false,
  isConsultant: false,
  isImpersonated: false,
}

const AuthContext = React.createContext<AuthInterface>({
  login: async () => {
    // make a login request
  },

  renewToken: async () => {
    // renew the JWT token
  },

  logout: () => {
    // clear the token in localStorage and the user data
  },

  setUser: () => {
    // Set the user data & token
  },

  data: initialState,
  loading: true,
})

export const JWT_LOCALSTORAGE_KEY = 'jwt'
export const JWT_REFRESH_LOCALSTORAGE_KEY = 'jwt_refresh'

export const AuthProvider: FC = (props) => {
  const [data, setData] = useState<AuthData>(initialState)
  const [loading, setLoading] = useState<boolean>(true)

  let token: string | null = ''

  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()

  const [mutateLogin] = useMutationLogin()
  const [mutateRenewToken] = useMutateRenewToken()

  useEffect(() => {
    const searchParams = parse(location.search)
    let isImpersonated = false

    if (searchParams.jwt) {
      token = searchParams.jwt as string
      isImpersonated = true
    } else {
      token = localStorage.getItem(JWT_LOCALSTORAGE_KEY)
    }

    if (token) {
      const jwt = jwtDecode<Jwt>(token)

      const now = Date.now() / 1000
      if (jwt.exp > now) {
        setUser(token, undefined, isImpersonated, false)
      } else {
        // @TODO Use refresh token
      }
    }

    setLoading(false)
  }, [])

  const setUser = useCallback(
    (token: string, refreshToken?: string, impersonated = false, persist = true): void => {
      const jwt = jwtDecode<Jwt>(token)
      if (persist) {
        localStorage.setItem(JWT_LOCALSTORAGE_KEY, token)

        if (refreshToken) {
          localStorage.setItem(JWT_REFRESH_LOCALSTORAGE_KEY, refreshToken)
        }
      }

      // Set default header Authorization for Axios request
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setData({
        user: {
          id: jwt.id,
          email: jwt.username,
          roles: jwt.roles,
          firstname: jwt.firstname,
          lastname: jwt.lastname,
          status: jwt.status,
          type: jwt.type,
        },
        jwt: token,
        isLoggedIn: true,
        isImpersonated: impersonated,
        isRecruiter: jwt.roles.includes(ROLES.RECRUITER),
        isCandidate: jwt.roles.includes(ROLES.CANDIDATE),
        isConsultant: [ROLES.ADMIN, ROLES.CONSULTANT].some((role) => jwt.roles.includes(role)),
      })
    },
    []
  )

  const login = async (payload: LoginParams): Promise<void> => {
    try {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { token, refresh_token } = (await mutateLogin(payload)) as {
        token: string
        refresh_token: string
      }

      setUser(token, refresh_token)

      const { roles } = jwtDecode<Jwt>(token)
      const redirectTo = roles?.includes(ROLES.RECRUITER)
        ? config.routes.recruiter.dashboard
        : config.routes.candidate.dashboard

      if (redirectTo) {
        await navigate(redirectTo)
      }
    } catch (e) {
      throw e
    }
  }

  const renewToken = async (): Promise<void> => {
    try {
      const refreshToken = localStorage.getItem(JWT_REFRESH_LOCALSTORAGE_KEY) as string
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { token, refresh_token } = (await mutateRenewToken(refreshToken)) as {
        token: string
        refresh_token: string
      }
      setUser(token, refresh_token)
    } catch (e) {
      notification.open({
        message: formatMessage({ id: 'notifications.error' }),
        description: formatMessage({ id: 'refreshToken.message' }),
        type: 'error',
      })
    }
  }

  const logout = (): void => {
    localStorage.removeItem(JWT_LOCALSTORAGE_KEY)
    localStorage.removeItem(JWT_REFRESH_LOCALSTORAGE_KEY)
    setData(initialState)
    navigate(config.routes.recruiter.login)
  }

  return (
    <AuthContext.Provider
      value={{ data, setUser, login, renewToken, logout, loading }}
      {...props}
    />
  )
}
export const useAuth = (): AuthInterface => React.useContext(AuthContext)
