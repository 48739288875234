import { default as enGB } from './en-GB'
import flattenMessages from './flattenMessages'
import { default as frFR } from './fr-FR'
import output from './output'

export enum TranslationBase {
  CONSULTANT = 'consultant',
  GLOBAL = 'global',
  RECRUITER = 'recruiter',
  CANDIDATE = 'candidate',
  ANONYMOUS = 'anonymous',
  FORM = 'form',
}

export type TranslationKeyValue = { [key: string]: TranslationKeyValue | string }

export interface Locale {
  'fr-FR': Record<string, string>
  'en-GB': Record<string, string>
}

export interface TranslationObject {
  base: TranslationBase
  debug?: boolean
  langs: {
    fr: TranslationKeyValue
    en: TranslationKeyValue
  }
}

export default {
  'fr-FR': flattenMessages({ ...frFR, ...output.fr }),
  'en-GB': flattenMessages({ ...enGB, ...output.en }),
} as Locale
