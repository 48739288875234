import { Select, Tag } from 'antd'
import { SelectProps, SelectValue } from 'antd/es/select'
import ErrorMessage from 'app/form/base/ErrorMessage'
import cn from 'classnames'
import { FormikErrors } from 'formik'
import { CustomTagProps } from 'rc-select/lib/interface/generator'
import React, { ReactElement } from 'react'
import { FormValues } from 'app/UnauthenticatedApp/Signin/CandidateForm/types'
import styles from './styles.module.scss'
import { FormInputMobilityType } from 'app/form/InputMobilities/type'

export interface InputSelectProps extends SelectProps<SelectValue> {
  label: string
  error?:
    | string
    | false
    | string[]
    | FormikErrors<FormValues>
    | FormikErrors<FormValues>[]
    | FormikErrors<FormInputMobilityType>[]
    | undefined
  'data-cy'?: string
  name: string
  onChange?: (value: SelectValue) => void
  children?: React.ReactElement
}

function tagRender(props: CustomTagProps): ReactElement {
  const { closable, onClose, label } = props

  return (
    <Tag closable={closable} onClose={onClose}>
      {label}
    </Tag>
  )
}

const InputSelect: React.FC<InputSelectProps> = ({
  label,
  options,
  error,
  name,
  children,
  ...restProps
}) => {
  return (
    <div className={cn(styles.group, { [styles.error]: error })}>
      <label htmlFor={name}>{label}</label>
      <Select {...restProps} size="large" tagRender={tagRender}>
        {children ??
          options?.map((o, i) => (
            <Select.Option key={`${o}-${i}`} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
      </Select>

      {error && (
        <div className={styles.error}>
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  )
}

export default InputSelect
