import { Skeleton } from 'antd'
import ProfileAvatar from 'app/AuthenticatedApp/components/Avatar'
import { UserType } from 'app/contexts/AuthContext/types'
import cn from 'classnames'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

interface OwnProps {
  profilePicture?: string
  firstname?: string
  lastname?: string
  jobTitle?: string
  userType?: UserType
  inline?: boolean
  small?: boolean
  isActive?: boolean
  isLoading?: boolean
}

export const userTypeColorMap = new Map<UserType, 'pink' | 'blue'>([
  ['candidate', 'pink'],
  ['consultant', 'blue'],
  ['recruiter', 'pink'],
])

const Avatar: React.FC<OwnProps> = ({
  profilePicture,
  firstname,
  lastname,
  jobTitle,
  userType,
  small,
  inline,
  isActive,
  isLoading,
}) => {
  const { formatMessage } = useIntl()

  const fullName = useMemo(() => {
    return `${firstname} ${lastname}`
  }, [firstname, lastname])

  const color = useMemo(() => userTypeColorMap.get(userType as UserType), [userType])

  return (
    <div
      className={cn(styles.avatar, color && styles[color], {
        [styles.small]: small,
        [styles.active]: isActive,
      })}
    >
      {isLoading ? (
        <Skeleton.Avatar active size={small ? 30 : 50} shape={'circle'} />
      ) : (
        <ProfileAvatar
          size={small ? 30 : 50}
          image={profilePicture}
          firstname={firstname}
          lastname={lastname}
        />
      )}
      <div className={styles.infos}>
        <Skeleton paragraph={{ rows: 1, width: 120 }} active loading={isLoading} round>
          <p className={styles.name}>{fullName}</p>
        </Skeleton>

        {!small && jobTitle && <div className={styles.job}>{jobTitle}</div>}

        {!inline && !small && !isLoading && (
          <div className={styles.type}>{formatMessage({ id: `global.avatar.${userType}` })}</div>
        )}
      </div>
      {inline && !small && !isLoading && <div className={styles.type}>{userType}</div>}
    </div>
  )
}

export default Avatar
