import BreadCrumb, { BreadcrumbProps } from 'recruiter/components/Breadcrumb'
import React, { FC } from 'react'
import styles from './styles.module.scss'

type SubMenuProps = BreadcrumbProps

const SubMenu: FC<SubMenuProps> = ({ items, children }) => {
  return (
    <div className={styles.topbar}>
      <BreadCrumb items={items} />
      <div className={styles.topbarActions}>{children}</div>
    </div>
  )
}

export default SubMenu
